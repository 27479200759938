import { deleteSchoolUsers } from 'app/crud/schools.crud';
import { createUser, createUserSchools, updateUser } from 'app/crud/users.crud';
import { createGroup, deleteGroup } from 'app/crud/usersGroups.crud';
import i18next from 'app/translations/i18n';
import { getDomainTenant } from 'app/utils/url';
import axios from 'axios';

export const USER_ROLES = { student: 'R01', teacher: 'R02', teacherAdmin: 'R03', admin: 'R04', helpDesk: 'R05', adminFixedLayout: 'R06', publisher: 'R07' };

export async function createUserService(data) {
  const newUser = getDataGeneral(data);
  const response = await createUser(newUser);

  if (response.data) {
    const { guid } = response.data.data;
    await fetchUpdateUserGroups(guid, data);
    await fetchUpdateUserSchools(guid, data);
  }

  return response;
}

export async function updateUserService(data) {
  const updateData = getDataGeneralUpdate(data);
  await fetchUpdateUserGroups(data.guid, data);
  await fetchUpdateUserSchools(data.guid, data);

  return await updateUser(updateData);
}

// ---------------------------------------------------------------
// Auxiliary functions
// ---------------------------------------------------------------

export function getDataGeneral(data) {
  return {
    country_guid: data.country ? data.country.value : undefined,
    is_demo: data.is_demo ? 1 : 0,
    lastname: data.lastname,
    name: data.name,
    role: data.role,
    username: data.username,
  };
}

export function getDataGeneralUpdate(data) {
  return {
    avatar: data.avatar,
    change_password: data.change_password ? 1 : undefined,
    country_guid: data.country ? data.country.value : undefined,
    guid: data.guid,
    is_demo: data.is_demo ? 1 : 0,
    lastname: data.lastname,
    name: data.name,
    password: data.password ? data.password : undefined,
    role_guid: data.role,
    username: data.username,
  };
}

export function viewAsUser({ user, schoolGuid, tenant }) {
  let domain = getDomainTenant({ tenant, user });

  if (user.schools && user.schools.length) {
    const url = schoolGuid ? user.schools.find((item) => item.guid === schoolGuid).url_access : user.schools[0].url_access;
    domain = url ? `//${url}` : domain;
  }

  return new Promise(async (resolve) => {
    try {
      const response = await axios.post(`users/${user.guid}/login`, { data: {} });

      window.open(`${domain}auth/login/${response?.data?.data?.token}`);
      resolve(response?.data?.data);
    } catch (error) {}
  });
}

export function viewAsUserOraculo(user) {
  return new Promise(async (resolve) => {
    const response = await axios.post(`users/${user.oraculo_id}/login?provider=oraculo`, {});
    const { frontoffice, token } = response.data.data;

    window.open(`${frontoffice}/login/?user_token=${token}`);
    resolve(response.data.data);
  });
}

async function fetchUpdateUserSchools(userGuid, data) {
  if (data.role !== USER_ROLES.teacherAdmin) {
    await fetchUpdateUserSchoolsOnly(userGuid, data);
  } else {
    await fetchUpdateUserSchoolsAdmin(userGuid, data);
  }
}

async function fetchUpdateUserGroups(userGuid, data) {
  const { groups, groupsOld, school } = data;
  const filterCreate = groups.filter((item) => !item?.school_guid || item?.school_guid === school?.value);
  const filterDelete = groupsOld.filter((item) => !groups.find((element) => element.value === item.guid));

  filterCreate.forEach((group) => {
    createGroup(group.school_guid, group.value, { guid: [userGuid] });
  });

  filterDelete.forEach((element) => {
    deleteGroup(element.school_guid, element.guid, { guid: [userGuid] });
  });
}

// ---------------------------------------------------------------
// Auxiliary functions
// ---------------------------------------------------------------

export function isTeacher(user) {
  return user?.role_guid === USER_ROLES.teacher;
}

export function isStudent(user) {
  return user?.role_guid === USER_ROLES.student;
}

export function isAdminEditorial(user) {
  return user?.role_guid === USER_ROLES.admin || user.role === USER_ROLES.admin;
}

export function isHelpDesk(user) {
  return user?.role_guid === USER_ROLES.helpDesk || user.role === USER_ROLES.helpDesk;
}

export function isUserBackOffice(user) {
  return isAdminEditorial(user) || isHelpDesk(user);
}

export function getRoleText(role_guid) {
  return i18next.t(`users:${role_guid}`);
}

export function canSeeFrontOffice(user) {
  return [USER_ROLES.student, USER_ROLES.teacher].includes(user?.role || user?.role_guid);
}

// ---------------------------------------------------------------
// Internal functions
// ---------------------------------------------------------------
async function fetchUpdateUserSchoolsOnly(userGuid, data) {
  const { school, schoolsOld } = data;
  const filter = schoolsOld.filter((item) => item.guid !== school?.value).filter((item) => !item.name?.includes(item.guid)); // Quitamos 'personal school of'

  if (school?.value) {
    await createUserSchools(userGuid, [school.value]);
  }

  for (const element of filter) {
    await deleteSchoolUsers(element.guid, { guid: [data.guid] });
  }
}

async function fetchUpdateUserSchoolsAdmin(userGuid, data) {
  const { schools, schoolsOld } = data;

  if (schools.length) {
    await createUserSchools(
      userGuid,
      schools.map((item) => item.value)
    );
  }

  const filterOld = schoolsOld.filter((item) => !schools.find((element) => element.value === item.value));
  for (const element of filterOld) {
    await deleteSchoolUsers(element.guid, { guid: [data.guid] });
  }
}
