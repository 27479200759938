import { setMetadataSelect } from 'app/services/metadata.service';
import { USER_ROLES } from 'app/services/users.service';
import i18next from 'app/translations/i18n';
import { requiredIf } from 'app/utils/yupHelpers';
import { DATE, INTEGER, MAX, POSITIVE, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'licenseBatch';

export const Model = {
  codes_amount: '',
  country: undefined,
  expired_at: new Date(),
  expires: 'expiration', // Radio button 'expiration', 'interval'
  expires_after_number: 1,
  expires_after_string: '',
  name: '',
  role_guid: USER_ROLES.student,
};

Yup.addMethod(Yup.string, 'requiredIf', requiredIf);
Yup.addMethod(Yup.number, 'requiredIfNumber', requiredIf);

export const ModelYup = Yup.object().shape({
  codes_amount: Yup.number().positive(POSITIVE).integer(INTEGER).required(REQUIRED),
  expired_at: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'expiration', REQUIRED),
  expires: Yup.string(DATE),
  expires_after_number: Yup.number(DATE).requiredIfNumber(Yup.ref('expires'), 'expires_after', REQUIRED).positive(POSITIVE).integer(INTEGER),
  expires_after_string: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'interval', REQUIRED),
  name: Yup.string().max(50, MAX).required(REQUIRED),
  role_guid: Yup.string(),
});

export const ModelYupMultiCountry = Yup.object().shape({
  codes_amount: Yup.number().positive(POSITIVE).integer(INTEGER).required(REQUIRED),
  country: Yup.object().required(REQUIRED),
  expired_at: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'expiration', REQUIRED),
  expires: Yup.string(DATE),
  expires_after_number: Yup.number(DATE).requiredIfNumber(Yup.ref('expires'), 'expires_after', REQUIRED).positive(POSITIVE).integer(INTEGER),
  expires_after_string: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'interval', REQUIRED),
  name: Yup.string().max(50, MAX).required(REQUIRED),
  role_guid: Yup.string(),
});

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('licenses:toast_update') },
];

export function sanitizeData(data, countries) {
  const findCountry = countries.find((country) => country.guid === data.country_guid);
  const license = { ...data, country_name: findCountry?.country };
  const expires_after = license.expires_after ? license.expires_after.split(' ') : license.expires_after;

  return {
    codes_amount: license.codes_amount ? license.codes_amount : Model.codes_amount,
    country: license.country_guid ? setMetadataSelect(license, 'country_name', 'country_guid') : Model.country,
    expired_at: license.expired_at ? license.expired_at : Model.expired_at,
    expires: expires_after ? 'interval' : Model.expires,
    expires_after_number: expires_after ? expires_after[0] : Model.expires_after_number,
    expires_after_string: expires_after ? expires_after[1] : Model.expires_after_string,
    name: license.name ? license.name : Model.name,
    role_guid: license.role_guid ? license.role_guid : Model.role_guid,
    guid: license.guid,
  };
}
