import { getLangScopes } from 'app/crud/langs.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_SCOPES, REDUCER_SCOPES } from '../conf';

export const actionTypes = {
  GetScopes: 'cms/GET_SCOPES',
  SetScopes: 'cms/SET_SCOPES',
};

const initialState = {
  data: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_SCOPES, whitelist: ['data'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetScopes:
      return { ...state, loading: true };

    case actionTypes.SetScopes: {
      const { response } = action.payload;
      return response ? { data: response, loading: false } : { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getScopes: (state) => {
    return state.entities[REDUCER_SCOPES].data;
  },
};

export const actions = {
  getScopes: (params) => ({ type: actionTypes.GetScopes, payload: { params } }),
  fulfillScopes: (response) => ({ type: actionTypes.SetScopes, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetScopes, function* getScopesSaga(action) {
    // Intentar obtener los datos de la caché primero
    const cached = yield select(selectors.getScopes);

    // Si hay datos en la caché, los usamos directamente
    if (cached && cached.length > 0) {
      yield put(actions.fulfillScopes(cached));
    } else {
      // Si no hay datos en la caché, llamamos a la API para obtenerlos
      const { data } = yield getLangScopes(action.payload.params);
      if (data && data.status === 'success' && data.data) yield put(actions.fulfillScopes(data.data));
      else yield put(actions.fulfillScopes([]));
    }
  });
}
