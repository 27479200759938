import axios from 'axios';

export const CONTENT_CMS = 'cms/contents/';
export const CONTENTS = 'cms/contents';
export const CONTENTS_CLONE = 'cms/contents-clone';

export function getContent(guid) {
  return axios.get(`${CONTENTS}/${guid}`);
}

export function getContents(params) {
  return axios.get(CONTENTS, { params });
}

export function createContent(params) {
  return axios.post(CONTENTS, params);
}

export function updateContent(params) {
  return axios.put(`${CONTENTS}/${params.guid}`, params);
}

export function deleteContent(params = { guid: [] }) {
  return axios.delete(CONTENTS, { data: params });
}

export function cloneContent(params) {
  return axios.post(CONTENTS_CLONE, params);
}

// --------------------------------------------------------------------------------
//  CREATE METADATA:
// --------------------------------------------------------------------------------

export function createContentEducationLevels(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/education-levels`, { guid: guids });
}

export function createContentEducationYears(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/education-years`, { guid: guids });
}

export function createContentDisciplines(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/disciplines`, { guid: guids });
}

export function createContentTags(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/tags`, { guid: guids });
}

export function createContentCollections(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/collections`, { guid: guids });
}

export function createContentPublications(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/publications`, { guid: guids });
}

export function createContentDependencies(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/dependencies`, { guid: guids });
}

export function createContentCustomTags(contentGuid, guids) {
  return axios.post(`${CONTENTS}/${contentGuid}/custom-tags`, { guid: guids });
}

// --------------------------------------------------------------------------------
//  DELETE METADATA:
// --------------------------------------------------------------------------------

export function deleteContentCustomTags(contentGuid, guids) {
  const params = { guid: guids };

  return axios.delete(`${CONTENTS}/${contentGuid}/custom-tags`, { data: params });
}

// --------------------------------------------------------------------------------
//  OTHER:
// --------------------------------------------------------------------------------

export function getContentCms(guid) {
  return axios.get(CONTENT_CMS + guid);
}

export function uploadFromGoogleDrive(contentGuid, fileId, oauthToken, innerFolder) {
  const params = { fileId: fileId, oauthToken: oauthToken };
  if (innerFolder && innerFolder.toString().length > 0) {
    params.innerFolder = innerFolder;
  }
  return axios.post(CONTENT_CMS + contentGuid + '/content/upload-from-google-drive', params);
}

export function uploadFromGoogleDriveThumbnail(contentGuid, fileId, oauthToken) {
  const params = { fileId: fileId, oauthToken: oauthToken };
  return axios.post(CONTENT_CMS + contentGuid + '/thumbnail/upload-from-google-drive', params);
}
