import SvgIcon from '@material-ui/core/SvgIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';

function Icon({ color, fontSize, htmlColor, isTitle, type, ...props }) {
  const svgContext = require.context('./svg', false, /\.svg$/);
  const src = hasIcon() ? svgContext(`./${type}.svg`) : '';

  function hasIcon() {
    return svgContext.keys().find((item) => item === `./${type}.svg`);
  }

  return (
    <>
      <SvgIcon color={color} fontSize={fontSize} htmlColor={htmlColor} {...props}>
        <ReactSVG src={src} wrapper={'svg'} />
      </SvgIcon>

      {isTitle && <p style={{ fontSize: 10, marginTop: 12 }}>{type}</p>}
    </>
  );
}

Icon.propTypes = {
  color: PropTypes.oneOf(['inherit', 'action', 'disabled', 'primary', 'secondary', 'error']),
  fontSize: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
  htmlColor: PropTypes.string,
  isTitle: PropTypes.bool,
  type: PropTypes.string,
};

Icon.defaultProps = {
  color: 'inherit',
  fontSize: 'small',
  isTitle: false,
  type: 'icon-add',
};

export default Icon;
