import React from 'react';

const PublicationCreatePage = React.lazy(() => import('../pages/publications/PublicationCreatePage/PublicationCreatePage'));
const PublicationDetailPage = React.lazy(() => import('../pages/publications/PublicationDetailPage/PublicationDetailPage'));
const PublicationsPage = React.lazy(() => import('../pages/publications/PublicationsPage/PublicationsPage'));

export const PATH_PUBLICATION = '/publication/:guid';
export const PATH_PUBLICATIONS = '/publications';
export const PATH_PUBLICATION_CREATE = '/publication/create';

// -------------------------------------------
//  PUBLICATIONS
// -------------------------------------------

const PAGE_PUBLICATION_CREATE = {
  component: PublicationCreatePage,
  path: PATH_PUBLICATION_CREATE,
};

const PAGE_PUBLICATION = {
  component: PublicationDetailPage,
  path: PATH_PUBLICATION,
};

const PAGE_PUBLICATIONS = {
  component: PublicationsPage,
  path: PATH_PUBLICATIONS,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_PUBLICATION_CREATE, PAGE_PUBLICATION, PAGE_PUBLICATIONS];
