import PropTypes from 'prop-types';
import React from 'react';

const LoadingSmple = ({ title = 'Loading..' }) => {
  return (
    <div className="laoding-simple">
      <div className="laoding-simple__loop">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="loading-simple__title">{title}</div>
    </div>
  );
};

LoadingSmple.propTypes = {
  title: PropTypes.string,
};

export default LoadingSmple;
