import { getSchoolsTypes } from 'app/crud/schools.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { REDUCER_SCHOOLS } from '../conf';

export const actionTypes = {
  GetTypes: 'cms/GET_SCHOOLS_TYPES',
  SetTypes: 'cms/SET_SCHOOLS_TYPES',
};

const initialState = {
  loading: false,
  types: [
    { guid: 'ST_01', type: 'Public' },
    { guid: 'ST_02', type: 'Private' },
  ],
};

export const reducer = persistReducer({ storage, key: REDUCER_SCHOOLS, whitelist: ['types'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTypes: {
      return { ...state, loading: true };
    }

    case actionTypes.SetTypes: {
      return { ...state, loading: false, types: action.payload };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLoading: (state) => {
    return state.entities && state.entities[REDUCER_SCHOOLS] ? state.entities[REDUCER_SCHOOLS].loading : null;
  },
  getSchoolsTypes: (state) => {
    return state.entities && state.entities[REDUCER_SCHOOLS] ? state.entities[REDUCER_SCHOOLS].types : null;
  },
};

export const actions = {
  getSchoolsTypes: (params) => ({ type: actionTypes.GetTypes, payload: { params } }),
  setSchoolsTypes: (response) => ({ type: actionTypes.SetTypes, payload: { response } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetTypes, function* getSchoolsTypesSaga() {
    const { data } = yield getSchoolsTypes();

    if (data && data.status === 'success' && data.data) {
      yield put({ type: actionTypes.SetTypes, payload: data.data });
    }
  });
}
