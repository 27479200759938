import useFeatureFlag from 'app/hooks/useFeatureFlag';
import { FEATURE_FLAGS } from 'app/services/featureFlag.service';

function useFeatureFlagAnalytics() {
  const { value: showAmplitude } = useFeatureFlag(FEATURE_FLAGS.SHOW_AMPLITUDE);
  const { value: showAnalyticsGame } = useFeatureFlag(FEATURE_FLAGS.SHOW_ANALYTICS_GAME);

  return {
    showAmplitude: showAmplitude === undefined ? true : showAmplitude,
    showAnalyticsGame: showAnalyticsGame === undefined ? true : showAnalyticsGame,
  };
}

export default useFeatureFlagAnalytics;
