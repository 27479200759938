import React from 'react';

const PersonalizePage = React.lazy(() => import('../pages/personalize/PersonalizePage'));

export const PATH_PERSONALIZE = '/personalize';

const PAGE_PERSONALIZE = {
  component: PersonalizePage,
  path: PATH_PERSONALIZE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_PERSONALIZE];
