import axios from 'axios';

export const PROGRAMS = 'lms/courses';

export function getProgram(guid) {
  return axios.get(`${PROGRAMS}/${guid}`);
}

export function getPrograms(params) {
  return axios.get(PROGRAMS, { params });
}

export function createProgram(params) {
  return axios.post(PROGRAMS, params);
}

export function updateProgram(params) {
  return axios.put(`${PROGRAMS}/${params.guid}`, params);
}

export function deleteProgram(params = { guid: [] }) {
  return axios.delete(PROGRAMS, { data: params });
}

export function cloneProgram(params) {
  return axios.post('lms/courses-clone', params);
}

// ---------------------------------------------------
//  RELATED
// ---------------------------------------------------

export function getProgramsRelated(courseGuid, params = {}) {
  // ?pageSize=2&offset=0
  return axios.get(`${PROGRAMS}/${courseGuid}/related`, { params });
}
