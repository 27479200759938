import { getTypography } from 'app/crud/programTypography.crud';
import { applyFonts } from 'app/services/fonts.service';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_TYPOGRAPHY, REDUCER_TYPOGRAPHY } from '../conf';

export const actionTypes = {
  GetTypography: 'cms/GET_TYPOGRAPHY',
  SetTypography: 'cms/SET_TYPOGRAPHY',
};

const initialState = {
  typography: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_TYPOGRAPHY, whitelist: ['typography'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTypography:
      return { ...state, loading: true };

    case actionTypes.SetTypography: {
      applyFonts(action.payload);
      return { ...state, typography: action.payload, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getTypography: (state) => {
    return state.entities && state.entities[REDUCER_TYPOGRAPHY] ? state.entities[REDUCER_TYPOGRAPHY].typography : null;
  },
};

export const actions = {
  getTypography: (params) => ({ type: actionTypes.GetTypography, payload: { ...params } }),
  setTypography: (response) => ({ type: actionTypes.SetTypography, payload: response }),
};

function* fetchTypography() {
  const { data } = yield getTypography();
  if (data && data.status === 'success') {
    yield put(actions.setTypography(data.data));
  }
}

function* getTypographyIfNeeded() {
  const typography = yield select(selectors.getTypography);
  if (!typography || typography.length === 0) {
    yield fetchTypography();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetTypography, getTypographyIfNeeded);
}
