import posthog from 'posthog-js';

export function initFeatureFlag({ organization, user }) {
  window.tenant = organization?.tenant?.guid;

  posthog.identify(user?.guid, {
    client: organization?.tenant?.guid,
    environment: process?.env?.REACT_APP_ENVIRONMENT,
    email: user?.username,
    name: `${user?.name} ${user?.lastname}`,
    role: user?.role_guid,
  });

  console.log(`%cFeature flag Init. guid: ${organization?.tenant?.guid} enviroment: ${process?.env?.REACT_APP_ENVIRONMENT}`, 'color: #007aff; font-weight: bold;');
}
