import SelectCustomizable, { utilsSelectCustomizable } from '__core/modules/atoms/SelectCustomizable';
import { educationLevels } from 'app/store/index';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SelectDisciplines = React.forwardRef(function (
  { countries, country, disabled, isMulti, isSkeleton, menuPosition, name, onBlur, onChange, required, value },
  ref
) {
  const { t } = useTranslation(['common']);
  const disciplines = useSelector((state) => educationLevels.selectors.getDisciplines(state));
  const disciplinesFilter = getDataFilter();
  const options = utilsSelectCustomizable.getOptionsProperties(disciplinesFilter, 'discipline', 'guid');
  const optionsOrder = getList(options);

  function onChangeSelect(event) {
    onChange(event ? event : []);
  }

  function getList(data) {
    return orderBy(data, ['label'], ['asc']);
  }

  function getDataFilter() {
    if (countries) {
      return disciplines.filter((item) => countries.find((element) => element.value === item.country_guid));
    }

    if (country) {
      return disciplines.filter((item) => item.country_guid === country.value);
    }

    return disciplines;
  }

  return (
    <SelectCustomizable
      isDisabled={disabled}
      isMulti={isMulti}
      isSkeleton={isSkeleton}
      label={t('Subjects')}
      menuPosition={menuPosition}
      name={name}
      onBlur={onBlur}
      onChange={onChangeSelect}
      options={optionsOrder}
      required={required}
      value={value}
    />
  );
});

SelectDisciplines.propTypes = {
  countries: PropTypes.array,
  country: PropTypes.object,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
};

SelectDisciplines.defaultProps = {
  disabled: false,
  isMulti: true,
  menuPosition: 'initial',
  onChange: () => null,
};

export default SelectDisciplines;
