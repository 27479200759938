import axios from 'axios';

export const EDUCATION_LEVELS = 'education-levels';

export function getEducationLevels(params) {
  return axios.get(EDUCATION_LEVELS, { params });
}

export function createEducationLevel(params) {
  return axios.post(EDUCATION_LEVELS, params);
}

export function updateEducationLevel(params) {
  return axios.patch(`${EDUCATION_LEVELS}/${params.guid}`, params);
}

export function deleteEducationLevel(params = { guid: [] }) {
  return axios.delete(EDUCATION_LEVELS, { data: params });
}

export function updateEducationLevelOrder(params) {
  return axios.put(`${EDUCATION_LEVELS}`, params);
}
