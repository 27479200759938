import viewerApi from '../api/viewerApi';
import { VIEWER_LANGUAGE } from './viewerConfig';
import { EN } from './viewerDefaultLangEn';
import { ES } from './viewerDefaultLangEs';
import { PT } from './viewerDefaultLangPt';

export function getTranslations(_callback) {
  if (typeof window.ocviewerlang == 'undefined') {
    var _data = { params: { scope: Array('visores'), lang: Array(VIEWER_LANGUAGE) }, type: 'GET_LANGS' };
    viewerApi(
      _data,
      function (e) {
        try {
          window.ocviewerlang = JSON.parse(e.data[0].json);
          _callback();
        } catch (e) {
          window.ocviewerlang = VIEWER_LANGUAGE === 'en' ? EN : VIEWER_LANGUAGE === 'es' ? ES : VIEWER_LANGUAGE === 'PT' ? PT : EN;
          _callback();
        }
      }.bind(this)
    );
  } else {
    _callback();
  }
}
