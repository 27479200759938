import { conf } from 'app/store/index';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import {
  REDUCER_APP,
  REDUCER_GENERAL_APP,
  REDUCER_LOCATIONS,
  REDUCER_QUESTION_TYPES,
  REDUCER_SCHOOLS,
  REDUCER_SCOPES,
  REDUCER_SYSTEM_SETTINGS,
  REDUCER_USERS,
} from './conf';
import * as appEntities from './index';

// Creamos el reducer del _ui a medida con lo estrictamente necesario
const reducersCore = {
  [conf.REDUCER_AUTH]: appEntities.auth.reducer,
  [conf.REDUCER_I18N]: appEntities.i18n.reducer,
};

const reducersApp = {
  [REDUCER_APP]: appEntities.app.reducer,
  [REDUCER_GENERAL_APP]: combineReducers({
    [REDUCER_LOCATIONS]: appEntities.locations.reducer,
    [REDUCER_QUESTION_TYPES]: appEntities.questions.reducer,
    [REDUCER_SCHOOLS]: appEntities.schools.reducer,
    [REDUCER_SCOPES]: appEntities.scopes.reducer,
    [REDUCER_USERS]: appEntities.users.reducer,
    [conf.REDUCER_CUSTOM_TAGS]: appEntities.customTags.reducer,
    [conf.REDUCER_EDUCATION_LEVELS]: appEntities.educationLevels.reducer,
    [conf.REDUCER_LANGS]: appEntities.langs.reducer,
    [conf.REDUCER_LEARNING_OBJECTIVES]: appEntities.learningObjectives.reducer,
    [conf.REDUCER_TEMPLATES]: appEntities.templates.reducer,
    [conf.REDUCER_TOPICS]: appEntities.topics.reducer,
    [conf.REDUCER_TYPES]: appEntities.types.reducer,
    [conf.REDUCER_TYPOGRAPHY]: appEntities.programTypography.reducer,
  }),
};

const reducerSetting = {
  [REDUCER_SYSTEM_SETTINGS]: appEntities.systemSettings.reducer,
};

export const rootReducer = combineReducers({ ...reducersCore, ...reducersApp, ...reducerSetting });

export function* rootSaga() {
  yield all([
    appEntities.auth.saga(),
    appEntities.customTags.saga(),
    appEntities.educationLevels.saga(),
    appEntities.langs.saga(),
    appEntities.learningObjectives.saga(),
    appEntities.locations.saga(),
    appEntities.programTypography.saga(),
    appEntities.questions.saga(),
    appEntities.schools.saga(),
    appEntities.scopes.saga(),
    appEntities.systemSettings.saga(),
    appEntities.templates.saga(),
    appEntities.topics.saga(),
    appEntities.types.saga(),
    appEntities.users.saga(),
  ]);
}
