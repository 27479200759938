import Icon from '__core/modules/atoms/Icons/Icon';
import InputText from '__core/modules/atoms/InputText';
import React from 'react';
import UISelectColor from './UISelectColor';
import UISelectSize from './UISelectSize';

function UIIcons() {
  const [color, setColor] = React.useState('inherit');
  const [htmlColor, setHtmlColor] = React.useState(undefined);
  const [fontSize, setFontSize] = React.useState('large');

  const svgContext = require.context('../../../../../__core/modules/atoms/Icons/svg', false, /\.svg$/);
  const icons = svgContext.keys().map((item) => item.replace('./', '').replace('.svg', ''));

  function onChangeColor(event) {
    setColor(event);
  }

  function onChangeHtml(event) {
    setHtmlColor(event.target.value);
  }

  function onChangeSize(event) {
    setFontSize(event);
  }

  return (
    <>
      <div className="d-flex ">
        <div className="w-25 mr-3">
          <UISelectColor onChange={onChangeColor} value={color} />
        </div>

        <div className="w-25 mr-3">
          <UISelectSize onChange={onChangeSize} value={fontSize} />
        </div>

        <InputText label="htmlColor" onChange={onChangeHtml} />
      </div>

      <div className="d-flex mb-4">
        <p className="mr-3">#FF8000</p>
        <p className="mr-3">#FFFF00</p>
        <p className="mr-3">#00FF00</p>
        <p className="mr-3">#00FFFF</p>
        <p className="mr-3">#FF00FF</p>
      </div>

      <div className="d-flex flex-wrap">
        {icons.map((type) => (
          <div key={type} className="d-flex flex-column align-items-center m-3">
            <Icon type={type} color={color} htmlColor={htmlColor} fontSize={fontSize} isTitle />
          </div>
        ))}
      </div>
    </>
  );
}

export default UIIcons;
