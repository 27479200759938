import { Card, CardContent, CardHeader, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  list: {
    flex: 1,
    minWidth: 250, // Asegura un ancho mínimo para las tarjetas
  },
  droppableArea: {
    minHeight: 120,
    padding: theme.spacing(1),
    transition: 'background-color 0.2s ease',
    backgroundColor: theme.palette.background.default,
    '&.isDraggingOver': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  draggableItem: {
    marginBottom: theme.spacing(1),
    transition: 'transform 0.2s ease, background-color 0.2s ease',
    '&.isDragging': {
      transform: 'rotate(2deg)',
      backgroundColor: theme.palette.warning.light,
    },
  },
  jsonPreview: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    '& pre': {
      margin: 0,
      fontSize: '0.875rem',
    },
  },
}));

// Componente auxiliar para el área donde se pueden soltar elementos
const DroppableList = ({ listId, items, classes }) => (
  <Droppable droppableId={listId} isDropDisabled={listId === 'materials'} direction={true ? 'horizontal' : 'vertical'}>
    {(provided, snapshot) => (
      <div {...provided.droppableProps} ref={provided.innerRef} className={`${classes.droppableArea} ${snapshot.isDraggingOver ? 'isDraggingOver' : ''}`}>
        {items.map((item, index) => (
          <DraggableItem key={item.id} item={item} index={index} classes={classes} listId={listId} />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

// Componente auxiliar para los elementos arrastrables
const DraggableItem = ({ item, index, classes, listId }) => (
  <Draggable draggableId={item.id} index={index} isDragDisabled={listId !== 'materials'}>
    {(provided, snapshot) => (
      <Paper
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        elevation={snapshot.isDragging ? 3 : 1}
        className={`${classes.draggableItem} ${snapshot.isDragging ? 'isDragging' : ''}`}
      >
        <CardContent>{item.name}</CardContent>
      </Paper>
    )}
  </Draggable>
);

const DragDropLists = () => {
  const classes = useStyles();
  const [lists, setLists] = useState({
    materials: {
      title: 'Materiales',
      items: [
        { id: '1', name: 'Cat' },
        { id: '2', name: 'Dog' },
        { id: '3', name: 'Bird' },
        { id: '4', name: 'Moto' },
        { id: '5', name: 'Coche' },
        { id: '6', name: 'Pepe' },
      ],
    },
    session_1: {
      title: 'Sesión 1',
      items: [],
    },
    session_4: {
      title: 'Sesión 4',
      items: [],
    },
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Si no hay destino válido, salir
    if (!destination) return;

    // Si el origen y destino son el mismo y el índice es el mismo, salir
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceList = lists[source.droppableId];
    const destList = lists[destination.droppableId];

    // Crear copias de los arrays para manipular
    const sourceItems = Array.from(sourceList.items);
    const destItems = Array.from(destList.items);

    // Si el origen es "materials", crear una copia del elemento
    if (source.droppableId === 'materials') {
      const itemToCopy = sourceList.items[source.index];
      const newItem = {
        ...itemToCopy,
        id: `${itemToCopy.id}-${Date.now()}`, // Crear un ID único para la copia
      };
      destItems.splice(destination.index, 0, newItem);

      setLists({
        ...lists,
        [destination.droppableId]: {
          ...destList,
          items: destItems,
        },
      });
    } else {
      // Para otras listas, mover el elemento
      const [removedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removedItem);

      setLists({
        ...lists,
        [source.droppableId]: {
          ...sourceList,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destList,
          items: destItems,
        },
      });
    }
  };

  const onDragStart = () => {
    // Opcional: Añadir efectos visuales al comenzar el arrastre
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  };

  const onDragUpdate = (update) => {
    // Opcional: Actualizar la UI durante el arrastre
    const { destination } = update;
    // Puedes usar esto para efectos visuales adicionales
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
      <div className={classes.root}>
        <div className={classes.container}>
          {Object.entries(lists).map(([listId, list]) => (
            <Card key={listId} className={classes.list}>
              <CardHeader title={list.title} titleTypographyProps={{ variant: 'h6' }} />
              <CardContent>
                <DroppableList listId={listId} items={list.items} classes={classes} />
              </CardContent>
            </Card>
          ))}
        </div>

        <Paper className={classes.jsonPreview}>
          <pre>{JSON.stringify(lists, null, 2)}</pre>
        </Paper>
      </div>
    </DragDropContext>
  );
};

export default DragDropLists;
