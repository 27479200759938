import i18next from 'app/translations/i18n';

export const MODEL_TEXT = 'license-publications';

export const ModelErrors = [{ code: 412, message: i18next.t('licensesPublications:toast_error_412') }];

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('licenses:toast_update') },
];
