import React from 'react';

const FixedLayoutEditorContainer = React.lazy(() => import('../modules/fixedLayoutEditor/FixedLayoutEditorContainer'));

export const PATH_FIXED_LAYOUT_EDITOR = '/fixedlayout-editor/:id';

const PAGE_FIXED_LAYOUT_EDITOR = {
  component: FixedLayoutEditorContainer,
  path: PATH_FIXED_LAYOUT_EDITOR,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_FIXED_LAYOUT_EDITOR];
