import { getEducationLevels } from 'app/crud/educationLevels.crud';
import { getEducationYears } from 'app/services/contentsType.service';
import { getOptionByGuid } from 'app/utils/utils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_EDUCATION_LEVELS, REDUCER_EDUCATION_LEVELS } from '../conf';

export const actionTypes = {
  GetEducationLevels: 'cms/GET_EDUCATION_LEVELS',
  SetEducationLevels: 'cms/SET_EDUCATION_LEVELS',
};

const initialState = {
  levels: [],
  years: [],
  disciplines: [],
  loading: false,
  educationCountries: [],
};

export const reducer = persistReducer(
  { storage, key: PERSIST_EDUCATION_LEVELS, whitelist: ['levels', 'years', 'disciplines'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GetEducationLevels:
        return { ...state, loading: true };

      case actionTypes.SetEducationLevels: {
        const { response } = action.payload;
        if (response && response.status === 'success' && response.data) {
          return response.data.educationLevels
            ? {
                ...state,
                disciplines: response.data.disciplines,
                levels: response.data.educationLevels,
                loading: false,
                years: getEducationYears([...response.data.educationLevels]),
              }
            : { ...state, educationCountries: response.data.levels };
        }

        return { ...state, loading: false };
      }

      default:
        return state;
    }
  }
);

export const selectors = {
  getEducationLevels: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].levels : null;
  },
  getEducationYears: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].years : null;
  },
  getDisciplines: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].disciplines : null;
  },
  getEducationCountries: (state) => {
    return state.entities && state.entities[REDUCER_EDUCATION_LEVELS] ? state.entities[REDUCER_EDUCATION_LEVELS].educationCountries : null;
  },
};

export const utils = {
  isEducationYearInsideEducationLevel: (educationLevels, educationLevelGuid, educationYearGuid) => {
    var inside = false;
    if (educationLevels) {
      const educationLevelFind = getOptionByGuid(educationLevels, educationLevelGuid);
      if (educationLevelFind && educationLevelFind.educationYears) {
        const educationYearFind = getOptionByGuid(educationLevelFind.educationYears, educationYearGuid);
        inside = educationYearFind ? true : false;
      }
    }
    return inside;
  },
};

export const actions = {
  getEducationLevels: (country) => ({ type: actionTypes.GetEducationLevels, payload: country }),
  fulfillEducationLevels: (response) => ({ type: actionTypes.SetEducationLevels, payload: { response } }),
};

function* fetchEducationLevels(country) {
  const { data } = yield getEducationLevels(country);
  yield put(actions.fulfillEducationLevels(data));
}

function* getEducationLevelsIfNeeded(action) {
  const levels = yield select(selectors.getEducationLevels);
  if (!levels || levels.length === 0) {
    yield fetchEducationLevels(action.payload);
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetEducationLevels, getEducationLevelsIfNeeded);
}
