import { setMetadataArraySelect, setMetadataSelect } from 'app/services/metadata.service';
import { USER_ROLES } from 'app/services/users.service';
import i18next from 'app/translations/i18n';
import { requiredIf } from 'app/utils/yupHelpers';
import { DATE, INTEGER, MAX, POSITIVE, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'license';

export const Model = {
  code: '',
  countdown_original: '',
  country: undefined,
  expired_at: new Date(),
  expires: 'expiration', // Radio button 'expiration', 'interval'
  expires_after_number: 1,
  expires_after_string: '',
  langs: [],
  role_guid: USER_ROLES.student,
};

Yup.addMethod(Yup.string, 'requiredIf', requiredIf);
Yup.addMethod(Yup.number, 'requiredIfNumber', requiredIf);

export const ModelYup = Yup.object().shape({
  countdown_original: Yup.number().typeError(REQUIRED).max(2147483647, MAX).integer(INTEGER).positive(POSITIVE).required(REQUIRED),
  expired_at: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'expiration', REQUIRED),
  expires: Yup.string(DATE),
  expires_after_number: Yup.number(DATE).requiredIfNumber(Yup.ref('expires'), 'expires_after', REQUIRED).positive(POSITIVE).integer(INTEGER),
  expires_after_string: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'interval', REQUIRED),
  langs: Yup.array().of(Yup.object({})),
  role_guid: Yup.string(),
});

export const ModelYupMultiCountry = Yup.object().shape({
  countdown_original: Yup.number().typeError(REQUIRED).max(2147483647, MAX).integer(INTEGER).positive(POSITIVE).required(REQUIRED),
  country: Yup.object().required(REQUIRED),
  expired_at: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'expiration', REQUIRED),
  expires: Yup.string(DATE),
  expires_after_number: Yup.number(DATE).requiredIfNumber(Yup.ref('expires'), 'expires_after', REQUIRED).positive(POSITIVE).integer(INTEGER),
  expires_after_string: Yup.string(DATE).requiredIf(Yup.ref('expires'), 'interval', REQUIRED),
  langs: Yup.array().of(Yup.object({})),
  role_guid: Yup.string(),
});

export const ModelErrors = [
  { error: 'LP_REDEEM_03', message: i18next.t('licenses:error_no_left_uses') },
  { error: 'LP_REDEEM_04', message: i18next.t('licenses:code_already_linked') },
  { error: 'LP_REDEEM_06', message: i18next.t('licenses:error_license_role_constraint') },
  { error: 'LP_REDEEM_07', message: i18next.t('licenses:error_country_mismatch') },
];

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('licenses:toast_update') },
];

export function sanitizeData(data, countries) {
  const findCountry = countries.find((country) => country.guid === data.personalLicenses[0].country_guid);
  const license = { ...data.personalLicenses[0], country_name: findCountry?.country };
  const expires_after = license.expires_after ? license.expires_after.split(' ') : license.expires_after;

  return {
    code: license.code ? license.code : Model.code,
    countdown_original: license.countdown_original ? license.countdown_original : Model.countdown_original,
    country: license.country_guid ? setMetadataSelect(license, 'country_name', 'country_guid') : Model.country,
    expired_at: license.expired_at ? license.expired_at : Model.expired_at,
    expires: expires_after ? 'interval' : Model.expires,
    expires_after_number: expires_after ? expires_after[0] : Model.expires_after_number,
    expires_after_string: expires_after ? expires_after[1] : Model.expires_after_string,
    guid: license.guid,
    langs: setMetadataArraySelect(license.langs, 'lang', 'id'),
    role_guid: license.role_guid ? license.role_guid : Model.role_guid,
    batch_guid: license.batch_guid,
    batch_number: license.batch_number,
    created_at: license.created_at,
    creator_avatar: license.creator_avatar,
    creator_guid: license.creator_guid,
    creator_lastname: license.creator_lastname,
    creator_name: license.creator_name,
  };
}
