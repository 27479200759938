import { USER_ROLES } from 'app/services/users.service';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PERSIST_UI, REDUCER_UI } from '../conf';
import { actionTypes as actionAuth } from './auth.duck';

export const actionTypes = {
  SearchHeaderbar: 'ui/SEARCH_HEADERBAR',
  ChangeTheme: 'ui/CHANGE_THEME',
  ShowCreateClass: 'ui/SHOW_CREATE_CLASS',
  ShowInfoDialog: 'ui/SHOW_INFO_DIALOG',
  ChangeShowMigrationPopup: 'ui/CHANGE_SHOW_MIGRATION_POPUP',
  SetHealthStatus: 'ui/SET_HEALTH_STATUS',
  SetEmbedFrom: 'ui/SET_EMBED_FROM',
};

const initialState = {
  headerSearch: undefined,
  theme: 'light',
  showCreateClass: false,
  infoDialog: { text: null },
  showMigrationPopup: false,
  embedFrom: null,
  healthStatus: {
    code: 200,
    title: '',
    message: '',
  },
};

export const reducer = persistReducer(
  { storage, key: PERSIST_UI, blacklist: ['headerSearch', 'showCreateClass', 'infoDialog'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SearchHeaderbar:
        return { ...state, headerSearch: action.payload.value };

      case actionTypes.ChangeTheme:
        return { ...state, theme: action.payload.theme };

      case actionTypes.ShowCreateClass:
        return { ...state, showCreateClass: action.payload };

      case actionTypes.ShowInfoDialog:
        return { ...state, infoDialog: action.payload };

      case actionTypes.ChangeShowMigrationPopup:
        return { ...state, showMigrationPopup: action.payload };

      case actionTypes.SetHealthStatus: {
        return { ...state, healthStatus: action.payload };
      }

      case actionTypes.SetEmbedFrom: {
        return { ...state, embedFrom: action.payload };
      }

      case actionAuth.UserLoaded: {
        const { user } = action.payload;

        console.log('UserLoaded: ', user);

        return { ...state, showMigrationPopup: !user.data.oraculo_id && user.data.role_guid === USER_ROLES.teacher && user.data.source !== 'lti' };
      }

      case actionAuth.Logout:
        return initialState;

      default:
        return state;
    }
  }
);

export const selectors = {
  getSearchHeaderbar: (state) => {
    return state[REDUCER_UI].headerSearch;
  },
  getTheme: (state) => {
    return state[REDUCER_UI].theme;
  },
  getShowCreateClass: (state) => {
    return state[REDUCER_UI].showCreateClass;
  },
  getInfoDialog: (state) => {
    return state[REDUCER_UI].infoDialog;
  },
  getShowMigrationPopup: (state) => {
    return state[REDUCER_UI].showMigrationPopup;
  },
  getHealthStatus: (state) => {
    return state[REDUCER_UI].healthStatus;
  },
  getEmbedFrom: (state) => {
    return state[REDUCER_UI].embedFrom;
  },
};

export const actions = {
  searchHeaderbar: (value) => ({ type: actionTypes.SearchHeaderbar, payload: { value } }),
  setTheme: (theme) => ({ type: actionTypes.ChangeTheme, payload: { theme } }),
  setShowInfoDialog: (value) => ({ type: actionTypes.ShowInfoDialog, payload: value }),
  changeShowMigrationPopup: (value) => ({ type: actionTypes.ChangeShowMigrationPopup, payload: value }),
  setHealthStatus: (value) => ({ type: actionTypes.SetHealthStatus, payload: value }),
  setEmbedFrom: (value) => ({ type: actionTypes.SetEmbedFrom, payload: value }),
};
