import * as utils from '../utils/utils';

const localStorageLastLocationKey = '__core';

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    //lastLocation.pathname !== "/" &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname.indexOf('search') === -1 &&
    lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = [];
  try {
    _useLocations = localStorateLocations ? JSON.parse(localStorateLocations) : [];
  } catch (e) {
    console.log(e, 'error saveLastLocation');
  }

  if (acceptLocation(lastLocation)) {
    _useLocations.push(lastLocation.pathname);
    utils.setStorage(localStorageLastLocationKey, JSON.stringify(_useLocations), 120);
  }
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return '/';
  }

  let _userLocations = [];
  try {
    _userLocations = JSON.parse(localStorateLocations);
  } catch (e) {
    console.log(e, 'error getLastLocation');
  }

  const result = _userLocations.length > 0 ? _userLocations.pop() : '/';
  return result;
}
