import Icon from '__core/modules/atoms/Icons';
import clsx from 'clsx';
import React from 'react';
import { DefaultToast } from 'react-toast-notifications';

const ToastCustom = ({ appearance, children, isList, onDismiss, ...props }) => {
  function getIcon() {
    if (appearance === 'info') return 'icon-toast-info';
    if (appearance === 'success') return 'icon-toast-success';
    if (appearance === 'warning') return 'icon-toast-warning';
    if (appearance === 'error') return 'icon-toast-error';
  }

  function onClose() {
    onDismiss();
  }

  return (
    <div className={clsx({ 'toast--list': isList })}>
      <DefaultToast appearance={appearance} {...props}>
        <div
          className={clsx('toast', {
            'toast--info': appearance === 'info',
            'toast--success': appearance === 'success',
            'toast--warning': appearance === 'warning',
            'toast--error': appearance === 'error',
          })}
        >
          <div className="d-flex align-items-center">
            <Icon type={getIcon()} /> <div className="ml-2">{children}</div>
          </div>

          <div className="d-flex align-items-center hover-basic ml-3" onClick={onClose}>
            <Icon type="icon-close" />
          </div>
        </div>
      </DefaultToast>
    </div>
  );
};

export default ToastCustom;
