import SelectCustomizable, { utilsSelectCustomizable } from '__core/modules/atoms/SelectCustomizable';
import { types } from 'app/store/index';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SelectContentType = React.forwardRef(function ({ disabled, menuPosition, name, onBlur, onChange, isSkeleton, required, value }, ref) {
  const { t } = useTranslation(['common']);
  const [defaultValue, setDefaultValue] = React.useState(undefined);
  const list = useSelector((state) => types.selectors.getTypes(state));
  const options = utilsSelectCustomizable.getOptionsProperties(list, 'name', 'guid');
  const optionsOrder = getList(options);

  React.useEffect(getDefaultValue, [value]);

  function onChangeSelect(event) {
    onChange(event.value);
  }

  function getDefaultValue() {
    const find = options.find((item) => item.value === value);

    if (find) {
      setDefaultValue(find);
    } else {
      setDefaultValue(undefined);
    }
  }

  function getList(data) {
    return orderBy(data, ['label'], ['asc']);
  }

  return (
    <SelectCustomizable
      isDisabled={disabled}
      isSkeleton={isSkeleton}
      label={t('content_type')}
      menuPosition={menuPosition}
      name={name}
      onBlur={onBlur}
      onChange={onChangeSelect}
      options={optionsOrder}
      required={required}
      value={defaultValue}
    />
  );
});

SelectContentType.propTypes = {
  disabled: PropTypes.bool,
  menuPosition: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isSkeleton: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
};

SelectContentType.defaultProps = {
  menuPosition: 'initial',
  onBlur: () => null,
  onChange: () => null,
};

export default SelectContentType;
