import * as Sentry from '@sentry/react';
import { defaultConfig } from 'app/config/environment';

export function initSentry() {
  const { DOMAIN } = defaultConfig;
  const dsn = process.env.REACT_APP_API_CONFIG_SENTRY;

  if (dsn) {
    Sentry.init({
      dsn,
      integrations: [Sentry.browserTracingIntegration()], // Sentry.replayIntegration()
      // Performance Monitoring
      tracesSampleRate: 0.2, //  Capture 20% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [DOMAIN],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      denyUrls: ['epub', 'fixedLayoutEditor', 'lib', 'visor', 'public'],
    });

    console.log(`%cSentry Init`, 'color: #007aff; font-weight: bold;');
  }
}
