export const EN = {
  VIEWER_LANG_TYPE: 'en',
  VISOR_DATA_ACTION_NOT_POSSIBLE: 'Currently, it is not possible to perform this action.',
  VISOR_FILE_HAVE_ERROR: 'Content error.',
  VISOR_FILE_NOT_FOUND: 'Error, content not found.',
  VISOR_FILE_TYPE_UNDEFINED: 'Error, unidentified content type.',
  VISOR_ERROR_404: 'Error 404. Content not found.',
  VISOR_ERROR_403: 'Error 403. Access forbiden.',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS: 'Do you want to delete all annotations created?',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_YES: 'YES',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_NOT: 'NO',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_OK: 'Notes deleted correctly.',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_NOOK: 'Unable to delete annotations.',
  VISOR_LIB_MENU_ADD_PLAYLIST: 'Add playlist',
  VISOR_LIB_MENU_MY_PLAYLIST: 'My playlist',
  VISOR_LIB_MENU_EVALUATE_CONTENT: 'Evaluate content',
  VISOR_LIB_MENU_RE_EVALUATE_CONTENT: 'Requalify',
  VISOR_LIB_MENU_REPORT_CONTENT: 'Report content',
  VISOR_LIB_COMMENTS_CANCELAR: 'CANCEL',
  VISOR_LIB_PLAYLIST_GROUP_TITLE: 'Favorites list',
  VISOR_LIB_PLAYLIST_GROUP_PLACEHOLDER: 'List name',
  VISOR_LIB_PLAYLIST_GROUP_EMPTY: 'No list group',
  VISOR_LIB_PLAYLIST_GROUP_SAVE_NEW: 'Create',
  VISOR_LIB_PLAYLIST_GROUP_CREATE_NEW: '+ Create new list',
  VISOR_LIB_PLAYLIST_SAVE_NEW: 'Create',
  VISOR_LIB_PLAYLIST_INSTANT: 'Starts at',
  VISOR_LIB_PLAYLIST_IN: 'List in',
  VISOR_LIB_PLAYLIST_DELETE: 'Delete',
  VISOR_LIB_PLAYLIST_CREATED: 'Created lists',
  VISOR_LIB_PLAYLIST_EMPTY: 'No lists created yet',
  VISOR_LIB_ERRORS_DESCRIPTION: 'Describe the problem (optional)',
  VISOR_LIB_ERRORS_ERROR_CONTENT: 'Content error',
  VISOR_LIB_ERRORS_INAPROPIATE: 'Content inappropriate',
  VISOR_LIB_ERRORS_RAZON: 'What went wrong?',
  VISOR_LIB_ERRORS_REPORT_PROBLEM: 'Report a problem',
  VISOR_LIB_EVALUATE_DIFFICULT: 'What is your level of difficulty during your studies?',
  VISOR_LIB_EVALUATE_UTIL: 'How satisfied are you with the content?',
  VISOR_LIB_EVALUATE_TITLE: 'Evaluate the content you have studied',
  VISOR_LIB_EVALUATE_SAVE: 'Send',
  VISOR_LIB_PDFTRON_TITLE_SHARE: 'Share in',
  VISOR_LIB_PDFTRON_SHARE_GCLASSROOM: 'Google Classroom',
  VISOR_LIB_PDFTRON_SHARE_MSTEAMS: 'Microsoft Teams',
  VISOR_LIB_PDFTRON_SHARE_CANCEL: 'Cancel',
  VISOR_LIB_PDFTRON_TOOL_DRAWING: 'Drawing',
  VISOR_LIB_PDFTRON_DELETE_ALL_ANNOTS: 'Delete all annotations',
  VISOR_LIB_PDFTRON_ERROR_FILE: 'Content error, please try again in a moment.',
};
