import viewerApi, { deleteAnnotationOne, getAllAnnotations } from '../api/viewerApi';

export function addAnnotation(_this, _annotation_id, _annotation_content, _callback) {
  var _data = {};

  _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      guid: _annotation_content ? _annotation_id : _annotation_id.guid,
      annotation: _annotation_content ? String(_annotation_content) : String(_annotation_id.content),
      parent: _annotation_id.parent || null,
    },
    type: 'POST_ANNOTATION',
  };

  if (typeof _callback == 'function') {
    viewerApi(
      _data,
      function (e) {
        _callback(e);
      }.bind(_this)
    );
  } else {
    viewerApi(_data, function (e) {}.bind(_this));
  }
}

export function updateAnnotation(_this, _annotation_id, _annotation_content, _callback) {
  var _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      guid: _annotation_content ? _annotation_id : _annotation_id.guid,
      annotation: _annotation_content ? String(_annotation_content) : String(_annotation_id.content),
      parent: _annotation_id.parent || null,
    },
    type: 'PUT_ANNOTATION',
  };

  _callback = _annotation_id && _annotation_id.callback && typeof _annotation_id.callback === 'function' ? _annotation_id.callback : _callback;

  if (typeof _callback == 'function') {
    viewerApi(
      _data,
      function (e) {
        _callback(e);
      }.bind(_this)
    );
  } else {
    viewerApi(_data, function (e) {}.bind(_this));
  }
}

export async function deleteAnnotation(_this, annotationElement) {
  console.log(annotationElement);
  if (annotationElement && annotationElement.fn && typeof annotationElement.fn === 'function') {
  } else {
    alert('UNDEFINED annotationElement.fn');
    return;
  }
  if (annotationElement && annotationElement.ids) {
  } else {
    alert('UNDEFINED annotationElement.ids');
    return;
  }
  if (annotationElement && annotationElement.status) {
  } else {
    alert('UNDEFINED annotationElement.status');
    return;
  }
  if (annotationElement.ids === 'full') {
    let allAnnots = await getAllAnnotations(_this.state.data.content_guid);
    if (allAnnots && allAnnots.data && allAnnots.data.data && allAnnots.data.data.length > 0) {
      processDelete(_this, allAnnots.data.data, 0, annotationElement.fn, annotationElement.status);
    } else if (allAnnots && allAnnots.data && allAnnots.data.data && allAnnots.data.data.length === 0) {
      annotationElement.fn({ status: 'none' });
    } else {
      alert('OTHER ERROR getAllAnnotations');
      return;
    }
  } else {
    processDelete(_this, annotationElement.ids, 0, annotationElement.fn, annotationElement.status);
  }
}

async function processDelete(_this, _array, _index, _callback, _status) {
  _status(_index + 1 + '/' + _array.length);
  let annotGuid = typeof _array[_index] === 'string' ? _array[_index] : _array[_index].guid;
  let _return = await deleteAnnotationOne(_this.state.data.content_guid, String(annotGuid));
  _index = _index + 1;
  if (_array.length === _index) {
    _callback(_return.data);
  } else {
    processDelete(_this, _array, _index, _callback, _status);
  }
}
