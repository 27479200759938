import axios from 'axios';

export const USERS = 'users';

export function getUser(guid, params) {
  return axios.get(`${USERS}/${guid}`, { params });
}

export function getUsers(params) {
  return axios.get(USERS, { params });
}

export function createUser(params) {
  return axios.post(USERS, params);
}

export function updateUser(paramsPut) {
  const { guid, ...params } = paramsPut;

  return axios.put(`${USERS}/${guid}`, params);
}

export function deleteUser(params = { guid: [] }) {
  return axios.delete(USERS, { data: params });
}

// ----------------------------------------------------

export function getRoles() {
  return axios.get(`${USERS}/roles`);
}

// --------------------------------------------------------------------------------
//  CREATE METADATA:
// --------------------------------------------------------------------------------

export function createUserSchools(userGuid, guids) {
  return axios.post(`${USERS}/${userGuid}/schools`, { guid: guids });
}

// --------------------------------------------------------------------------------
//  USER LICENSES METADATA:
// --------------------------------------------------------------------------------
export function getUserLicenses(userGuid, params) {
  return axios.get(`${USERS}/${userGuid}/license-personals`, { params });
}

export function deleteUserLicenses(userGuid, params = { guid: [] }) {
  return axios.delete(`${USERS}/${userGuid}/license-personals`, { data: params });
}

export function redeemLicense(userGuid, code) {
  return axios.post(`${USERS}/${userGuid}/redeem`, { code });
}
