export const hasData = (val) => val && val.toString().length > 0;
export const isBlankSpace = (val) => /^\s+$/.test(val);
export const isNumber = (val) => !isNaN(val);
export const minLength = (length, val) => val.length >= length;
export const string = (val) => typeof val === 'string';

export const isURL = (val) => /^(https?:\/\/)(www\.)?[A-Z0-9.-]+\.[A-Z]{2,}(\/\S*)?$/i.test(val?.toLowerCase());

export const isArray = (val) => {
  if (!Array.isArray) {
    return Object.prototype.toString.call(val) === '[object Array]';
  } else {
    return Array.isArray(val);
  }
};

export const isEmptyObj = (obj) => {
  if (obj) {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
  }
  return true;
};
