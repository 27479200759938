import { useTranslation } from 'react-i18next';

function useErrorBoundaryPage() {
  const { t } = useTranslation(['errors']);

  function onReload() {
    window.location.reload();
  }

  return { onReload, t };
}

export default useErrorBoundaryPage;
