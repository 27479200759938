import Button from '__core/modules/atoms/Button';
import useToast from 'app/hooks/useToast';
import React from 'react';

function UIToast() {
  const { openToast } = useToast();

  function toastOpen(state) {
    openToast({ state, timeout: 300000, message: 'Esto es un mensaje de prueba para la UI de BackOffice', isList: true });
  }

  return (
    <div>
      <p>Toast</p>

      <div>
        <Button onClick={() => toastOpen('info')} text="Info →" variant="inline" className="mr-3" color="info" />
        <Button onClick={() => toastOpen('success')} text="Success →" variant="inline" className="mr-3" color="success" />
        <Button onClick={() => toastOpen('warning')} text="Warning →" variant="inline" className="mr-3" color="warning" />
        <Button onClick={() => toastOpen('error')} text="Error →" variant="inline" className="mr-3" color="error" />
      </div>
    </div>
  );
}

export default UIToast;
