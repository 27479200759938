import axios from 'axios';

export const TAGS = 'cms/tags';

export function getTags(params) {
  return axios.get(TAGS, { params });
}

export function postTags(tags) {
  const params = { tag: tags };
  return axios.post(TAGS, params);
}
