import Button from '__core/modules/atoms/Button';
import React from 'react';
import useErrorBoundaryPage from './useErrorBoundaryPage';

function ErrorBoundaryPage() {
  const { onReload, t } = useErrorBoundaryPage();

  return (
    <div className="error-boundary-page">
      <div>
        <img src="/images/svg/errorPage.svg" alt="Error" />
        <h1 className="text-title-strong">{t('Something went wrong')}</h1>
        <p className="text-span">{t('Please reload the page or try again later')}</p>

        <Button text={t('Reload page')} onClick={onReload} variant="inline" />
      </div>
    </div>
  );
}

export default ErrorBoundaryPage;
