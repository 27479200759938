import { defaultConfig } from 'app/config/environment';

export function getLevelThroughYear(levels, year) {
  const find = levels.find((level) => level.educationYears.find((item) => item.guid === year.value));

  return { label: find?.level, value: find?.guid };
}

export const getEducationYears = (educationLevels) => {
  let educationYears = [];

  educationLevels.forEach((element) => {
    element.educationYears.map((item) => {
      item.name = `${element.level}: ${item.year}`;
      item.education_year_name_simple = item.year;
      item.education_year_name = `${element.level}: ${item.year}`;
      item.education_year_guid = item.guid;

      return item;
    });

    educationYears.push(...element.educationYears);
  });

  return educationYears;
};

export function getUrlToOpenContent(url, authToken) {
  const BASE_URL = defaultConfig.BASE_URL_.slice(0, -1);
  let newUrl = url;

  if (url && url.indexOf('/files-storage/') >= 0) {
    newUrl = BASE_URL + url + '?token=' + authToken || null; //TO REMOVE THE SLASH OF THE URL
  }

  return newUrl;
}
