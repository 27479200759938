import { setMetadataArraySelect, setMetadataSelect } from 'app/services/metadata.service';
import { getTemplate } from 'app/services/programs.service';
import i18next from 'app/translations/i18n';
import { MAX, MIN, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

const TEMPLATE_VANILLA = 'vanilla';
const TEMPLATES = getTemplate();
const VANILLA_TEMPLATE = TEMPLATES.find((item) => item.template_type === TEMPLATE_VANILLA);

export const MODEL_TEXT = 'program';

export const Model = {
  background: '',
  background_mint: '',
  body_typeface_guid: '',
  book_guid: '',
  book_name: '',
  book_thumbnail: '',
  colour_mint: '',
  config: '',
  country: '',
  description: '',
  discipline: '',
  educationLevels: '',
  educationYears: '',
  evaluation_period: undefined,
  heading_typeface_guid: '',
  is_accessible: false,
  isbn_student: '',
  isbn_teacher: '',
  langs: [],
  name: '',
  picture: '',
  program_cover: '',
  publication_guid: '',
  structure_levels: 2,
  subtitle: '',
  template: { label: VANILLA_TEMPLATE?.description, value: VANILLA_TEMPLATE?.template_type, ...VANILLA_TEMPLATE },
  templateConfig: '{}',
};

export const ModelYup = Yup.object().shape({
  discipline: Yup.object().required(REQUIRED),
  educationLevels: Yup.object().required(REQUIRED),
  educationYears: Yup.object().required(REQUIRED),
  isbn_student: Yup.string().max(80, MAX),
  isbn_teacher: Yup.string().max(80, MAX),
  langs: Yup.array(),
  name: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
  picture: Yup.string(),
  program_cover: Yup.string(),
  publication_guid: Yup.string().required(REQUIRED),
});

export const ModelYupProgrammingAdvanced = Yup.object().shape({
  discipline: Yup.object().required(REQUIRED),
  educationLevels: Yup.object().required(REQUIRED),
  educationYears: Yup.object().required(REQUIRED),
  evaluation_period: Yup.string().required(REQUIRED),
  isbn_student: Yup.string().max(80, MAX),
  isbn_teacher: Yup.string().max(80, MAX),
  langs: Yup.array(),
  name: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
  picture: Yup.string(),
  program_cover: Yup.string(),
  publication_guid: Yup.string().required(REQUIRED),
});

export const ModelErrors = [{ errno: 1062, message: i18next.t('digitalPrograms:error_isbn_exist') }];

export const ModelSuccess = [
  { id: 'create', message: i18next.t('digitalPrograms:toast_create') },
  { id: 'update', message: i18next.t('digitalPrograms:toast_update') },
];

export function sanitizeData(data) {
  return {
    background: data.background ? data.background : '',
    background_mint: data.background_mint ? data.background_mint : '',
    body_typeface_guid: data.body_typeface_guid,
    book_guid: data.book_guid,
    book_name: data.book_name,
    book_thumbnail: data.book_thumbnail,
    colour_mint: data.colour_mint,
    config: data.config ? data.config : Model.config,
    country: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country,
    description: data.description ? data.description : '',
    discipline: data.education_discipline_guid ? setMetadataSelect(data, 'discipline_name', 'discipline_guid') : Model.discipline,
    educationLevels: data.education_level_guid ? setMetadataSelect(data, 'education_level_name', 'education_level_guid') : Model.educationLevels,
    educationYears: data.education_year_guid ? setMetadataSelect(data, 'education_year_name', 'education_year_guid') : Model.educationYears,
    evaluation_period: data.evaluation_period ? data.evaluation_period : Model.evaluation_period,
    guid: data.guid,
    heading_typeface_guid: data.heading_typeface_guid,
    is_accessible: data.is_accessible ? true : false,
    isbn_student: data.isbn_student ? data.isbn_student : '',
    isbn_teacher: data.isbn_teacher ? data.isbn_teacher : '',
    langs: data.langs ? setMetadataArraySelect(data.langs, 'description', 'id') : Model.langs,
    name: data.name ? data.name : Model.name,
    pdf_ia_ingested_at: data.pdf_ia_ingested_at,
    pdf_pages_enabled: data.pdf_pages_enabled,
    picture: data.picture ? data.picture : '',
    program_cover: data.program_cover ? data.program_cover : '',
    publication_guid: data.publication_guid ? data.publication_guid : Model.publication_guid,
    structure_levels: data?.structure_levels ? data.structure_levels : Model.structure_levels,
    subtitle: data.subtitle ? data.subtitle : '',
    template: data.template && data.template.template_type ? setMetadataSelect(data.template, 'description', 'template_type') : Model.template,
    templateConfig: data.template ? data.template.json_config : Model.templateConfig,
  };
}
