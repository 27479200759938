import React from 'react';

const AnalyticProgramsPage = React.lazy(() => import('../pages/analytics/analyticProgram/AnalyticProgramsPage/AnalyticProgramsPage'));
const AnalyticProgramDetailPage = React.lazy(() => import('../pages/analytics/analyticProgram/AnalyticProgramDetailPage/AnalyticProgramDetailPage'));

export const PATH_ANALYTIC_PROGRAM_DETAIL = '/analytic/programs/:guid';
export const PATH_ANALYTIC_PROGRAMS = '/analytic/programs';

const PAGE_ANALYTIC_PROGRAM_DETAIL = {
  component: AnalyticProgramDetailPage,
  path: PATH_ANALYTIC_PROGRAM_DETAIL,
};

const PAGE_ANALYTIC_PROGRAMS = {
  component: AnalyticProgramsPage,
  path: PATH_ANALYTIC_PROGRAMS,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_ANALYTIC_PROGRAM_DETAIL, PAGE_ANALYTIC_PROGRAMS];
