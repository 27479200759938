import { USER_ROLES } from 'app/services/users.service';
import axios from 'axios';

export const LOGIN_URL = 'login';
export const CHANGE_PASS_URL = 'users/change-password';
export const CREATE_USER_URL = 'users';
export const EDIT_USER_URL = 'users';
export const EDIT_PROFILE = 'front/profile';
export const LOGIN_APP_URL = 'p+/login-app';
export const LOGIN_SSO_URL = 'p+/login-sso';
export const ME_URL = 'users/whoami';
export const RECOVER_ACCOUNT = 'front/recover-account';
export const RECOVER_ACCOUNT_PASSWORD = 'front/recover-account-password';
export const REGISTER_URL = 'register';
export const REQUEST_PASSWORD_URL = 'forgot-password';
export const SIGN_UP_USER_URL = 'front/sign-up/user';
export const SIGN_UP_LICENSE = 'front/sign-up/license/';
export const CHECK_USER = 'front/school-admin/check/check-users';
export const LOGIN_OAUTH = 'login-oauth';
export const LOGIN_OAUTH_URL = 'login-oauth';
export const MAGIC_LINK = 'users/magic-link';

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password /*, admitted_roles: ["1","3"]*/ });
}

export function loginSSO(token, identity_provider) {
  return axios.post(LOGIN_OAUTH, { token, identity_provider });
}

export function register(email, fullname, password) {
  return axios.post(REGISTER_URL, { email, fullname, password });
}

export function getUserByToken(params) {
  // Authorization head should be fulfilled in interceptor.
  if (params && params.token) {
    return axios.get(ME_URL, {
      headers: {
        Authorization: params.token,
      },
    });
  }
  return axios.get(ME_URL);
}

export function editUser(guid, name, lastname, email) {
  return axios.put(EDIT_USER_URL + '/' + guid, { name, lastname, email });
}

export function editProfile(user) {
  return axios.put(EDIT_PROFILE, user);
}

export function editPass(passActual, passNew) {
  return axios.put(CHANGE_PASS_URL, { current: passActual, new: passNew, source: 'profile' });
}

export function createUser(name, lastname, email, username, password, roleGuid, changePassword = false) {
  return axios.post(CREATE_USER_URL, { name, lastname, email, username, password, role_guid: roleGuid, change_password: changePassword ? 1 : 0 });
}

export function recoverAccount(email, params) {
  return axios.post(RECOVER_ACCOUNT, { email, ...params });
}

export function recoverAccountPassword(token, password) {
  return axios.post(RECOVER_ACCOUNT_PASSWORD, { token, new_password: password });
}

export function signupUser(payload) {
  return axios.post(SIGN_UP_USER_URL, payload);
}

export function checkUser(user) {
  return axios.post(CHECK_USER, user);
}

export function loginOauth(token, identity_provider) {
  return axios.post(LOGIN_OAUTH_URL, { token, identity_provider });
}

export function loginWithUserGuid(userGuid) {
  return axios.post(`users/${userGuid}/login`, {});
}

export function loginBackoffice(email, password) {
  return axios.post(LOGIN_URL, { email, password, admitted_roles: [USER_ROLES.admin, USER_ROLES.helpDesk, USER_ROLES.adminFixedLayout, USER_ROLES.publisher] });
}
