import { permissionsRol as collectionRol } from 'app/policies/roles/collectionsRol.access';
import { permissionsRol as contentRol } from 'app/policies/roles/contentsRol.access';
import { permissionsRol as programRol } from 'app/policies/roles/programsRol.access';
import { permissionsRol as publicationRol } from 'app/policies/roles/publicationsRol.access';
import { PATH_COLLECTIONS } from 'app/router/routesConfigCollections';
import { PATH_CONTENTS } from 'app/router/routesConfigContents';
import { PATH_PROGRAMS } from 'app/router/routesConfigPrograms';
import { PATH_PUBLICATIONS } from 'app/router/routesConfigPublications';

export function getRedirect(user) {
  const USER_FTD = 'a44067a1-52d5-11ee-940a-4d25fb80326f';
  const PROGRAM_GUID = 'fc1c0b50-424f-11ee-86ba-3dc126597206';
  if (user?.guid === USER_FTD) return `/program/mint-stand-alone/${PROGRAM_GUID}/TEACHER`;

  // Este if es para redirigir a la primera vista que el usuario tenga permisos
  if (contentRol(user).readList) {
    return PATH_CONTENTS;
  }

  if (collectionRol(user).readList) {
    return PATH_COLLECTIONS;
  }

  if (programRol(user).readList) {
    return PATH_PROGRAMS;
  }

  if (publicationRol(user).readList) {
    return PATH_PUBLICATIONS;
  }

  return PATH_CONTENTS;
}
