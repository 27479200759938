import { ThemeProvider } from '@material-ui/core/styles';
import Loading from 'app/components/Loading/LoadingScreen';
import ErrorBoundary from 'app/pages/errors/ErrorBoundary';
import ErrorBoundaryPage from 'app/pages/errors/ErrorBoundaryPage';
import { ThemeBackOffice } from 'app/style/theme/themeMaterialUI';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import ToastCustom from './app/components/Toast';
import { Routes } from './app/router/Routes';
import I18nProvider from './app/translations/I18nProvider';

export default function App({ store, persistor, basename }) {
  function getUserConfirmation() {
    /* Empty callback to block the default browser prompt */
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<Loading />}>
        <React.Suspense fallback={<Loading />}>
          <BrowserRouter basename={basename} getUserConfirmation={getUserConfirmation}>
            <LastLocationProvider>
              <I18nProvider>
                <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={{ api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST }}>
                  <ToastProvider placement="bottom-left" components={{ Toast: ToastCustom }}>
                    <ThemeProvider theme={ThemeBackOffice}>
                      <ErrorBoundary fallback={<ErrorBoundaryPage />}>
                        <Switch>
                          <Routes />
                        </Switch>
                      </ErrorBoundary>
                    </ThemeProvider>
                  </ToastProvider>
                </PostHogProvider>
              </I18nProvider>
            </LastLocationProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
