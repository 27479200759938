import axios from 'axios';

export const ORGANIZATION = 'organization';
export const ORGANIZATION_UPLOAD_LOGO = 'organization/upload-logo';
export const ORGANIZATION_CSS = 'organization/css/stylesheet';

export function getOrganization() {
  return axios.get(`${ORGANIZATION}`);
}

export function getOrganizationCSS() {
  return axios.get(`${ORGANIZATION_CSS}`);
}

export function postOrganizationCSS(data) {
  return axios.post(`${ORGANIZATION_CSS}`, data);
}
