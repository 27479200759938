import React from 'react';

const AnalyticLicensesPage = React.lazy(() => import('../pages/analytics/analyticLicenses/AnalyticLicensesPage'));

export const PATH_ANALYTIC_LICENSES = '/analytic/licenses';

const PAGE_ANALYTIC_LICENSES = {
  component: AnalyticLicensesPage,
  path: PATH_ANALYTIC_LICENSES,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_ANALYTIC_LICENSES];
