import axios from 'axios';

export const LEARNING_OBJECTIVES = 'cms/learning-objectives';

export function getLearningObjectives(params) {
  return axios.get(LEARNING_OBJECTIVES, { params: params });
}

export function createLearningObjective(params) {
  return axios.post(LEARNING_OBJECTIVES, params);
}

export function updateLearningObjective(params) {
  return axios.put(`${LEARNING_OBJECTIVES}/${params.guid}`, params);
}

export function deleteLearningObjective(guid) {
  const params = { guid: [guid] };
  return axios.delete(`${LEARNING_OBJECTIVES}/${guid}`, { data: params });
}
