import { ModelErrors as ModelErrorsAuth, MODEL_TEXT as TEXT_AUTH } from 'app/models/auth';
import { ModelSuccess as ModelSuccessCollection, MODEL_TEXT as TEXT_COLLECTION } from 'app/models/collection';
import { ModelErrors as ModelErrorsContent, ModelSuccess as ModelSuccessContent, MODEL_TEXT as TEXT_CONTENT } from 'app/models/content';
import { ModelErrors as ModelErrorsLicense, ModelSuccess as ModelSuccessLicense, MODEL_TEXT as TEXT_LICENSE } from 'app/models/license';
import { ModelSuccess as ModelSuccessLicenseBatch, MODEL_TEXT as TEXT_LICENSE_BATCH } from 'app/models/licenseBatch';
import {
  ModelErrors as ModelErrorsLicensePublications,
  ModelSuccess as ModelSuccessLicensePublications,
  MODEL_TEXT as TEXT_LICENSE_PUBLICATIONS,
} from 'app/models/licensePublications';
import { ModelErrors as ModelErrorsProgram, ModelSuccess as ModelSuccessProgram, MODEL_TEXT as TEXT_PROGRAM } from 'app/models/program';
import { ModelSuccess as ModelSuccessPublication, MODEL_TEXT as TEXT_PUBLICATION } from 'app/models/publication';
import { ModelSuccess as ModelSuccessSchool, MODEL_TEXT as TEXT_SCHOOL } from 'app/models/school';
import { ModelErrors as ModelErrorsUser, ModelSuccess as ModelSuccessUser, MODEL_TEXT as TEXT_USER } from 'app/models/user';
import i18next from 'app/translations/i18n';

export const TYPES_TOAST = { create: 'create', update: 'update' };

const errorModel = {
  [TEXT_AUTH]: ModelErrorsAuth,
  [TEXT_CONTENT]: ModelErrorsContent,
  [TEXT_LICENSE]: ModelErrorsLicense,
  [TEXT_LICENSE_PUBLICATIONS]: ModelErrorsLicensePublications,
  [TEXT_PROGRAM]: ModelErrorsProgram,
  [TEXT_USER]: ModelErrorsUser,
};

const successModel = {
  [TEXT_COLLECTION]: ModelSuccessCollection,
  [TEXT_CONTENT]: ModelSuccessContent,
  [TEXT_LICENSE]: ModelSuccessLicense,
  [TEXT_LICENSE_BATCH]: ModelSuccessLicenseBatch,
  [TEXT_LICENSE_PUBLICATIONS]: ModelSuccessLicensePublications,
  [TEXT_PROGRAM]: ModelSuccessProgram,
  [TEXT_PUBLICATION]: ModelSuccessPublication,
  [TEXT_SCHOOL]: ModelSuccessSchool,
  [TEXT_USER]: ModelSuccessUser,
};

const errorGeneral = i18next.t('toast:error_general');
const successGeneral = i18next.t('toast:success_general');

export function getError(error, model) {
  const list = errorModel[model];
  const find = list.find((item) => searchError(item, error.error));

  return find ? find.message : errorGeneral;
}

export function getSuccess(model, type) {
  const find = successModel[model]?.find((item) => item.id === type);
  return find ? find.message : successGeneral;
}

function searchError(item, error) {
  const isErrorKey = item.errorKey && item.errorKey === error.errorKey;
  const isErrorNo = item.errno && item.errno === error.errno;
  const isError = item.error && item.error === error.error;

  return isErrorKey || isErrorNo || isError;
}
