import viewerApi from '../api/viewerApi';

export function createNewComment(_this, _e, _callback) {
  _this.setState({ visible_loading_full: true });

  //_e.content_guid
  _e.content_guid = _this.state.data.content_guid;
  _e.show_at = String(_e.show_at);
  var _data = { params: _e, type: 'POST_CONTENT_COMMENT' };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_COMMENT_NEW_OK, type: 'correct' },
        openToast: true,
      });
      _callback(e.data.data);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_COMMENT_NEW_ERROR, type: 'error' },
        openToast: true,
      });
      _callback('');
    }
  });
}

export function updateComment(_this, _e, _callback) {
  _this.setState({ visible_loading_full: true });

  //_e.content_guid
  _e.content_guid = _this.state.data.content_guid;
  _e.show_at = String(_e.show_at);
  var _data = { params: _e, type: 'PUT_CONTENT_COMMENT' };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_COMMENT_UPDATE_OK, type: 'correct' },
        openToast: true,
      });
      _callback(true);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_COMMENT_UPDATE_ERROR, type: 'error' },
        openToast: true,
      });
      _callback(false);
    }
  });
}

export function deleteComment(_this, _e, _callback) {
  _this.setState({ visible_loading_full: true });

  var _data = {
    params: {
      guid: _e.guid,
      content_guid: _this.state.data.content_guid,
    },
    type: 'DELETE_CONTENT_COMMENT',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_COMMENT_DELETE_OK, type: 'correct' },
        openToast: true,
      });
      _callback(true);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_COMMENT_DELETE_ERROR, type: 'error' },
        openToast: true,
      });
      _callback(false);
    }
  });
}
