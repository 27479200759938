import { defaultConfig } from 'app/config/environment';
import { parseFieldData } from 'app/services/contents.service';
import { setMetadataArraySelect, setMetadataArraySelectSimple, setMetadataSelect } from 'app/services/metadata.service';
import i18next from 'app/translations/i18n';
import { MAX, MIN, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

const BASE_URL = defaultConfig.BASE_URL_.substring(0, defaultConfig.BASE_URL_.length - 1);

export const MODEL_TEXT = 'content';

export const Model = {
  collections: [],
  country: '',
  customTags: [],
  data: undefined,
  dependencies: [],
  description: '',
  disciplines: [],
  educationLevels: [],
  educationYears: [],
  erp_id: '',
  is_public: undefined,
  is_teacher_only: 0,
  langs: [],
  learningObjectives: [],
  mobile_friendly: undefined,
  name: '',
  publications: [],
  question_type_guid: null,
  status: true,
  storage_status: null,
  tags: [],
  thumbnail: '',
  topics: [],
  type_guid: '',
  url: '',
};

export const ModelYup = Yup.object().shape({
  collections: Yup.array().of(Yup.object({})),
  customTags: Yup.array().of(Yup.object({})),
  dependencies: Yup.array().of(Yup.object({})),
  description: Yup.string(),
  disciplines: Yup.array().of(Yup.object({})),
  educationLevels: Yup.array().of(Yup.object({})),
  educationYears: Yup.array().of(Yup.object({})),
  erp_id: Yup.string().max(250, MAX),
  is_public: Yup.number(),
  is_teacher_only: Yup.number().required(REQUIRED),
  langs: Yup.array().of(Yup.object({})),
  learningObjectives: Yup.array().of(Yup.object({})),
  mobile_friendly: Yup.number(),
  name: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
  publications: Yup.array().of(Yup.object({})),
  tags: Yup.array().of(Yup.object({})),
  thumbnail: Yup.string(),
  data: Yup.object(),
  topics: Yup.array().of(Yup.object({})),
  type_guid: Yup.string().required(REQUIRED),
  url: Yup.string(),
});

export const ModelErrors = [{ errno: 1062, codeString: 'ER_DUP_ENTRY', message: i18next.t('contents:error_erp') }];
export const ModelSuccess = [
  { id: 'create', message: i18next.t('contents:toast_create') },
  { id: 'update', message: i18next.t('contents:toast_update') },
];

export function sanitizeData(data) {
  const isUrl = data.url && data.url.includes('http');
  const url = isUrl ? data.url : `${BASE_URL}${data.url}`;

  return {
    ...data,
    collections: setMetadataArraySelect(data.collections, 'collection'),
    country: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country,
    customTags: [],
    data: data.data ? parseFieldData(data.data) : undefined,
    dependencies: setMetadataArraySelect(data.dependencies),
    description: data.description ? data.description : Model.description,
    disciplines: setMetadataArraySelect(data.disciplines, 'discipline_name', 'discipline_guid'),
    educationLevels: setMetadataArraySelectSimple(data.educationLevels, 'education_level_name', 'education_level_guid'),
    educationYears: setMetadataArraySelectSimple(data.educationYears, 'education_year_name', 'education_year_guid'),
    erp_id: data.erp_id ? data.erp_id : Model.erp_id,
    guid: data.guid,
    is_public: data.is_public,
    is_teacher_only: data.is_teacher_only,
    langs: setMetadataArraySelect(data.langs, 'name', 'id'),
    learningObjectives: setMetadataArraySelect(data.learningObjectives, 'learning_objective'),
    mobile_friendly: data.mobile_friendly,
    name: data.name ? data.name : Model.name,
    publications: setMetadataArraySelect(data.publications),
    size: data.size,
    status: data.status === 'inactive' ? false : true,
    tags: setMetadataArraySelect(data.tags, 'tag'),
    thumbnail: data.thumbnail ? data.thumbnail : '',
    topics: setMetadataArraySelect(data.topics, 'topic'),
    type_guid: data.type_guid ? data.type_guid : Model.type_guid,
    updated_at: null,
    url: data.url ? url : '',
  };
}
