import { setMetadataSelect } from 'app/services/metadata.service';
import i18next from 'app/translations/i18n';
import { requiredIf } from 'app/utils/yupHelpers';
import { MAX, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'school';

export const Model = {
  address: '',
  address_number: '',
  city_guid: undefined,
  code: '',
  country_guid: undefined,
  enableLti: false,
  school_admin_enabled: false,
  name: '',
  neighborhood: '',
  phone: '',
  state_guid: undefined,
  type_guid: '',
  zipcode: '',

  lti_tool_id: '',
  lti_login_url: '',
  lti_public_key_url: '',
  lti_redirect_url: '',
  lti_tool_url: '',

  consumer_tool_client_id: '',
  consumer_name: '',
  consumer_deployment_id: '',
  consumer_url: '',
  consumer_authorization_keys: '',
  consumer_access_token_url: '',
  consumer_authorizartion_url: '',
};

Yup.addMethod(Yup.string, 'requiredIf', requiredIf);

export const ModelYup = Yup.object().shape({
  address: Yup.string().max(200, MAX),
  address_number: Yup.string().max(50, MAX),
  city_guid: Yup.object(),
  code: Yup.string().max(40, MAX),
  country_guid: Yup.object(),
  enableLti: Yup.bool(),
  school_admin_enabled: Yup.bool(),
  name: Yup.string().max(200, MAX),
  neighborhood: Yup.string().max(100, MAX),
  state_guid: Yup.object(),
  type_guid: Yup.string(),
  zipcode: Yup.string(),
});

export const ModelYupRequired = Yup.object().shape({
  address: Yup.string().max(200, MAX).required(REQUIRED),
  address_number: Yup.string().max(50, MAX),
  city_guid: Yup.object().required(REQUIRED),
  code: Yup.string().max(40, MAX),
  country_guid: Yup.object().required(REQUIRED),
  enableLti: Yup.bool(),
  school_admin_enabled: Yup.bool(),
  name: Yup.string().required(REQUIRED).max(200, MAX),
  neighborhood: Yup.string().max(100, MAX),
  state_guid: Yup.object().required(REQUIRED),
  type_guid: Yup.string(),
  zipcode: Yup.string().required(REQUIRED),
});

export const ModelYupRequiredLTI = Yup.object().shape({
  address: Yup.string().max(200, MAX).required(REQUIRED),
  address_number: Yup.string().max(50, MAX),
  city_guid: Yup.object().required(REQUIRED),
  code: Yup.string().max(40, MAX),
  country_guid: Yup.object().required(REQUIRED),
  enableLti: Yup.bool(),
  school_admin_enabled: Yup.bool(),
  name: Yup.string().required(REQUIRED).max(200, MAX),
  neighborhood: Yup.string().max(100, MAX),
  state_guid: Yup.object().required(REQUIRED),
  type_guid: Yup.string(),
  zipcode: Yup.string().required(REQUIRED),

  consumer_tool_client_id: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_name: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_deployment_id: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_url: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_authorization_keys: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_access_token_url: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
  consumer_authorizartion_url: Yup.string().requiredIf(Yup.ref('enableLti'), true, REQUIRED),
});

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('toast:toast_update') },
];

export function sanitizeData(data) {
  return {
    address: data.address ? data.address : Model.address,
    address_number: data.address_number ? data.address_number : Model.address_number,
    city_guid: data.city_guid ? setMetadataSelect(data, 'city_name', 'city_guid') : Model.city_guid,
    code: data.code ? data.code : Model.code,
    country_guid: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country_guid,
    enableLti: data.lti_enabled ? true : false,
    school_admin_enabled: data.school_admin_enabled ? true : false,
    guid: data.guid,
    license: getLicenseActive(data),
    licenseGuid: getLicenseActive(data, 'license_guid'),
    name: data.name ? data.name : Model.name,
    neighborhood: data.neighborhood ? data.neighborhood : Model.neighborhood,
    phone: data.phone ? data.phone : Model.phone,
    state_guid: data.state_guid ? setMetadataSelect(data, 'state_name', 'state_guid') : Model.state_guid,
    type_guid: data.type_guid ? data.type_guid : Model.type_guid,
    zipcode: data.zipcode ? data.zipcode : Model.zipcode,

    lti_tool_id: data.lti_tool_id ? data.lti_tool_id : Model.lti_tool_id,
    lti_login_url: data.lti?.login_url ? data.lti?.login_url : Model.lti_login_url,
    lti_public_key_url: data.lti?.public_key_url ? data.lti?.public_key_url : Model.lti_public_key_url,
    lti_redirect_url: data.lti?.redirect_url ? data.lti?.redirect_url : Model.lti_redirect_url,
    lti_tool_url: data.lti?.tool_url ? data.lti?.tool_url : Model.lti_tool_url,

    consumer_access_token_url: data.lti?.configuration?.consumer_access_token_url
      ? data.lti.configuration.consumer_access_token_url
      : Model.consumer_access_token_url,
    consumer_authorizartion_url: data.lti?.configuration?.consumer_authorizartion_url
      ? data.lti.configuration.consumer_authorizartion_url
      : Model.consumer_authorizartion_url,
    consumer_authorization_keys: data.lti?.configuration?.consumer_authorization_keys
      ? data.lti.configuration.consumer_authorization_keys
      : Model.consumer_authorization_keys,
    consumer_deployment_id: data.lti?.configuration?.consumer_deployment_id ? data.lti.configuration.consumer_deployment_id : Model.consumer_deployment_id,
    consumer_name: data.lti?.configuration?.consumer_name ? data.lti.configuration.consumer_name : Model.consumer_name,
    consumer_tool_client_id: data.lti?.configuration?.consumer_tool_client_id ? data.lti.configuration.consumer_tool_client_id : Model.consumer_tool_client_id,
    consumer_url: data.lti?.configuration?.consumer_url ? data.lti.configuration.consumer_url : Model.consumer_url,
  };
}

export function setLicense(school, license) {
  school.licenseGuid = license.value;
  school.license = license;
}

function getLicenseActive(data, attribute) {
  const find = data.contracts.find((item) => item.is_active);
  const isAttribute = find ? find[attribute] : '';
  const isNotAttribute = find ? { value: find?.license_guid, label: find?.school_year } : undefined;

  return attribute ? isAttribute : isNotAttribute;
}
