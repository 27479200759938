import { setMetadataArraySelect, setMetadataSelect } from 'app/services/metadata.service';
import i18next from 'app/translations/i18n';
import { MAX, PASSWORD_MIN, PASSWORD_REGEX, PASSWORD_REPEAT_TEXT, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'user';

export const Model = {
  avatar: '',
  change_password: undefined,
  country: '',
  country_guid: '',
  groups: [],
  groupsOld: [],
  is_demo: 0,
  lastname: '',
  name: '',
  oraculo_id: null,
  password: '',
  password_repeat: '',
  role: 'R01',
  school: {},
  schools: [],
  schoolsOld: [],
  username: '',
};

const regexPassword = /^$|^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
const VALIDATIONS = {
  change_password: Yup.string(),
  // email: Yup.string().max(200, MAX).email(EMAIL).required(REQUIRED),
  groups: Yup.array().of(Yup.object({})),
  lastname: Yup.string().max(50, MAX).required(REQUIRED),
  name: Yup.string().max(50, MAX).required(REQUIRED),
  password: Yup.string(),
  password_repeat: Yup.string().oneOf([Yup.ref('password'), null], PASSWORD_REPEAT_TEXT),
  role: Yup.string(),
  school: Yup.object(),
  username: Yup.string().required(REQUIRED),
};

export const ModelYup = Yup.object().shape(VALIDATIONS);
export const ModelYupMultiCountry = Yup.object().shape({ ...VALIDATIONS, country: Yup.object().required(REQUIRED) });

export const ModelYupPassword = Yup.object().shape({
  ...VALIDATIONS,
  password: Yup.string()
    .matches(regexPassword, PASSWORD_REGEX)
    .test('empty-or-10-characters-check', PASSWORD_MIN, (password) => !password || password.length >= 10),
});

export const ModelYupPasswordMultiCountry = Yup.object().shape({
  ...VALIDATIONS,
  country: Yup.object().required(REQUIRED),
  password: Yup.string()
    .matches(regexPassword, PASSWORD_REGEX)
    .test('empty-or-10-characters-check', PASSWORD_MIN, (password) => !password || password.length >= 10),
});

export const ModelErrors = [
  { errorKey: 'USERNAME_EXIST', message: i18next.t('users:error_user_exist') },
  { errno: 1062, message: i18next.t('users:error_user_exist') },
  { code: 412, message: i18next.t('users:pepe donde estas') },
];

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('toast:toast_update') },
];

export function sanitizeData(data) {
  return {
    avatar: data.avatar ? data.avatar : Model.avatar,
    country: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country,
    country_guid: data.country_guid,
    groups: setMetadataArraySelect(data.groups),
    groupsOld: setMetadataArraySelect(data.groups),
    guid: data.guid,
    is_demo: data.is_demo ? true : false,
    lastname: data.lastname ? data.lastname : Model.lastname,
    name: data.name ? data.name : Model.name,
    oraculo_id: data.oraculo_id ? data.oraculo_id : Model.oraculo_id,
    password: Model.password,
    password_repeat: Model.password_repeat,
    role: data.role_guid ? data.role_guid : Model.role,
    school: getSchool(data),
    schools: setMetadataArraySelect(data.schools),
    schoolsOld: setMetadataArraySelect(data.schools),
    subscription: data?.subscription,
    username: data.username ? data.username : Model.username,
  };
}

function getSchool(data) {
  const isPersonalSchool = data?.schools?.length ? data.schools[0].name?.includes(data.guid) : false;
  return isPersonalSchool ? undefined : setMetadataArraySelect(data.schools)[0];
}
