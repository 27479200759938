import Icon from '__core/modules/atoms/Icons/Icon';
import InputText from '__core/modules/atoms/InputText';
import DialogDefault from '__core/modules/components/dialogs/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import useDialogDelete from './useDialogDelete';

function DialogDelete({
  buttonTextSucess,
  hasConfirm,
  onClose,
  onSuccess,
  open,
  size,
  subtitle,
  subtitle2,
  textStringLangConfirmMessage,
  textStringLangConfirmText,
  title,
}) {
  const { textSubtitle, textTitle, value, onChangeInput, t } = useDialogDelete({ title, subtitle, open });

  return (
    <DialogDefault
      buttonTextCancel={t('Cancel')}
      buttonTextSucess={buttonTextSucess ? buttonTextSucess : t('Delete')}
      className="dialog-delete"
      disabledSuccess={hasConfirm ? value !== t(textStringLangConfirmText) : false}
      hideHeader
      onCancel={onClose}
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      size={size}
    >
      <div className="d-flex align-items-center mb-3">
        <Icon type="icon-warning-circle" fontSize="large" color="error" />
        <span className="dialog-exit-page__title ml-3">{textTitle}</span>
      </div>

      <p className="dialog-exit-page__description">{textSubtitle}</p>

      {hasConfirm && (
        <>
          <p className="dialog-exit-page__description">
            <Trans i18nKey={textStringLangConfirmMessage} values={{ name: t(textStringLangConfirmText) }} components={{ strong: <strong /> }} />
          </p>

          <div className="mt-4 mb-3">
            <InputText onChange={onChangeInput} placeholder={t(textStringLangConfirmText)} value={value} />
          </div>
        </>
      )}
    </DialogDefault>
  );
}

DialogDelete.propTypes = {
  buttonTextSucess: PropTypes.string,
  hasConfirm: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  subtitle: PropTypes.node,
  textStringLangConfirmMessage: PropTypes.string, // dialogs:message_confirm_delete
  textStringLangConfirmText: PropTypes.string, // dialogs:message_confirm_delete_text
  title: PropTypes.node,
};

DialogDelete.defaultProps = {
  buttonTextSucess: '',
  size: 's',
  subtitle: '',
  title: '',
};

export default DialogDelete;
