import { LANG_ID_LOCAL_STORAGE_KEY } from 'app/services/language.service';
import { actions } from 'app/store/ducks/i18n';
import store from 'app/store/store';

export function initializeLanguage(user) {
  const langNavigator = navigator?.language?.split('-')[0];
  const langId = localStorage.getItem(LANG_ID_LOCAL_STORAGE_KEY);
  const lang = user?.lang_id || langId || langNavigator || 'en';
  store.dispatch(actions.setLanguage(lang));
}
