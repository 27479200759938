import viewerApi from '../api/viewerApi';

export function createNewPlaylist(_this, e, _callback) {
  console.log('CREAR NUEVO MARCADOR');
  console.log(e);

  if (e.group_guid == '') {
    _this.setState({
      dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_NEW_NOT_GROUP_ASIGNED, type: 'info' },
      openToast: true,
    });
    return;
  }

  _this.setState({ visible_loading_full: true });

  var _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      show_at: String(e.show_at),
      group_guid: e.group_guid,
    },
    type: 'POST_CONTENT_IN_PLAYLIST',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_PLAYLIST_NEW_CREATED_OK, type: 'correct' },
        openToast: true,
      });
      if (_callback) {
        _callback(e.data.data);
      }
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_NEW_CREATED_ERROR, type: 'error' },
        openToast: true,
      });
      if (_callback) {
        _callback('');
      }
    }
  });
}

export function deletePlaylist(_this, _e, _callback) {
  this.setState({ visible_loading_full: true });

  var _data = { params: { guid: _e.guid, content_guid: _this.state.data.content_guid }, type: 'DELETE_CONTENT_OF_PLAYLIST' };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_PLAYLIST_DELETE_OK, type: 'correct' },
        openToast: true,
      });
      _callback(true);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_DELETE_ERROR, type: 'error' },
        openToast: true,
      });
      _callback(false);
    }
  });
}

export function updatePlaylist(_this, e, _callback) {
  console.log('ACTUALIZAR MARCADOR');
  console.log(e);

  _this.setState({ visible_loading_full: true });

  var _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      show_at: String(e.show_at),
      group_guid: e.group_guid,
      guid: e.guid,
    },
    type: 'PUT_CONTENT_IN_PLAYLIST',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_PLAYLIST_UPDATE_OK, type: 'correct' },
        openToast: true,
      });
      _callback(e.data.data.guid);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_UPDATE_ERROR, type: 'error' },
        openToast: true,
      });
      _callback('');
    }
  });
}

export function createNewPlaylistGroup(_this, e, _callback) {
  _this.setState({ visible_loading_full: true });

  var _data = {
    params: { title: e.title, thumbnail: e.thumbnail, description: e.description },
    type: 'POST_PLAYLIST_GROUP',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_PLAYLIST_GROUP_NEW_OK, type: 'correct' },
        openToast: true,
      });
      _callback(e.data.data.guid);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_GROUP_NEW_ERROR, type: 'error' },
        openToast: true,
      });
      _callback('');
    }
  });
}

export function deletePlaylistGroup(_this, _e, _callback) {
  _this.setState({ visible_loading_full: true });

  var _data = { params: { guid: _e.guid }, type: 'DELETE_PLAYLIST_GROUP' };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });

    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_PLAYLIST_GROUP_DELETE_OK, type: 'correct' },
        openToast: true,
      });
      _callback(true);
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_PLAYLIST_GROUP_DELETE_ERROR, type: 'error' },
        openToast: true,
      });
      _callback(false);
    }
  });
}
