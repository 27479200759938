import axios from 'axios';

export const CUSTOM_TAGS = 'cms/custom-tags';

export function getCustomTags(params) {
  return axios.get(CUSTOM_TAGS, { params: params });
}

export function createCustomTag(params) {
  return axios.post(CUSTOM_TAGS, params);
}

export function updateCustomTag(params) {
  return axios.put(`${CUSTOM_TAGS}/${params.guid}`, params);
}

export function deleteCustomTag(guid) {
  const params = { guid: [guid] };
  return axios.delete(`${CUSTOM_TAGS}/${guid}`, { data: params });
}
