import axios from 'axios';
import React, { Component } from 'react';
import './styles.scss';

class ScormNormalize extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listContents: [],
      listFileStorage: [],
      listFileUpdated: [],
      date_create: '',
      showLoading: false,
      textStatus: '',
      isEmpty: '',
      page: '',
      offset: '',
      pagesize: '',
      order: 'created_at desc',
    };
  }

  componentDidMount() {}

  onChangeDate = (e) => {
    let _value = e.currentTarget.value;
    let _element = e.currentTarget.getAttribute('id');
    this.setState({
      [_element]: _value,
    });
  };

  getContents = (_callback) => {
    let params = {
      offset: 0,
      page: 0,
      pageSize: 100000,
      author: 'editorial',
      type: ['CTTY_10'],
    };

    if (this.state.date_create) {
      params.createdAt = this.state.date_create;
    }

    const date_create = this.state.date_create;

    let _order = this.state.order;
    let _page = this.state.page;
    let _offset = this.state.offset;
    let _pagesize = this.state.pagesize;

    if (_order && _page && _offset && _pagesize) {
      params.offset = _offset;
      params.page = _page;
      params.pageSize = _pagesize;
      params.orderBy = _order;
    }

    axios
      .get(`cms/contents`, { params })
      .then((res) => {
        //_callback(res.data.data);

        let _arrayAllContents = res && res.data && res.data.data && res.data.data.contents ? res.data.data.contents : [];

        let listContentsNew = [];
        _arrayAllContents.forEach(function (content) {
          listContentsNew.push(content);
        });

        _callback(listContentsNew);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFileStorage = (_file, _callback) => {
    axios
      .get(_file)
      .then((res) => {
        _callback(res.data);
      })
      .catch((error) => {
        console.log(error);
        _callback('');
      });
  };

  onClickGetContents = (e) => {
    e.preventDefault();

    this.setState({
      isEmpty: '',
      showLoading: true,
      textStatus: 'GET Contents',
      listContents: [],
      listFileStorage: [],
      listFileUpdated: [],
    });

    this.getContents(
      function (listContentsNew) {
        this.setState({
          showLoading: false,
          textStatus: '',
          listContents: listContentsNew,
          isEmpty: listContentsNew.length === 0 ? 'empty' : '',
        });
      }.bind(this)
    );
  };

  onClickCheckNormalizedContents = (e) => {
    e.preventDefault();

    this.setState({
      isEmpty: '',
      showLoading: true,
      listContents: [],
      listFileStorage: [],
      listFileUpdated: [],
      textStatus: 'GET Contents',
    });

    this.getContents(
      function (listContentsNew) {
        if (listContentsNew.length === 0) {
          this.setState({
            showLoading: false,
            isEmpty: 'empty',
            textStatus: '',
          });
        } else {
          this.recursiveCheckContent(listContentsNew, 0, []);
        }
      }.bind(this)
    );
  };

  setTextStatus = (_text) => {
    this.setState({
      textStatus: _text,
    });
  };

  recursiveCheckContent = (_list, _position, _arrayRender) => {
    const domain = window.location.origin.replace('backoffice.', '');
    const token = JSON.parse(localStorage.getItem('persist:i2c_auth')).authToken.replace(/"/g, '');
    const rcf_file = domain + '/files-storage/open/' + _list[_position].guid + '/content/rcf/js/rcf.min.js?action=get-contents&token=' + token;
    const text_up_file = domain + '/files-storage/open/' + _list[_position].guid + '/fixedup.txt?action=get-contents&token=' + token;
    // const rcf_file = 'http://localhost:3005/files/scorm_2.0.0_Level_4-Unit_01_09.03.2023_scorm/content/rcf/js/rcf.min.js';
    // const text_up_file = 'http://localhost:3005/files/scorm_2.0.0_Level_4-Unit_01_09.03.2023_scorm/fixedup.txt';

    let _object = {
      guid: _list[_position].guid,
      name: _list[_position].name,
      created_at: _list[_position].created_at,
      author_name: _list[_position].author_name,
      author_lastname: _list[_position].author_lastname,
    };

    var scormCOrrectFormat = true;

    this.setTextStatus('Processing position ' + (_position + 1));

    if (_list[_position].url && _list[_position].url.indexOf('imsmanifest.xml') >= 0) {
      this.setTextStatus('Processing position ' + (_position + 1) + ' - GET files-storage - rcf');

      this.getFileStorage(
        rcf_file,
        function (file_js_data) {
          console.log();
          if (file_js_data.indexOf('var rcf_2450') >= 0) {
            _object.type = 'rcf_2450';
          } else if (file_js_data.indexOf('var rcf_2457') >= 0) {
            _object.type = 'rcf_2457';
          } else if (file_js_data.indexOf('var rcf_2458') >= 0) {
            _object.type = 'rcf_2458';
          } else if (file_js_data.indexOf('var rcf_2459') >= 0) {
            _object.type = 'rcf_2459';
          } else if (file_js_data.indexOf('var rcf_2461') >= 0) {
            _object.type = 'rcf_2461';
          } else if (file_js_data.indexOf('var rcf_2469') >= 0) {
            _object.type = 'rcf_2469';
          } else if (file_js_data.indexOf('var rcf_2470') >= 0) {
            _object.type = 'rcf_2470';
          } else if (file_js_data.indexOf('var rcf_2471') >= 0) {
            _object.type = 'rcf_2471';
          } else if (file_js_data.indexOf('var rcf_2472') >= 0) {
            _object.type = 'rcf_2472';
          } else if (file_js_data.indexOf('var rcf_2477') >= 0) {
            _object.type = 'rcf_2477';
          } else if (file_js_data.indexOf('var rcf_2479') >= 0) {
            _object.type = 'rcf_2479';
          } else if (file_js_data.indexOf('var rcf_2480') >= 0) {
            _object.type = 'rcf_2480';
          } else {
            scormCOrrectFormat = false;
            _object.type = 'NOT_FOUND (' + file_js_data.slice(0, 15) + ')';
          }

          if (!scormCOrrectFormat) {
            _object.txt = 'NO - possible format error';
            _arrayRender.push(_object);
            if (_list.length === _position + 1) {
              this.finishCheckContent(_arrayRender);
            } else {
              this.recursiveCheckContent(_list, _position + 1, _arrayRender);
            }
          } else {
            this.setTextStatus('Processing position ' + (_position + 1) + ' - GET files-storage - fixedup');
            this.getFileStorage(
              text_up_file,
              function (file_txt_data) {
                console.log(file_txt_data);
                if (file_txt_data && (file_txt_data === 'a' || file_txt_data === 1)) {
                  _object.txt = 'YES';
                } else {
                  _object.txt = 'NO';
                }

                _arrayRender.push(_object);

                if (_list.length === _position + 1) {
                  this.finishCheckContent(_arrayRender);
                } else {
                  this.recursiveCheckContent(_list, _position + 1, _arrayRender);
                }
              }.bind(this)
            );
          }
        }.bind(this)
      );
    } else {
      this.setTextStatus('Processing position ' + (_position + 1) + ' - Posible content error');
      _object.txt = 'NO - possible content error';
      _object.type = 'NOT_FOUND (imsmanifest.xml)';
      _object.errr = true;

      _arrayRender.push(_object);

      if (_list.length === _position + 1) {
        this.finishCheckContent(_arrayRender);
      } else {
        this.recursiveCheckContent(_list, _position + 1, _arrayRender);
      }
    }
  };

  finishCheckContent = (_arrayRender) => {
    this.setState({
      listFileStorage: _arrayRender,
      showLoading: false,
      textStatus: '',
    });
  };

  onClickNormalizeContents = (e) => {
    e.preventDefault();

    this.setState({
      isEmpty: '',
      showLoading: true,
      listContents: [],
      listFileStorage: [],
      listFileUpdated: [],
      textStatus: 'GET Contents',
    });

    this.getContents(
      function (listContentsNew) {
        if (listContentsNew.length === 0) {
          this.setState({
            showLoading: false,
            isEmpty: 'empty',
            textStatus: '',
          });
        } else {
          this.recursiveUpdateContent(listContentsNew, 0, []);
        }
      }.bind(this)
    );
  };

  recursiveUpdateContent = (_list, _position, _arrayRender) => {
    const domain = window.location.origin.replace('backoffice.', '');
    const token = JSON.parse(localStorage.getItem('persist:i2c_auth')).authToken.replace(/"/g, '');
    const api = domain + '/api/';
    const guid_content = _list[_position].guid;
    const rcf_file = domain + '/files-storage/open/' + guid_content + '/content/rcf/js/rcf.min.js?action=get-contents&token=' + token;
    const text_up_file = domain + '/files-storage/open/' + guid_content + '/fixedup.txt?action=get-contents&token=' + token;

    // const rcf_file = 'http://localhost:3005/files/scorm_2.0.0_Level_4-Unit_01_09.03.2023_scorm/content/rcf/js/rcf.min.js';
    // const text_up_file = 'http://localhost:3005/files/scorm_2.0.0_Level_4-Unit_01_09.03.2023_scorm/fixedup.txt';

    let _object = {
      guid: guid_content,
      name: _list[_position].name,
      created_at: _list[_position].created_at,
      author_name: _list[_position].author_name,
      author_lastname: _list[_position].author_lastname,
    };

    let files = null;
    var scormCOrrectFormat = true;

    this.setTextStatus('Processing position ' + (_position + 1));

    if (_list[_position].url && _list[_position].url.indexOf('imsmanifest.xml') >= 0) {
      this.setTextStatus('Processing position ' + (_position + 1) + ' - GET files-storage - rcf');

      this.getFileStorage(
        rcf_file,
        function (file_js_data) {
          if (file_js_data && file_js_data.indexOf('rcf_2450=function') >= 0) {
            _object.type = 'rcf_2450';
            files = document.getElementById('rcf_2450').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2457') >= 0) {
            _object.type = 'rcf_2457';
            files = document.getElementById('rcf_2457').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2458') >= 0) {
            _object.type = 'rcf_2458';
            files = document.getElementById('rcf_2458').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2459') >= 0) {
            _object.type = 'rcf_2459';
            files = document.getElementById('rcf_2459').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2461') >= 0) {
            _object.type = 'rcf_2461';
            files = document.getElementById('rcf_2461').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2469') >= 0) {
            _object.type = 'rcf_2469';
            files = document.getElementById('rcf_2469').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2470') >= 0) {
            _object.type = 'rcf_2470';
            files = document.getElementById('rcf_2470').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2471') >= 0) {
            _object.type = 'rcf_2471';
            files = document.getElementById('rcf_2471').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2472') >= 0) {
            _object.type = 'rcf_2472';
            files = document.getElementById('rcf_2472').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2477') >= 0) {
            _object.type = 'rcf_2477';
            files = document.getElementById('rcf_2477').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2479') >= 0) {
            _object.type = 'rcf_2479';
            files = document.getElementById('rcf_2479').files;
          } else if (file_js_data && file_js_data.indexOf('var rcf_2480') >= 0) {
            _object.type = 'rcf_2480';
            files = document.getElementById('rcf_2480').files;
          } else {
            scormCOrrectFormat = false;
            _object.type = 'NOT_FOUND (' + file_js_data.slice(0, 15) + ')';
          }

          const isLastItem = _list.length === _position + 1 ? true : false;

          if (!scormCOrrectFormat) {
            _object.txt = 'NO - possible format error';
            _arrayRender.push(_object);

            if (isLastItem) {
              this.finishUpdateContent(_arrayRender);
            } else {
              this.recursiveUpdateContent(_list, _position + 1, _arrayRender);
            }
          } else {
            this.setTextStatus('Processing position ' + (_position + 1) + ' - GET files-storage - fixedup');
            this.getFileStorage(
              text_up_file,
              function (file_txt_data) {
                if (file_txt_data && (file_txt_data === 'a' || file_txt_data === 1)) {
                  _object.txt = 'NO';

                  _arrayRender.push(_object);

                  if (isLastItem) {
                    this.finishUpdateContent(_arrayRender);
                  } else {
                    this.recursiveUpdateContent(_list, _position + 1, _arrayRender);
                  }
                } else {
                  const _url_get_tiket = api + 'cms/contents/' + guid_content + '/content/upload';
                  const __this = this;
                  _object.txt = 'YES';
                  _arrayRender.push(_object);

                  __this.setTextStatus('Processing position ' + (_position + 1) + ' - GET upload tiket');

                  axios
                    .get(_url_get_tiket)
                    .then((res) => {
                      console.log(res);

                      const upload_token = res.data.data.data.upload.token;
                      const upload_endpoint = res.data.data.data.upload.endpoint;

                      const xhr_file = new XMLHttpRequest();

                      const formData = new FormData();
                      formData.append('file', files[0]);
                      formData.append('token', upload_token);

                      xhr_file.open('POST', domain + upload_endpoint);

                      // xhr_file.setRequestHeader('authorization', _token_file);
                      // xhr_file.setRequestHeader('authorization', token);
                      // xhr_file.setRequestHeader('app-referer', domain);

                      console.log('Enviando archivo...');

                      xhr_file.send(formData);
                      __this.setTextStatus('Processing position ' + (_position + 1) + ' - Sending fixedup');

                      xhr_file.onreadystatechange = function (e) {
                        if (xhr_file.readyState == 4) {
                          if (xhr_file.status == 200) {
                            __this.setTextStatus('Processing position ' + (_position + 1) + ' - Sending ok');

                            var _r = JSON.parse(xhr_file.response);
                            console.log('Status = ' + _r.status);
                            _object.txt = 'YES';
                            if (isLastItem) {
                              __this.finishUpdateContent(_arrayRender);
                            } else {
                              __this.recursiveUpdateContent(_list, _position + 1, _arrayRender);
                            }
                          } else {
                            __this.setTextStatus('Processing position ' + (_position + 1) + ' - Sending error');

                            _object.txt = 'ERROR';
                            console.log('Status = Error :(');

                            if (isLastItem) {
                              __this.finishUpdateContent(_arrayRender);
                            } else {
                              __this.recursiveUpdateContent(_list, _position + 1, _arrayRender);
                            }
                          }
                        }
                      };
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }.bind(this)
            );
          }
        }.bind(this)
      );
    } else {
      this.setTextStatus('Processing position ' + (_position + 1) + ' - Posible content error');

      _object.txt = 'NO - possible content error';
      _object.type = 'NOT_FOUND (imsmanifest.xml)';
      _object.errr = true;

      _arrayRender.push(_object);

      if (_list.length === _position + 1) {
        this.finishUpdateContent(_arrayRender);
      } else {
        this.recursiveUpdateContent(_list, _position + 1, _arrayRender);
      }
    }
  };

  finishUpdateContent = (_arrayRender) => {
    this.setState({
      listFileUpdated: _arrayRender,
      showLoading: false,
      textStatus: '',
    });
  };

  render() {
    return (
      <div id="scormnormalize">
        <h3>Normalize Scorm from core cases RCF RUNTIMES - Updated 13/09/2023 - A</h3>
        <table>
          <tr>
            <td>rcf_2450</td>
            <td>
              <input type="file" id="rcf_2450" />
            </td>
          </tr>
          <tr>
            <td>rcf_2457</td>
            <td>
              <input type="file" id="rcf_2457" />
            </td>
          </tr>
          <tr>
            <td>rcf_2458</td>
            <td>
              <input type="file" id="rcf_2458" />
            </td>
          </tr>
          <tr>
            <td>rcf_2459</td>
            <td>
              <input type="file" id="rcf_2459" />
            </td>
          </tr>
          <tr>
            <td>rcf_2461</td>
            <td>
              <input type="file" id="rcf_2461" />
            </td>
          </tr>
          <tr>
            <td>rcf_2469</td>
            <td>
              <input type="file" id="rcf_2469" />
            </td>
          </tr>
          <tr>
            <td>rcf_2470</td>
            <td>
              <input type="file" id="rcf_2470" />
            </td>
          </tr>
          <tr>
            <td>rcf_2471</td>
            <td>
              <input type="file" id="rcf_2471" />
            </td>
          </tr>
          <tr>
            <td>rcf_2472</td>
            <td>
              <input type="file" id="rcf_2472" />
            </td>
          </tr>
          <tr>
            <td>rcf_2477</td>
            <td>
              <input type="file" id="rcf_2477" />
            </td>
          </tr>
          <tr>
            <td>rcf_2479</td>
            <td>
              <input type="file" id="rcf_2479" />
            </td>
          </tr>
          <tr>
            <td>rcf_2480</td>
            <td>
              <input type="file" id="rcf_2480" />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <h4>Filters</h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              Date create
              {`    `}
              {/* <input type="date" id="date_from" value={this.state.date_from} onChange={this.onChangeDate} /> */}
              <input type="date" id="date_create" value={this.state.date_create} onChange={this.onChangeDate} />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              Pagination:
              {`    `}
              Offset:
              <input type="text" id="offset" value={this.state.offset} onChange={this.onChangeDate} />
              {`    `}
              Page:
              <input type="text" id="page" value={this.state.page} onChange={this.onChangeDate} />
              {`    `}
              Page Size:
              <input type="text" id="pagesize" value={this.state.pagesize} onChange={this.onChangeDate} />
              {`    `}
              Order:
              <input type="text" id="order" value={this.state.order} onChange={this.onChangeDate} />
            </td>
          </tr>
          {/* <tr>
            <td>Date to</td>
            <td>
              <input type="date" id="date_to" value={this.state.date_to} onChange={this.onChangeDate} />
            </td>
          </tr> */}
          <tr>
            <td colSpan={2}>
              <input type="button" id="get_contents" value={'Get Contents'} onClick={this.onClickGetContents} />
              <input type="button" id="check_normalized_contents" value={'Check Is Normalized Contents'} onClick={this.onClickCheckNormalizedContents} />
              <input type="button" id="normalize_contents" value={'Start Normalize Contents'} onClick={this.onClickNormalizeContents} />
            </td>
          </tr>
        </table>

        {this.state.textStatus && <div className="textStatus">{this.state.textStatus}</div>}
        {this.state.showLoading && <span className="loader"></span>}
        {this.state.isEmpty && <div className="isEmpty">No Scorm Content created on this date</div>}

        {this.state.listContents.length > 0 && (
          <table className="tableGetContents" border="1">
            <tr>
              <th>I</th>
              <th>GUID</th>
              <th>created_at</th>
              <th>author_name</th>
              <th>Name</th>
            </tr>
            {this.state.listContents.map((l, index) => {
              return (
                <tr key={l.guid}>
                  <td>{index}</td>
                  <td>{l.guid}</td>
                  <td>{l.created_at.split('T')[0]}</td>
                  <td>{`${l.author_name} ${l.author_lastname}`}</td>
                  <td>{l.name}</td>
                </tr>
              );
            })}
          </table>
        )}

        {this.state.listFileStorage.length > 0 && (
          <table className="tableGetContents" border="1">
            <tr>
              <th>I</th>
              <th>GUID</th>
              <th>created_at</th>
              <th>author_name</th>
              <th>Name</th>
              <th>RCF type</th>
              <th>Is Updated</th>
            </tr>
            {this.state.listFileStorage.map((l, index) => {
              return (
                <tr key={l.guid} className={l.errr ? 'scormrow_err' : ''}>
                  <td>{index}</td>
                  <td>{l.guid}</td>
                  <td>{l.created_at.split('T')[0]}</td>
                  <td>{`${l.author_name} ${l.author_lastname}`}</td>
                  <td>{l.name}</td>
                  <td>{l.type}</td>
                  <td>{l.txt}</td>
                </tr>
              );
            })}
          </table>
        )}

        {this.state.listFileUpdated.length > 0 && (
          <table className="tableGetContents" border="1">
            <tr>
              <th>I</th>
              <th>GUID</th>
              <th>created_at</th>
              <th>author_name</th>
              <th>Name</th>
              <th>RCF type</th>
              <th>Must update?</th>
            </tr>
            {this.state.listFileUpdated.map((l, index) => {
              return (
                <tr key={l.guid} className={l.errr ? 'scormrow_err' : ''}>
                  <td>{index}</td>
                  <td>{l.guid}</td>
                  <td>{l.created_at.split('T')[0]}</td>
                  <td>{`${l.author_name} ${l.author_lastname}`}</td>
                  <td>{l.name}</td>
                  <td>{l.type}</td>
                  <td>{l.txt}</td>
                </tr>
              );
            })}
          </table>
        )}
      </div>
    );
  }
}

export default ScormNormalize;
