import React from 'react';

const ContentCreatePage = React.lazy(() => import('../pages/contents/ContentCreatePage/ContentCreatePage'));
const ContentDetailPage = React.lazy(() => import('../pages/contents/ContentDetailPage/ContentDetailPage'));
const ContentsPage = React.lazy(() => import('../pages/contents/ContentsPage/ContentsPage'));
const ContentBatchUploadPage = React.lazy(() => import('../pages/contents/ContentBatchUpload/ContentBatchUploadPage'));

export const PATH_CONTENT = '/content/:guid';
export const PATH_CONTENTS = '/contents';
export const PATH_CONTENT_CREATE = '/contents/create';
export const PATH_CONTENT_BATCH_UPLOAD = '/contents/batch-upload';

const PAGE_CONTENT = {
  component: ContentDetailPage,
  path: PATH_CONTENT,
};

const PAGE_CONTENTS = {
  component: ContentsPage,
  path: PATH_CONTENTS,
};

const PAGE_CONTENT_CREATE = {
  component: ContentCreatePage,
  path: PATH_CONTENT_CREATE,
};

const PAGE_CONTENT_BATCH_UPLOAD = {
  component: ContentBatchUploadPage,
  path: PATH_CONTENT_BATCH_UPLOAD,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_CONTENT_CREATE, PAGE_CONTENT, PAGE_CONTENT_BATCH_UPLOAD, PAGE_CONTENTS];
