import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../atoms/Button';

const FooterButtons = ({ buttonTextCancel, buttonTextSucess, disabledCancel, disabledSuccess, loadingCancel, onCancel, onSuccess }) => {
  return (
    <div className="footer-buttons">
      {onCancel && (
        <Button
          color="transparent"
          disabled={disabledCancel}
          loading={loadingCancel}
          onClick={onCancel}
          size="small"
          text={buttonTextCancel}
          type="reset"
          variant="inline"
        />
      )}

      {onSuccess && <Button type="submit" variant="inline" onClick={onSuccess} text={buttonTextSucess} disabled={disabledSuccess} size="small" />}
    </div>
  );
};

FooterButtons.propTypes = {
  buttonTextCancel: PropTypes.string,
  buttonTextSucess: PropTypes.string,
  disabledCancel: PropTypes.bool,
  disabledSuccess: PropTypes.bool,
  loadingCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

FooterButtons.defaultProps = {
  buttonTextCancel: 'Cancel',
  buttonTextSucess: 'Confirm',
  onSuccess: function () {},
  disabledSuccess: false,
};

export default FooterButtons;
