import Tooltip from '@material-ui/core/Tooltip';
import Icon from '__core/modules/atoms/Icons';
import Dropdown from '__core/modules/components/dropdowns/components/Dropdown';
import ListSimpleDropdown from '__core/modules/components/dropdowns/components/ListSimpleDropdown';
import SelectLanguage from 'app/components/selectors/SelectLanguage/SelectLanguage';
import { timestampPrventCache } from 'app/utils/date';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

function BarMenu({ options, optionsDropdown, optionsDropdownSetting, activeItem, iconTypeSettings, iconColorSettings, hasLogo, userLogin }) {
  const avatar = userLogin?.avatar ? userLogin.avatar : '/images/svg/avatar.svg';
  const [anchorEl, setAnchorEl] = React.useState({});
  const [anchorElSetting, setAnchorElSetting] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);

  function onOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  function onOpenSetting(event) {
    setAnchorElSetting(event.currentTarget);
    setOpenSetting(true);
  }

  function onCloseSetting() {
    setOpenSetting(false);
  }

  function renderItem(item) {
    return (
      <div className={`bar-menu__item ${activeItem === item.key ? 'bar-menu__item-selected' : ''}`}>
        <div className="bar-menu__item-icon" onClick={item.onClick}>
          <Tooltip title={item.title} placement="right">
            <>
              <Icon type={item.icon} fontSize="medium" htmlColor="#fff" />
            </>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div className="bar-menu">
      <div className="bar-menu__content">
        <div className="bar-menu__logo">
          {hasLogo && <Icon type="icon-logo" htmlColor="#fff" fontSize="large" />}
          <div className="bar-menu__sections">
            {options.map((item) => (
              <div key={item.key}>
                {item.to && <Link to={item.to}>{renderItem(item)}</Link>}
                {!item.to && renderItem(item)}
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex flex-column align-items-center">
          <div className="mb-2">
            <SelectLanguage />
          </div>

          {optionsDropdownSetting.length > 0 && (
            <>
              <div className="bar-menu__setting mb-3" onClick={onOpenSetting}>
                <Icon type={iconTypeSettings} htmlColor={iconColorSettings} size="big" />
              </div>

              <Dropdown anchorEl={anchorElSetting} open={openSetting} handleClose={onCloseSetting}>
                <ListSimpleDropdown items={optionsDropdownSetting} />
              </Dropdown>
            </>
          )}

          {optionsDropdown.length > 0 && (
            <>
              <button onClick={onOpen}>
                <img src={`${avatar}${timestampPrventCache()}`} className="bar-menu__avatar" />
              </button>

              <Dropdown anchorEl={anchorEl} open={open} handleClose={onClose}>
                <ListSimpleDropdown items={optionsDropdown} />
              </Dropdown>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

BarMenu.propTypes = {
  hasLogo: PropTypes.bool,
  options: PropTypes.array,
  optionsDropdown: PropTypes.array,
  optionsDropdownSetting: PropTypes.array,
  iconTypeSettings: PropTypes.string,
  iconColorSettings: PropTypes.string,
  userLogin: PropTypes.object,
};

BarMenu.defaultProps = {
  hasLogo: true,
  options: [],
  optionsDropdown: [],
  optionsDropdownSetting: [],
  iconTypeSettings: 'icon-setting',
  iconColorSettings: '#fff',
};

export default BarMenu;
