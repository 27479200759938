import LoadingSmple from '__core/modules/components/LoadingSimple';
import { defaultConfig } from 'app/config/environment';
import * as authStore from 'app/store/ducks/auth.duck';
import store from 'app/store/store';
import { isInPublisher } from 'app/utils/utils';
import React from 'react';
import { connect } from 'react-redux';

const LogoutPage = ({ hasAuthToken, logout }) => {
  const [redirectTo, setRedirectTo] = React.useState('');
  let baseUrl = defaultConfig.BASE_URL_;
  var lastChar = baseUrl.substr(-1); // Selects the last character

  if (lastChar !== '/') {
    baseUrl = `${baseUrl}/`;
  }

  React.useEffect(() => {
    let auth = store.getState().auth;
    setRedirectTo(isInPublisher(defaultConfig) ? '/' : auth.user && auth.user.referrer ? auth.user.referrer : '/');

    if (document.cookie.indexOf('PHPSESSID') !== -1) {
      document.cookie = 'PHPSESSID=10373160-ca49-11ee-a2ad-494f733b286f; Path=/;';
    }

    logout();
  }, []);

  React.useEffect(() => {
    window.location.href = redirectTo;
  }, [hasAuthToken]);

  return <LoadingSmple title={'Cerrando sesión'} />;
};

export default connect(({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }), authStore.actions)(LogoutPage);
