import Button from '__core/modules/atoms/Button';
import DialogConfirm from 'app/components/dialog/DialogConfirm/DialogConfirm';
import DialogDelete from 'app/components/dialog/DialogDelete/DialogDelete';
import DialogExitPage from 'app/components/dialog/DialogExitPage/DialogExitPage';
import DialogInfo from 'app/components/dialog/DialogInfo/DialogInfo';
import React from 'react';

function UIDialog() {
  const [open, setOpen] = React.useState({ DialogDelete: false, DialogDeleteConfirm: false, DialogInfo: false, DialogExitPage: false });

  function onClick(state) {
    setOpen((prevOpen) => {
      return { ...prevOpen, [state]: true };
    });
  }

  function onClose(state) {
    setOpen((prevOpen) => {
      return { ...prevOpen, [state]: false };
    });
  }

  return (
    <div>
      <p>Dialog</p>

      <div>
        <Button onClick={() => onClick('DialogDelete')} text="DialogDelete →" variant="inline" className="mr-3" color="error" />
        <Button onClick={() => onClick('DialogDeleteConfirm')} text="DialogDeleteConfirm →" variant="inline" className="mr-3" color="error" />
        <Button onClick={() => onClick('DialogInfo')} text="DialogInfo →" variant="inline" className="mr-3" color="info" />
        <Button onClick={() => onClick('DialogConfirm')} text="DialogConfirm →" variant="inline" className="mr-3" color="success" />
        <Button
          onClick={() => onClick('DialogExitPage')}
          text="DialogExitPage → Pulsa el boton y pincha para salir de esta sección"
          variant="inline"
          className="mr-3"
          color="warning"
        />
      </div>

      <DialogDelete
        name="nombre del contenido"
        onClose={() => onClose('DialogDelete')}
        onSuccess={() => onClose('DialogDelete')}
        open={open.DialogDelete}
        subtitle="¿Estás seguro de que quieres borrar este contenido?"
        title="Borrar contenido"
      />

      <DialogDelete
        hasConfirm
        onClose={() => onClose('DialogDeleteConfirm')}
        onSuccess={() => onClose('DialogDeleteConfirm')}
        open={open.DialogDeleteConfirm}
        subtitle="¿Estás seguro de que quieres borrar este contenido?"
        textStringLangConfirmMessage="digitalPrograms:import_in_design_mint_warning_confirm"
        textStringLangConfirmText="digitalPrograms:import_in_design_mint_warning_confirm_text"
        title="Borrar contenido"
      />

      <DialogInfo
        open={open.DialogInfo}
        onClose={() => onClose('DialogInfo')}
        onSuccess={() => onClose('DialogInfo')}
        title="Año escolar Creado"
        description="Por colegio solo puede existir un año escolar activo, al activarlo, deshabilitarás el año escolar que hay actualmente añadido y las clases del curso escolar anterior permanecerán accesibles por el usuario pero archivadas."
        descriptionSecond="Descripción 2"
      />

      <DialogConfirm name={'Antonio pepe'} onClose={() => onClose('DialogConfirm')} onSuccess={() => onClose('DialogConfirm')} open={open.DialogConfirm} />
      <DialogExitPage when={open.DialogExitPage} />
    </div>
  );
}

export default UIDialog;
