import { createTheme } from '@material-ui/core/styles';

export const ThemeBackOffice = createTheme({
  palette: {
    error: { main: '#f66868' },
    primary: { main: '#2544ca' },
    secondary: { main: '#414B5C' },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 8,
        border: '1px solid #DFE5EC',
        boxShadow: 'none',
      },
    },
    MuiCardContent: {
      root: {
        padding: 16,
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 8,
        backgroundColor: '#414B5C',
        color: '#FFF',
        fontSize: 12,
        fontWeight: 600,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: '#8c99ad',
        fontSize: 12,
        lineHeight: 23,
      },
      label: {
        fontSize: 14,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9000 !important',
      },
    },
    MuiBadge: {
      badge: {
        minWidth: 28,
        height: 28,
        borderRadius: '50%',
      },
    },
  },
});
