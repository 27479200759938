export const orderWithAccents = (list, field) => {
  return list?.sort((a, b) => a[field].localeCompare(b[field]));
};

export function reorderDown(list, element) {
  const array = list.map((item, key) => ({ guid: item.guid, order: key + 1 }));
  const arrayGuid = list.map((item) => item.guid);
  const indexOf = arrayGuid.indexOf(element.guid);

  return array.map((item, key) => {
    const equal = key === indexOf ? { ...item, order: item.order + 1 } : undefined;
    const other = key === indexOf + 1 ? { ...item, order: item.order - 1 } : item;

    return equal ? equal : other;
  });
}

export function reorderUp(list, element) {
  const array = list.map((item, key) => ({ guid: item.guid, order: key + 1 }));
  const arrayGuid = list.map((item) => item.guid);
  const indexOf = arrayGuid.indexOf(element.guid);

  return array.map((item, key) => {
    const equal = key === indexOf ? { ...item, order: item.order - 1 } : undefined;
    const other = key === indexOf - 1 ? { ...item, order: item.order + 1 } : item;

    return equal ? equal : other;
  });
}
