import axios from 'axios';

export const SYSTEM_SETTINGS = 'system-settings';

export function getSetting(params) {
  return axios.get(SYSTEM_SETTINGS, { params });
}

export function updateSetting(params) {
  return axios.put(SYSTEM_SETTINGS, params);
}
