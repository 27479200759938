import i18next from 'app/translations/i18n';

export function parseLanguages(languages = [], isFilter) {
  if (isFilter) {
    languages = languages.filter((language) => language.is_for_ui === 1);
  }

  return languages.map((language) => {
    return {
      ...language,
      description: i18next.t(`languages:${language.description}`),
    };
  });
}
