import React from 'react';

const SchoolsPage = React.lazy(() => import('../pages/schools/SchoolsPage/SchoolsPage'));
const SchoolCreatePage = React.lazy(() => import('../pages/schools/SchoolCreatePage/SchoolCreatePage'));
const SchoolDetailPage = React.lazy(() => import('../pages/schools/SchoolDetailPage/SchoolDetailPage'));

export const PATH_SCHOOL = '/school/:guid';
export const PATH_SCHOOLS = '/schools';
export const PATH_SCHOOL_CREATE = '/school/create';

const PAGE_SCHOOL = {
  component: SchoolDetailPage,
  path: PATH_SCHOOL,
};

const PAGE_SCHOOLS = {
  component: SchoolsPage,
  path: PATH_SCHOOLS,
};

const PAGE_SCHOOL_CREATE = {
  component: SchoolCreatePage,
  path: PATH_SCHOOL_CREATE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_SCHOOL_CREATE, PAGE_SCHOOL, PAGE_SCHOOLS];
