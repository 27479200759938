import { setMetadataArraySelect, setMetadataSelect } from 'app/services/metadata.service';
import i18next from 'app/translations/i18n';
import { MAX, MIN, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'collection';

export const Model = {
  collection: '',
  country: '',
  educationLevels: [],
};

export const ModelYup = Yup.object().shape({
  collection: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
});

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('toast:toast_update') },
];

export function sanitizeData(data) {
  return {
    educationLevels: setMetadataArraySelect(data.educationLevels, 'level'),
    guid: data.guid,
    collection: data.collection ? data.collection : Model.collection,
    country: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country,
  };
}
