import viewerApi from '../api/viewerApi';

export function getAttemptScormInternal(_this, ee, _callback) {
  var _data_send = {};
  _data_send.contentGuid = Array(_this.state.data.content_guid);
  _data_send.referenceId = Array(ee);
  _data_send.scope = Array('viewer');
  var __data = { params: _data_send, type: 'GET_EXERCISE_TRACE' };
  viewerApi(__data, function (e) {
    try {
      if (e.data.length == 0) {
        _callback(null);
        return;
      } else {
        var _r = JSON.parse(e.data[e.data.length - 1].data);
        _callback(_r);
        return;
      }
    } catch (___er) {
      console.log(___er);
      _callback(null);
      return;
    }
  });
}

export function setAttemptScormInternal(_this, _data) {
  // Convert time to seconts
  var seconds = 0;
  try {
    seconds = JSON.parse(_data.data).core.session_time;
    seconds = new Date('1970-01-01T' + seconds + 'Z').getTime() / 1000;
  } catch (rer) {}
  _data.session_time = seconds;

  // Preparando status
  _data.completion_status = _data.completion_status == '' ? 'unknown' : _data.completion_status;
  // Agregando GUID de CONTENT
  _data.content_guid = String(_this.state.data.content_guid);

  var __data = { params: _data, type: 'POST_EXERCISE_TRACE' };
  viewerApi(__data, function (e) {
    console.log(e);
  });
}
