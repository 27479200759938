import { getTemplates } from 'app/crud/templates.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_TEMPLATES, REDUCER_TEMPLATES } from '../conf';

export const actionTypes = {
  GetTemplates: 'cms/GET_TEMPLATES',
  SetTemplates: 'cms/SET_TEMPLATES',
};

const initialState = {
  templates: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_TEMPLATES, whitelist: ['templates'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetTemplates:
      return { ...state, loading: true };

    case actionTypes.SetTemplates: {
      const { payload } = action;

      return payload ? { templates: payload, loading: false } : { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getTemplates: (state) => {
    return state.entities && state.entities[REDUCER_TEMPLATES] ? state.entities[REDUCER_TEMPLATES].templates : null;
  },
};

export const actions = {
  getTemplates: () => ({ type: actionTypes.GetTemplates }),
  setTemplates: (response) => ({ type: actionTypes.SetTemplates, payload: { response } }),
};

function* fetch() {
  const { data } = yield getTemplates();

  if (data && data.status === 'success' && data.data) {
    yield put({ type: actionTypes.SetTemplates, payload: data.data });
  }
}

function* getIfNeeded() {
  const templates = yield select(selectors.getTemplates);

  if (!templates || templates.length === 0) {
    yield fetch();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetTemplates, getIfNeeded);
}
