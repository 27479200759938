export function getMetadata(data, atrribute) {
  return data[atrribute].value;
}

export function getMetadataArray(data, atrribute) {
  const metadata = data[atrribute].map((item) => item.value);
  return metadata.length ? metadata : [];
}

export function setMetadataArraySelect(data, label = 'name', guid = 'guid') {
  return data.map((item) => ({
    ...item,
    value: item[guid],
    label: item[label],
    guid: item[guid],
    name: item[label],
  }));
}

export function setMetadataArraySelectSimple(data, label = 'name', guid = 'guid') {
  return data.map((item) => ({
    label: item[label],
    value: item[guid],
  }));
}

export function setMetadataSelect(data, label = 'name', guid = 'guid') {
  return { label: data[label], value: data[guid] };
}

export function getMetadataMultiCountry(data) {
  return Array.isArray(data) ? data.map((item) => item.value) : data.value ? [data.value] : [];
}
