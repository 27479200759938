export const ES = {
  VIEWER_LANG_TYPE: 'es',
  VISOR_DATA_ACTION_NOT_POSSIBLE: 'Actualmente, no es posible realizar esta acción.',
  VISOR_FILE_HAVE_ERROR: 'Error de contenido.',
  VISOR_FILE_NOT_FOUND: 'Error, contenido no encontrado.',
  VISOR_FILE_TYPE_UNDEFINED: 'Error, tipo de contenido no identificado.',
  VISOR_ERROR_404: 'Error 404. Content not found.',
  VISOR_ERROR_403: 'Error 403. Access forbiden.',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS: '¿Quieres eliminar todas las anotaciones creadas?',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_YES: 'SI',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_NOT: 'NO',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_OK: 'Notas eliminadas correctamente.',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_NOOK: 'No se pueden eliminar las anotaciones.',
  VISOR_LIB_MENU_ADD_PLAYLIST: 'Añadir lista',
  VISOR_LIB_MENU_MY_PLAYLIST: 'Mi lista',
  VISOR_LIB_MENU_EVALUATE_CONTENT: 'Evaluar contenido',
  VISOR_LIB_MENU_RE_EVALUATE_CONTENT: 'Recalificar',
  VISOR_LIB_MENU_REPORT_CONTENT: 'Reportar contenido',
  VISOR_LIB_COMMENTS_CANCELAR: 'CANCELAR',
  VISOR_LIB_PLAYLIST_GROUP_TITLE: 'Lista de favoritos',
  VISOR_LIB_PLAYLIST_GROUP_PLACEHOLDER: 'Lista de nombres',
  VISOR_LIB_PLAYLIST_GROUP_EMPTY: 'Ningún grupo de lista',
  VISOR_LIB_PLAYLIST_GROUP_SAVE_NEW: 'Crear',
  VISOR_LIB_PLAYLIST_GROUP_CREATE_NEW: '+ Crear nueva lista',
  VISOR_LIB_PLAYLIST_SAVE_NEW: 'Crear',
  VISOR_LIB_PLAYLIST_INSTANT: 'Empieza a',
  VISOR_LIB_PLAYLIST_IN: 'Lista en',
  VISOR_LIB_PLAYLIST_DELETE: 'Borrar',
  VISOR_LIB_PLAYLIST_CREATED: 'Listas creadas',
  VISOR_LIB_PLAYLIST_EMPTY: 'Aún no se han creado listas',
  VISOR_LIB_ERRORS_DESCRIPTION: 'Describe el problema (opcional)',
  VISOR_LIB_ERRORS_ERROR_CONTENT: 'Error de contenido',
  VISOR_LIB_ERRORS_INAPROPIATE: 'Contenido inapropiado',
  VISOR_LIB_ERRORS_RAZON: '¿Qué salió mal?',
  VISOR_LIB_ERRORS_REPORT_PROBLEM: 'Reportar un problema',
  VISOR_LIB_EVALUATE_DIFFICULT: '¿Cuál es su nivel de dificultad durante sus estudios?',
  VISOR_LIB_EVALUATE_UTIL: '¿Qué tan satisfecho estás con el contenido?',
  VISOR_LIB_EVALUATE_TITLE: 'Evalúa el contenido que has estudiado',
  VISOR_LIB_EVALUATE_SAVE: 'Enviar',
  VISOR_LIB_PDFTRON_TITLE_SHARE: 'Compartir en',
  VISOR_LIB_PDFTRON_SHARE_GCLASSROOM: 'Google Classroom',
  VISOR_LIB_PDFTRON_SHARE_MSTEAMS: 'Microsoft Teams',
  VISOR_LIB_PDFTRON_SHARE_CANCEL: 'Cancelar',
  VISOR_LIB_PDFTRON_TOOL_DRAWING: 'Dibujo',
  VISOR_LIB_PDFTRON_DELETE_ALL_ANNOTS: 'Eliminar todas las anotaciones',
  VISOR_LIB_PDFTRON_ERROR_FILE: 'Error en el Contenido, por favor intente nuevamente en un momento.',
};
