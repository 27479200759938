import { getLangs, getLangScopes } from 'app/crud/langs.crud';
import { parseLanguages } from 'app/utils/lang';
import { compareField } from 'app/utils/utils';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_LANGS, REDUCER_LANGS } from '../conf';

export const actionTypes = {
  GetLangs: 'cms/GET_LANGS',
  SetLangs: 'cms/SET_LANGS',
  GetLangScopes: 'cms/GET_LANG_SCOPES',
  SetLangScopes: 'cms/SET_LANG_SCOPES',
};

const initialState = {
  langs: [],
  scopes: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_LANGS, whitelist: ['langs'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLangs:
      return { ...state, loading: true };

    case actionTypes.SetLangs: {
      const { response, isFilter } = action.payload;
      let languages = parseLanguages(response.data, isFilter);
      languages.sort((a, b) => {
        return compareField(a, b, 'description');
      });
      if (response && response.status === 'success' && response.data) {
        return { langs: languages, loading: false };
      }
      return { ...state, loading: false };
    }

    case actionTypes.SetLangScopes: {
      const { data } = action.payload;
      return { ...state, scopes: data };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLangs: (state) => {
    return state.entities && state.entities[REDUCER_LANGS] ? state.entities[REDUCER_LANGS].langs : null;
  },
  getLangScopes: (state) => {
    return state.entities && state.entities[REDUCER_LANGS] ? state.entities[REDUCER_LANGS].scopes : null;
  },
};

export const actions = {
  getLangs: (params) => ({ type: actionTypes.GetLangs, payload: { ...params } }),
  fulfillLangs: (response, isFilter) => ({ type: actionTypes.SetLangs, payload: { response, isFilter } }),
  getLangScopes: (params) => ({ type: actionTypes.GetLangScopes, payload: params }),
  fulfillLangScopes: (response) => ({ type: actionTypes.SetLangScopes, payload: response }),
};

function* fetchLangs() {
  const { data } = yield getLangs();
  yield put(actions.fulfillLangs(data));
}

function* fetchLangScopes() {
  const { data } = yield getLangScopes();
  yield put(actions.fulfillLangScopes(data));
}

function* getLangsIfNeeded({ payload }) {
  const langs = yield select(selectors.getLangs);
  if (!langs || langs.length === 0 || payload?.isRefresh) {
    yield fetchLangs();
  }
}

function* getLangScopesIfNeeded() {
  const scopes = yield select(selectors.getLangScopes);
  if (!scopes || scopes.length === 0) {
    yield fetchLangScopes();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetLangs, getLangsIfNeeded);
  yield takeLatest(actionTypes.GetLangScopes, getLangScopesIfNeeded);
}
