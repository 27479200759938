import React from 'react';

const LicenseCreatePage = React.lazy(() => import('../pages/licenses/LicenseCreatePage/LicenseCreatePage'));
const LicensesPage = React.lazy(() => import('../pages/licenses/LicensesPage/LicensesPage'));
const LicenseDetailPage = React.lazy(() => import('../pages/licenses/LicenseDetailPage/LicenseDetailPage'));

export const PATH_LICENSE = '/license/:guid';
export const PATH_LICENSES = '/licenses';
export const PATH_LICENSE_CREATE = '/license/create';

const PAGE_LICENSE = {
  component: LicenseDetailPage,
  path: PATH_LICENSE,
};

const PAGE_LICENSES = {
  component: LicensesPage,
  path: PATH_LICENSES,
};

const PAGE_LICENSE_CREATE = {
  component: LicenseCreatePage,
  path: PATH_LICENSE_CREATE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_LICENSE_CREATE, PAGE_LICENSE, PAGE_LICENSES];
