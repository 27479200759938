import axios from 'axios';

export const GET_LANGS = 'langs';
export const LANGS_SCOPES = 'langs/scopes';

export function getLangs() {
  return axios.get(GET_LANGS);
}

export function createLang(params) {
  return axios.post(GET_LANGS, params);
}

export function updateLang(guid, params) {
  return axios.patch(`${GET_LANGS}/${guid}`, params);
}

// ---------------------------------------------
// SCOPES
// ---------------------------------------------

export function getLangScopes() {
  return axios.get(LANGS_SCOPES);
}

export function createLangScope(params) {
  return axios.post(LANGS_SCOPES, params);
}

export function updateLangScope(guid, params) {
  return axios.put(`${LANGS_SCOPES}/${guid}`, params);
}
