import { addLatex } from 'app/config/configLatex';
import { initializeFonts } from 'app/config/initializeFonts';
import { actions as locationActions } from 'app/store/ducks/locations.duck';
import { actions as questionActions } from 'app/store/ducks/questions.duck';
import { actions as systemSettingActions } from 'app/store/ducks/systemSettings.duck';
import { actions as userActions } from 'app/store/ducks/users.duck';
import { customTags, educationLevels, langs, learningObjectives, programTypography, templates } from 'app/store/index';
import store from 'app/store/store';

export function initializeLogin() {
  store.dispatch(systemSettingActions.getSetting());
  store.dispatch(systemSettingActions.getOrganization());

  store.dispatch(customTags.actions.getCustomTags());
  store.dispatch(langs.actions.getLangs({ isFilter: false }));
  store.dispatch(learningObjectives.actions.getLearningObjectives());
  store.dispatch(locationActions.getLocations());
  store.dispatch(programTypography.actions.getTypography());
  store.dispatch(questionActions.getQuestionTypes());
  store.dispatch(templates.actions.getTemplates());
  store.dispatch(userActions.getRoles());

  initializeLatex();
  initializeFonts();
}

export function initializeAfterSetting(setting) {
  const { is_multicountry } = setting;
  const paramsEducationLevels = is_multicountry ? { all: true } : {};

  store.dispatch(educationLevels.actions.getEducationLevels(paramsEducationLevels));
}

function initializeLatex() {
  addLatex('katex');
}
