import React from 'react';

const LicenseBatchCreatePage = React.lazy(() => import('../pages/licensesBatch/LicenseBatchCreatePage/LicenseBatchCreatePage'));
const LicenseBatchDetailPage = React.lazy(() => import('../pages/licensesBatch/LicenseBatchDetailPage/LicenseBatchDetailPage'));
const LicensesBatchPage = React.lazy(() => import('../pages/licensesBatch/LicensesBatchPage/LicensesBatchPage'));

export const PATH_LICENSE_BATCH = '/license-batch/:guid';
export const PATH_LICENSE_BATCHS = '/licenses-batch';
export const PATH_LICENSE_BATCH_CREATE = '/license-batch/create';

const PAGE_LICENSE_BATCH = {
  component: LicenseBatchDetailPage,
  path: PATH_LICENSE_BATCH,
};

const PAGE_LICENSE_BATCHS = {
  component: LicensesBatchPage,
  path: PATH_LICENSE_BATCHS,
};

const PAGE_LICENSE_BATCH_CREATE = {
  component: LicenseBatchCreatePage,
  path: PATH_LICENSE_BATCH_CREATE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_LICENSE_BATCH_CREATE, PAGE_LICENSE_BATCH, PAGE_LICENSE_BATCHS];
