import BarMenu from 'app/components/menu/BarMenu';
import PropTypes from 'prop-types';
import React from 'react';
import ListWrappersMenu from './WrapperListMenu';
import useListWrappers from './useListWrappers';

function ListWrappers({ children, menu }) {
  const { OPTIONS_MENU, activeItem, authUser, optionsList, optionsListSetting, transitionPage } = useListWrappers();

  return (
    <div className="wrapper-list">
      <BarMenu options={OPTIONS_MENU} optionsDropdown={optionsList} optionsDropdownSetting={optionsListSetting} activeItem={activeItem} userLogin={authUser} />

      <div className="wrapper-list__container wrapper-list__children">
        <ListWrappersMenu menu={menu} />

        <div className={transitionPage}>{children}</div>
      </div>
    </div>
  );
}

ListWrappers.propTypes = {
  menu: PropTypes.array,
};

ListWrappers.defaultProps = {
  menu: [],
};

export default ListWrappers;
