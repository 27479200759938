import React from 'react';
import { useTranslation } from 'react-i18next';

function useDialogDelete({ title, subtitle, open }) {
  const { t } = useTranslation(['actions', 'dialogs']);
  const textTitle = title ? title : t('dialogs:delete_item_title');
  const textSubtitle = subtitle ? subtitle : t('dialogs:delete_item_description');
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    setValue('');
  }, [open]);

  function onChangeInput(event) {
    setValue(event.target.value);
  }

  return { textSubtitle, textTitle, value, onChangeInput, t };
}

export default useDialogDelete;
