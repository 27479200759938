import React from 'react';

const AnalyticUsagePage = React.lazy(() => import('../pages/analytics/analyticUsage/AnalyticUsagePage'));

export const PATH_ANALYTIC_USAGE = '/analytic/usage';

const PAGE_ANALYTIC_USAGE = {
  component: AnalyticUsagePage,
  path: PATH_ANALYTIC_USAGE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_ANALYTIC_USAGE];
