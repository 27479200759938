import SelectCustomizable, { utilsSelectCustomizable } from '__core/modules/atoms/SelectCustomizable';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UISelectSize = React.forwardRef(({ menuPosition, name, onBlur, onChange, required, value }, ref) => {
  const [defaultValue, setDefaultValue] = React.useState(undefined);
  const data = [
    { guid: 'inherit', name: 'inherit' },
    { guid: 'small', name: 'small' },
    { guid: 'medium', name: 'medium' },
    { guid: 'large', name: 'large' },
  ];
  const options = utilsSelectCustomizable.getOptionsProperties(data, 'name', 'guid');
  const { t } = useTranslation(['users']);

  React.useEffect(getDefaultValue, [value]);

  function onChangeSelect(event) {
    onChange(event.value);
  }

  function getDefaultValue() {
    const find = options.find((item) => item.value === value);

    if (find) {
      setDefaultValue(find);
    }
  }

  return (
    <SelectCustomizable
      label="Size"
      menuPosition={menuPosition}
      name={name}
      onBlur={onBlur}
      onChange={onChangeSelect}
      options={options}
      required={required}
      value={defaultValue}
    />
  );
});

UISelectSize.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

UISelectSize.defaultProps = {
  menuPosition: 'initial',
  onChange: () => null,
};

export default UISelectSize;
