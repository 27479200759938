import { getError, getSuccess } from 'app/services/toast.service';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

function useToast(model, type) {
  const { addToast } = useToasts(['toast']);
  const { t } = useTranslation(['contents']);

  const dismissTimeout = 8000;
  const autoDismiss = true;

  const errorGeneral = t('toast:error_general');
  const successGeneral = t('toast:success_general');

  function openToast({ error, message, state = 'success', timeout, isList = false }) {
    const appearance = error ? 'error' : state;
    const textDefault = error ? errorGeneral : successGeneral;
    const text = message ? message : textDefault;
    const autoDismissTimeout = timeout ? timeout : dismissTimeout;

    addToast(text, { appearance, autoDismiss, autoDismissTimeout, isList });
  }

  function openToastResponse({ response, message, state, timeout, isList = false }) {
    const { error } = response;
    const appearance = error ? 'error' : state || 'success';
    const textDefault = error ? errorGeneral : successGeneral;
    const text = getText({ error, message });
    const autoDismissTimeout = timeout ? timeout : dismissTimeout;

    addToast(text ? text : textDefault, { appearance, autoDismiss, autoDismissTimeout, isList });
  }

  function getText({ error, message }) {
    const textMessage = message ? message : getTextSuccess();
    return error ? getTextError({ error }) : textMessage;
  }

  function getTextSuccess() {
    return getSuccess(model, type);
  }

  function getTextError({ error }) {
    const { data } = error;
    return getError(data, model);
  }

  return {
    openToast,
    openToastResponse,
  };
}

export default useToast;
