import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import ListWrappers from 'app/modules/wrappers/WrapperList/WrapperList';
import React from 'react';
import UIDialog from './components/UIDialog';
import UIIcons from './components/UIIcons/UIIcons';
import UIInput from './components/UIInput';
import UITest from './components/UITest';
import UIText from './components/UIText';
import UIToast from './components/UIToast';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function UIPage() {
  const initValue = 0;
  const [value, setValue] = React.useState(initValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <ListWrappers>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Icons" {...a11yProps(0)} />
          <Tab label="Text" {...a11yProps(1)} />
          <Tab label="Input" {...a11yProps(2)} />
          <Tab label="General" {...a11yProps(3)} />
          <Tab label="PRUEBAS" {...a11yProps(4)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <UIIcons />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <UIText />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <UIInput />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <UIToast />
        <UIDialog />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <UITest />
      </TabPanel>
    </ListWrappers>
  );
}

export default UIPage;
