export const PT = {
  VIEWER_LANG_TYPE: 'pt',
  VISOR_DATA_ACTION_NOT_POSSIBLE: 'Atualmente, não é possível executar esta ação.',
  VISOR_FILE_HAVE_ERROR: 'Erro de conteúdo.',
  VISOR_FILE_NOT_FOUND: 'Erro, conteúdo não localizado.',
  VISOR_FILE_TYPE_UNDEFINED: 'Erro, tipo de conteúdo não identificado.',
  VISOR_ERROR_404: 'Error 404. Content not found.',
  VISOR_ERROR_403: 'Error 403. Access forbiden.',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS: 'Deseja excluir todas as anotações e interações criadas?',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_YES: 'SIM',
  FLE_POPUP_DELETE_ALL_ANNOTATIONS_NOT: 'NÃO',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_OK: 'Anotações deletadas corretamente.',
  VISOR_LIB_PDFTRON_FEEDBACK_DELETE_ANNOTS_NOOK: 'Não foi possível deletar as anotações.',
  VISOR_LIB_MENU_ADD_PLAYLIST: 'Adicionar playlist',
  VISOR_LIB_MENU_MY_PLAYLIST: 'Meus playlist',
  VISOR_LIB_MENU_EVALUATE_CONTENT: 'Avaliar conteúdo',
  VISOR_LIB_MENU_RE_EVALUATE_CONTENT: 'Requalificar',
  VISOR_LIB_MENU_REPORT_CONTENT: 'Reportar conteúdo',
  VISOR_LIB_COMMENTS_CANCELAR: 'CANCELAR',
  VISOR_LIB_PLAYLIST_GROUP_TITLE: 'Lista de favoritos',
  VISOR_LIB_PLAYLIST_GROUP_PLACEHOLDER: 'Nome da lista',
  VISOR_LIB_PLAYLIST_GROUP_EMPTY: 'Nenhum grupo de lista',
  VISOR_LIB_PLAYLIST_GROUP_SAVE_NEW: 'Criar',
  VISOR_LIB_PLAYLIST_GROUP_CREATE_NEW: '+ Criar nova lista',
  VISOR_LIB_PLAYLIST_SAVE_NEW: 'Criar',
  VISOR_LIB_PLAYLIST_INSTANT: 'Começa em',
  VISOR_LIB_PLAYLIST_IN: 'Lista em',
  VISOR_LIB_PLAYLIST_DELETE: 'Excluir',
  VISOR_LIB_PLAYLIST_CREATED: 'Listas criadas',
  VISOR_LIB_PLAYLIST_EMPTY: 'Ainda não há listas criadas',
  VISOR_LIB_ERRORS_DESCRIPTION: 'Descreva o problema (opcional)',
  VISOR_LIB_ERRORS_ERROR_CONTENT: 'Erro no conteúdo',
  VISOR_LIB_ERRORS_INAPROPIATE: 'Conteúdo inadequado',
  VISOR_LIB_ERRORS_RAZON: 'O que deu errado?',
  VISOR_LIB_ERRORS_REPORT_PROBLEM: 'Relatar um problema',
  VISOR_LIB_EVALUATE_DIFFICULT: 'Qual o seu nível de dificuldade durante os estudos?',
  VISOR_LIB_EVALUATE_UTIL: 'Qual o seu nível de satisfação com o conteúdo?',
  VISOR_LIB_EVALUATE_TITLE: 'Avalie o conteúdo que você estudou',
  VISOR_LIB_EVALUATE_SAVE: 'Enviar',
  VISOR_LIB_PDFTRON_TITLE_SHARE: 'Compartilhar em',
  VISOR_LIB_PDFTRON_SHARE_GCLASSROOM: 'Google Classroom',
  VISOR_LIB_PDFTRON_SHARE_MSTEAMS: 'Microsoft Teams',
  VISOR_LIB_PDFTRON_SHARE_CANCEL: 'Cancelar',
  VISOR_LIB_PDFTRON_TOOL_DRAWING: 'Desenho',
  VISOR_LIB_PDFTRON_DELETE_ALL_ANNOTS: 'Excluir todas as anotações',
  VISOR_LIB_PDFTRON_ERROR_FILE: 'Erro de conteúdo, tente novamente em alguns instantes.',
};
