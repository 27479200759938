export function getUserData() {
  let _user = {};

  try {
    _user.email = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).email;
    _user.guid = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).guid;
    _user.name = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).name;
    _user.lastname = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).lastname;
    _user.role_guid = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).role_guid;
    _user.role_name = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).role_name;
    _user.username = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).username;
    _user.source = JSON.parse(JSON.parse(localStorage.getItem('persist:i2c_auth')).user).source;
  } catch (e_b2) {}

  return _user;
}

export function getToken() {
  let __token = '';
  try {
    __token = JSON.parse(localStorage.getItem('session')).token;
  } catch (e_b1) {}
  try {
    __token = JSON.parse(localStorage.getItem('persist:i2c_auth')).authToken.replace('"', '');
  } catch (e_b2) {}
  return __token;
}

export function getUrlParam(sParam) {
  try {
    var sPageURL = decodeURIComponent(window.location.search.substring(1));

    if (sPageURL == '') {
      sPageURL = window.location.href;
      sPageURL = sPageURL.split('?')[1];
    }

    var sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  } catch (err) {}
}

export const getDomain = (url, dots = 1) => {
  return '//' + window.location.host.replace('backoffice.', '').replace('publisher.', '');

  // url = url.replace(/(https?:\/\/)?(www.)?/i, '');
  // if (url.indexOf('/') !== -1) {
  //   url = url.split('/')[0];
  // }
  // if (url.indexOf(':3000') !== -1) {
  //   url = url.split(':3000')[0];
  // }
  // url = url.split('.');
  // let subdomain = url.length > 3 || (url[2] && url[1].length > 3) || false;
  // if (subdomain) {
  //   url = url.slice(url.length - (url.length - 1)).join('.');
  // } else {
  //   url = url.join('.');
  // }
  // return url;
};

export const setViewerLastLocation = (lastLocation) => {
  if (lastLocation) {
    localStorage.setItem('viewerLastLocation', JSON.stringify(lastLocation));
  }
};

export const getViewerLastLocation = () => {
  return JSON.parse(localStorage.getItem('viewerLastLocation'));
};
