import React from 'react';

const MessagesPage = React.lazy(() => import('../pages/messages/MessagesPage/MessagesPage'));

export const PATH_MESSAGES = '/messages';

const PAGE_MESSAGES = {
  component: MessagesPage,
  path: PATH_MESSAGES,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_MESSAGES];
