import React from 'react';

const UserCreatePage = React.lazy(() => import('../pages/users/UserCreatePage/UserCreatePage'));
const UserDetailPage = React.lazy(() => import('../pages/users/UserDetailPage/UserDetailPage'));
const UsersPage = React.lazy(() => import('../pages/users/UsersPage/UsersPage'));

export const PATH_USER = '/user/:guid';
export const PATH_USERS = '/users';
export const PATH_USER_CREATE = '/user/create';

const PAGE_USER = {
  component: UserDetailPage,
  path: PATH_USER,
};

const PAGE_USERS = {
  component: UsersPage,
  path: PATH_USERS,
};

const PAGE_USER_CREATE = {
  component: UserCreatePage,
  path: PATH_USER_CREATE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_USER_CREATE, PAGE_USER, PAGE_USERS];
