//import axios from '../../api/axios';
import React, { Component } from 'react';
//import ViewerMaster from '../../components/viewers/ViewerMaster';
import ReactOcviewersComponent from 'react-ocviewers-component';
import { withLastLocation } from 'react-router-last-location';
// import { getDomain } from '../../../_core/utils/url';
// Servicios
import { addAnnotation, deleteAnnotation, updateAnnotation } from './supports/viewerAnnotations';
import { createNewComment, deleteComment, updateComment } from './supports/viewerComment';
import {
  API_URL,
  GOTOBACK_MODE,
  IS_BACKOFFICE,
  IS_DEBUG_MODE,
  URL_API_LOCALTEST,
  VIEWER_ACTOR_VERSION,
  VIEWER_LANGUAGE,
  VIEWER_THEME,
} from './supports/viewerConfig';
import {
  afterLoadingViewer,
  closeSesionConsumption,
  finishSesionConsumptionInternal,
  getTracingConsumptionsInternal,
  initSesionConsumptionInternal,
  reportTraceConsumptionInternal,
  timerInternal,
} from './supports/viewerConsumption';
import {
  contentsTypes,
  evaluateContentError,
  getAccesAppContent,
  getContent,
  getContentsTypesName,
  getDataCommonElements,
  getExtraData,
  getFileStorage,
  getMetaScraper,
  getShowAt,
} from './supports/viewerContent';
import { getContentHotspotInternal } from './supports/viewerContentHotspots';
import { reportParentErrorInternal, sendReportContentErrorInternal, showErrorInternal } from './supports/viewerControlErrors';
import { getTranslations } from './supports/viewerLanguage';
import { createNewPlaylist, createNewPlaylistGroup, deletePlaylist, updatePlaylist } from './supports/viewerPlaylist';
import { getReportInternal, getReviewsInternal } from './supports/viewerReportReview';
import { getResponseExerciseInternal, hasValidatedExerciseInternal } from './supports/viewerResponsesLemonade';
import { getAttemptScormInternal, setAttemptScormInternal } from './supports/viewerResponsesScorm';
import { getDomain, getToken, getUserData, getViewerLastLocation, setViewerLastLocation } from './supports/viewerUtils';
import ToastViewers from './ToastViewers';

// Styles For Themes
import './styles/viewers_all.scss';
import './styles/viewers_darkblue1.scss';
import './styles/viewers_gray.scss';
import './styles/viewers_loading.scss';
import './styles/viewers_orange1.scss';
import './styles/viewers_orangeedv.scss';
import './styles/viewers_toast.scss';

const disable_all_consumption = false;

class ViewersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      typeContent: '',
      is_loaded: false,
      is_loaded_error: false,
      is_focus: true,
      error_msj: '',
      session_guid: null,
      consumption_guid: null,
      progressConsumed: 0,
      progress_start: 0,
      progress_end: 0,
      progress_start_test: 0,
      progress_end_test: 0,
      loadingLabelType: 'Reader',
      dataToast: {},
      openToast: false,
      visible_loading_full: false,
      comments: [],
      courses: [],
      user_autor: 'Xf22d2e0-c453-11e9-b38f-7b555c1bc2cb',
      user_role: 'R04',
      user_name: 'Admin Editorial',
      reviews: {},
      playlists: [],
      playlist_groups: [],
      annotations: [],
      toback: null,
      last_show_at: 0,
      translate: {},
      state_lemonade_exercise: [],
      error_viewer_text: '',
      re_guid_content: '',
      re_endpoint: '',
      re_endpoint_content_version: 1,
      re_endpoint_file: '',
      questionData: '', // FTDI2C-3157
      styleContentApp: '', // FTDI2C-3238
      oedCustomHeight: null, // FTDI2C-3460
      oedCustomWidth: null, // FTDI2C-3460
      suspend_data: '',
      viewerScormLessonItem: null,
      viewerScormReferenceId: null,
      viewerScormScope: null,
      viewerScormPersonGuid: null,
      viewerScormBookGuid: null,
      viewerScormLineItem: null,
      header: true,
      showHeader: props.showHeader,
    };

    this.timer = this.timer.bind(this);
    this.isLoaded = this.isLoaded.bind(this);
    this.getTracingConsumptions = this.getTracingConsumptions.bind(this);
    this.onCreateNewPlaylist = this.onCreateNewPlaylist.bind(this);
    this.onDeletePlaylist = this.onDeletePlaylist.bind(this);
    this.onUpdatePlaylist = this.onUpdatePlaylist.bind(this);
    this.onCreateNewPlaylistGroup = this.onCreateNewPlaylistGroup.bind(this);
    this.onDeletePlaylistGroup = this.onDeletePlaylistGroup.bind(this);
    this.onCreateNewComment = this.onCreateNewComment.bind(this);
    this.onUpdateComment = this.onUpdateComment.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
    this.onAddAnnotation = this.onAddAnnotation.bind(this); // ANNOTATIONS
    this.onUpdateAnnotation = this.onUpdateAnnotation.bind(this); // ANNOTATIONS
    this.onDeleteAnnotation = this.onDeleteAnnotation.bind(this); // ANNOTATIONS
    // Commons elements
    this.getReviews = this.getReviews.bind(this);
    this.getReport = this.getReport.bind(this);
    this.goToBack = this.goToBack.bind(this);
    this.showError = this.showError.bind(this);
    // Seteando INICIAL timestamp
    this._started_at = Date.now();
    // Hotspots
    this.getContentHotspot = this.getContentHotspot.bind(this); // ANNOTATIONS
    this.initSesionConsumption = this.initSesionConsumption.bind(this); // ANNOTATIONS
    this.finishSesionConsumption = this.finishSesionConsumption.bind(this); // ANNOTATIONS
    this.reportTraceConsumption = this.reportTraceConsumption.bind(this); // ANNOTATIONS
    this.setParamsViewer = this.setParamsViewer.bind(this);
    this.requestExtraData = this.requestExtraData.bind(this);
    this.nextStepGetContent = this.nextStepGetContent.bind(this); // LANG
    this.hasValidatedExercise = this.hasValidatedExercise.bind(this); // EXERCISES MINT VISOR
    // this.hasChangedResponseExercise = this.hasChangedResponseExercise.bind(this); // EXERCISES MINT VISOR
    this.getResponseExercise = this.getResponseExercise.bind(this); // EXERCISES MINT VISOR
    this.LOCALsetAttemptScorm = this.LOCALsetAttemptScorm.bind(this);
    this.LOCALgetAttemptScorm = this.LOCALgetAttemptScorm.bind(this);
    this.lastLocation = this.props.lastLocation;
    this.onClickShare = this.onClickShare.bind(this); // COMPARTIR

    // FOCUS en Contenedor
    // this.setFocus = this.setFocus.bind(this);
    // window.addEventListener('blur', this.setFocus);
    // window.addEventListener('focus', this.setFocus);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showHeader != this.props.showHeader) {
      this.setState({
        showHeader: this.props.showHeader,
      });
    }
  }

  componentWillUnmount() {
    //document.removeEventListener('scroll', this.trackScrolling);
    try {
      clearInterval(this.intervalId);
    } catch (erre) {
      console.log(erre);
    }

    // Llamada al servicio para Terminar SESIÓN DE CONSUMO
    closeSesionConsumption(this);
  }

  componentDidMount() {
    console.log(VIEWER_ACTOR_VERSION);

    // Clase para el contenedor de Visor
    document.body.classList.add('vwt_' + VIEWER_THEME);

    // Set localización antes de ingresar al Visor
    setViewerLastLocation(this.lastLocation);

    if (this.state.showHeader) {
      document.getElementById('container_pfdtron').classList.remove('rocvc_module_not_header');
      document.body.classList.remove('rocvc_module_not_header');
    }

    // Recuperando traducciones
    getTranslations(
      function () {
        this.nextStepGetContent();
      }.bind(this)
    );

    // Lemonade - Recuperar Question Data
    window.getQuestionData = function (_data, _callback) {
      _callback(this.state.questionData);
    }.bind(this);
  }

  // Recuperar datos del Contenido
  nextStepGetContent() {
    this.setState({ toback: window.history.length });
    const __token = getToken();

    // GET Content
    let _content_guid =
      this.props && this.props.match && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id.replace('#', '')
        : this.props && this.props.match && this.props.match.params && this.props.match.params.guid
        ? this.props.match.params.guid.replace('#', '')
        : '';

    const _this = this;
    this.setState({
      re_endpoint: 'Endpoint: GET cms/contents/' + _content_guid,
      re_guid_content: _content_guid,
    });

    var _file_cookies = [];

    // URL PARAMS
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let _lessonItem = urlParams.get('lessonItem') || 'viewer'; // === scope
    let studentGuid = urlParams.get('user_guid') || null; // === GUID de alumno par xApi

    this.setState({
      viewerScormLessonItem: urlParams.get('lessonItem') || 'viewer', // === scope
      viewerScormReferenceId: urlParams.get('referenceId') || null, // GUID interno del Scorm
      viewerScormScope: urlParams.get('scope') || null, // GUID de curso (si es LIBRO) - lesson_item (si es de leccion)
      viewerScormPersonGuid: urlParams.get('personGuid') || null, // GUID del usuario,
      viewerScormBookGuid: urlParams.get('bookGuid') || null, // GUID del Libro
      viewerScormLineItem: urlParams.get('lineitem') || null, // LTI parámetro
      viewerScormFromShared: urlParams.get('fromShared') || null, // Si viene por un link compartido
      header: urlParams.get('header') === 'false' ? false : true,
    });

    getContent(
      { content_guid: _content_guid, studentGuid: studentGuid },
      function (_content_data) {
        this.setState({ re_endpoint_content_version: _content_data && _content_data.version ? _content_data.version : 1 });

        // Evaluar si hubo error
        evaluateContentError(
          _content_data,
          function (_content) {
            let content;
            if (_content.error) {
              this.showError(_content.value);
              return;
            } else {
              content = _content.content;
            }

            // let _contentsTypes = contentsTypes.findIndex((item) => item.guid == content.type_guid);
            let _contentsTypes = contentsTypes.find((o) => o.guid === content.type_guid);
            if (!getContentsTypesName(content)) {
              this.showError('FILE_TYPE_UNDEFINED');
              return;
            }

            // Annotaciones
            let _annotations = [];
            if (content && content.annotations && content.annotations.length > 0) {
              for (var _an = 0; _an < content.annotations.length; _an++) {
                _annotations.push(content.annotations[_an].annotation);
              }
            }

            let _type = _contentsTypes.type;
            this.setState({
              loadingLabelType: getContentsTypesName(content),
              styleContentApp: _type === 'app' ? 'stylecontentapp' : '',
              re_endpoint_file: content.url,
              annotations: _annotations,
              oedCustomHeight: '100%', // HTML Interactivo - custom height/width
              oedCustomWidth: '100%', // HTML Interactivo - custom height/width
            });

            // Inactive content status
            if (content.status == 'inactive') {
              this.showError('CONTENT_DISABLED');
              return;
            }

            // Si es de tipo App, comprobar Iframe
            if (_type === 'app') {
              try {
                var arr = String(content.url).split('/');
                var protocol = String(arr[0]).toLocaleLowerCase();
                if (String(window.location.protocol).toLocaleLowerCase() !== protocol) {
                  console.error('Mixed content detected, then, show card!');
                  this.showError('CONTENT_APP_URL_NOT_ACCESSIBLE');
                  return;
                }
              } catch (__err_) {}
            }

            // const show_at_viewer = getShowAt(this.props, _type);

            // Objeto content data final
            let _data = {
              content_guid: content.guid,
              type: _type,
              description: content.description,
              thumbnail: content.thumbnail,
              name: content.name,
              show_at: getShowAt(this.props, _type), // Capturando show_at
              token: __token,
              api: IS_DEBUG_MODE ? URL_API_LOCALTEST : getDomain(window.location.href, false),
              app_referer: IS_DEBUG_MODE ? URL_API_LOCALTEST : getDomain(window.location.href, false).replace('/api', ''),
              path_root_mint_visor: '/visor/index.html',
              path_root_epub: '/epub/index.html',
            };

            if (_content.videoType === 'vimeo') {
              _data.video_vimeo_options = {
                autoplay: false,
                extraclass: '',
                color: '00a0e6',
                showtitle: false,
                controls: true,
                showbyline: false,
                vimeo_verification_tolerance: 5000,
              };
            }

            let headers = { Authorization: __token };
            // let API_URL = IS_DEBUG_MODE ? URL_API_LOCALTEST : '//' + getDomain(window.location.href, false) + '/api/';
            let _user_data = getUserData();

            if (_type == 'html_interactive') {
              _data.isPassParams = true;
              _data.url_api = API_URL;
              _data.token = __token;
            }

            let requireGetFile = _contentsTypes.mustGetFile;
            let requireGetFileDependency = _contentsTypes.hasDependency;

            if (requireGetFile) {
              let _url_content = IS_BACKOFFICE && _type === 'fixed_layout' ? content.url + '?mode=no-cache' : content.url;

              getFileStorage(
                content.type,
                _url_content,
                headers,
                API_URL,
                function (responseUrl) {
                  if (responseUrl === 'FILE_FORBIDDEN' || responseUrl === 'FILE_NOT_FOUND') {
                    this.showError(responseUrl);
                    return;
                  }

                  if (_type == 'xapi') {
                    let xapiQuery = content && content.xapi && content.xapi.query ? content.xapi.query : '';
                    xapiQuery = xapiQuery.replace('index_prof.html', '').replace('index_alu.html', '');
                    if (_user_data.role_guid === 'R02') {
                      responseUrl = responseUrl.replace('index_alu.html', 'index_prof.html');
                    } else {
                      responseUrl = responseUrl.replace('index_prof.html', 'index_alu.html');
                    }
                    responseUrl = responseUrl + xapiQuery;

                    if (_content_data && _content_data.ghostUser) {
                      let encodedUser = encodeURIComponent(content.xapi.actor.email);
                      let encodedUserGhost = encodeURIComponent(_content_data.ghostUser);
                      responseUrl = responseUrl.replace(encodedUser, encodedUserGhost);
                    }

                    window.location.href = responseUrl;
                    return;
                  }

                  _data.url = responseUrl;

                  // Si el contenido requiere de dependencia
                  if (requireGetFileDependency) {
                    let _url_dependency = content.dependencies[0].url;

                    getFileStorage(
                      'CTTY_00_' + content.type,
                      _url_dependency,
                      headers,
                      API_URL,
                      function (responseUrlDepenency) {
                        // Parámetros requeridos por Dependencia para contenido Scorm
                        if (_type == 'scorm') {
                          _data.path_root_scorm_visor = responseUrlDepenency;
                          _data.commit_mode = 'finish'; // Modo de guardar con el evento finish
                          _data.menu = 'b'; // que el menú lateral sea desplegable
                          _data.r = '10'; // máximo de score 10
                          _data.personGuid = _user_data.guid;
                          _data.token = __token;
                          _data.api = API_URL;
                          _data.app_referer = API_URL.replace('/api', '');

                          if (_lessonItem) {
                            _data.lesson_item = _lessonItem;
                          }
                          if (this.state.viewerScormReferenceId) {
                            _data.itemScorm = this.state.viewerScormReferenceId;
                          }

                          // Scorm from view TEACHER
                          if (this.state.viewerScormReferenceId && this.state.viewerScormScope && this.state.viewerScormPersonGuid) {
                            _data.personGuid = this.state.viewerScormPersonGuid;
                            _data.mode = 'read'; // Solo lectura
                            _data.lesson_item = this.state.viewerScormScope; // (scope) DEBE SER => en libro, el GUID del curso

                            if (this.state.viewerScormBookGuid) {
                              // Si viene de Hotspot de libro
                              let _itemScorm = this.state.viewerScormReferenceId;
                              let _itemScormReplace = this.state.viewerScormReferenceId.split('_');
                              _itemScorm = _itemScorm.replace(_itemScormReplace[0] + '_', '');
                              _data.itemScorm = _itemScorm; // ITEM en el manifiesto
                              _data.itemScormFL = this.state.viewerScormReferenceId; // ITEM en el manifiesto + "P2_"
                              _data.content_guid = this.state.viewerScormBookGuid;
                            } else {
                              // Si viene de Scorm de lección
                              _data.itemScorm = this.state.viewerScormReferenceId; // ITEM en el manifiesto
                              _data.itemScormFL = this.state.viewerScormReferenceId; // ITEM en el manifiesto
                              _data.content_guid = _content_guid;
                            }
                          }

                          // Caso cuando llega un link compartido por Gclassroom o MsTeams
                          if (this.state.viewerScormFromShared) {
                            let _itemScorm = this.state.viewerScormReferenceId;
                            let _itemScormReplace = this.state.viewerScormReferenceId.split('_');
                            _itemScorm = _itemScorm.replace(_itemScormReplace[0] + '_', '');
                            _data.itemScorm = _itemScorm; // ITEM en el manifiesto
                            _data.itemScormFL = this.state.viewerScormReferenceId; // ITEM en el manifiesto + "P2_"
                            _data.content_guid = this.state.viewerScormBookGuid;
                            _data.lesson_item = this.state.viewerScormScope;
                          }

                          // Caso LTI
                          if (this.state.viewerScormLineItem || (_user_data && _user_data.source === 'lti')) {
                            // Si proviene de un Lesson Item
                            _data.lineitem = this.state.viewerScormLineItem;

                            // Si proviene de un Hotspot se agregan más datos
                            let _itemScorm = this.state.viewerScormReferenceId;
                            if (_itemScorm && this.state.viewerScormBookGuid) {
                              let _itemScormReplace = this.state.viewerScormReferenceId.split('_');
                              _itemScorm = _itemScorm.replace(_itemScormReplace[0] + '_', '');
                              _data.itemScorm = _itemScorm; // ITEM en el manifiesto
                              _data.itemScormFL = this.state.viewerScormReferenceId; // ITEM en el manifiesto + "P2_"
                              _data.content_guid = this.state.viewerScormBookGuid;
                              _data.lesson_item = this.state.viewerScormScope;
                            }
                          }
                        }

                        // Parámetros requeridos por Dependencia para contenido Cat Book
                        if (_type == 'cat_book') {
                          _data.cat_dependency = responseUrlDepenency;
                          _data.jwt = __token;
                          _data.is_teacher = _user_data && _user_data.role_guid && _user_data.role_guid === 'R02' ? true : false;
                          _data.api_i2c = API_URL.replace('/api/', '').replace('/api', '').replace('api/', '') + '/i2c-v1/';
                        }

                        this.requestExtraData(_content_guid, _data, content);
                      }.bind(this)
                    );
                  } else {
                    this.requestExtraData(_content_guid, _data, content);
                  }
                }.bind(this)
              );
            } else {
              let _this = this;
              if (_type == 'link') {
                if (VIEWER_THEME === 'orangeedv') {
                  if (_data && _data.description && _data.description != null && _data.description != '') {
                    _data.name = _data.description;
                    _data.description = '';
                  }
                }
                getMetaScraper(content, _data, function (_data_return) {
                  _this.requestExtraData(_content_guid, _data_return, content);
                });
              } else if (_type == 'app') {
                getAccesAppContent(content, _data, function (_data_return) {
                  _this.requestExtraData(_content_guid, _data_return, content);
                });
              } else {
                this.requestExtraData(_content_guid, _data, content);
              }
            }
          }.bind(this)
        );
      }.bind(this)
    );
  }

  requestExtraData(_content_guid, _data, content) {
    // EXTRA DATA
    let _extradata = {
      language: localStorage.getItem('i18nextLng') && localStorage.getItem('i18nextLng') != '' ? localStorage.getItem('i18nextLng') : 'es',
    };
    getExtraData(
      _data,
      content,
      _extradata,
      this,
      function (__extradata) {
        this.setParamsViewer(_content_guid, _data, content, __extradata);
      }.bind(this)
    );
  }

  setParamsViewer(_content_guid, _data, e, _extradata) {
    // FIXED LAYOUT & LEMONADE EXERCISES
    // _extradata.hasValidatedExercise = this.hasValidatedExercise;
    // _extradata.getResponseExercise = this.getResponseExercise;

    _data.extraData = _extradata;
    // END EXTRA DATA

    // Si el archivo Office no es compatible con PDFTron, iniciar como Zip
    if (
      _data &&
      _data.url &&
      (_data.url.indexOf('.pps') >= 0 || _data.url.indexOf('.ppsx') >= 0 || _data.url.indexOf('.ppt') >= 0 || _data.url.indexOf('.xls') >= 0)
    ) {
      if (e.type_guid == 'CTTY_12') {
        _data.type = 'zip';
      }
    }

    if (e.type_guid == 'CTTY_12' && e.storage_status != 'upload-done') {
      _data.type = 'zip';
    }

    this.setState({
      data: _data,
      typeContent: e.type_guid,
      reviews: { difficult: e.difficult, usefull: e.usefull, range: 5 },
      playlists: e.playlists,
      playlist_groups: e.playlist_groups,
      comments: e.comments,
      courses: e.courses,
      user_autor: this.state.user_autor,
      user_role: this.state.user_role,
      user_name: this.state.user_name,
      questions: e.questions,
      translate: window.ocviewerlang,
    });

    getDataCommonElements(
      _content_guid,
      function (e_common_elements) {
        this.setState({
          reviews: { difficult: e_common_elements.difficult, usefull: e_common_elements.usefull, range: 5 },
          playlists: e_common_elements.playlists,
          playlist_groups: e_common_elements.playlist_groups,
          comments: e_common_elements.comments,
          courses: e_common_elements.courses,
          questions: e_common_elements.questions,
        });
      }.bind(this)
    );
  }

  isLoaded(e) {
    // FTDI2C-3460
    try {
      if (this.state.oedCustomHeight && window.document.getElementById('vhtmlint_iframe_el')) {
        window.document.getElementById('vhtmlint_iframe_el').style.maxWidth = this.state.oedCustomWidth;
        window.document.getElementById('vhtmlint_iframe_el').style.maxHeight = this.state.oedCustomHeight;
      }
    } catch (_er_ct_oed) {
      console.log(_er_ct_oed);
    }

    this.setState({ is_loaded: true });

    if (!disable_all_consumption) {
      afterLoadingViewer(this, e);
    }

    if (
      VIEWER_THEME === 'orangeedv' &&
      this.state &&
      this.state.data &&
      this.state.data.type &&
      this.state.data.type === 'link' &&
      (!this.state.data.thumbnail || this.state.data.thumbnail === '') &&
      this.state.data.url &&
      this.state.data.url != ''
    ) {
      try {
        document.getElementsByClassName('MediaViewerLink_content')[0].classList.add('rocvc_link_no_thumb');
      } catch (_ellik) {
        console.log('Canot add class rocvc_link_no_thumb');
      }
    }
  }

  // TIMER envío de TRAZAS
  timer(e = null) {
    timerInternal(this, e);
  }

  getTracingConsumptions(e) {
    getTracingConsumptionsInternal(e, this);
  }

  onClickShare(_type) {
    this.viewerInstance.onClickShare(_type);
  }

  // Crear nueva Playlist
  onCreateNewPlaylist(e, _callback) {
    createNewPlaylist(this, e, _callback);
  }

  // Actualizar Playlist
  onUpdatePlaylist(e, _callback) {
    updatePlaylist(this, e, _callback);
  }

  // Crear nuevo Grupo de  Playlist
  onCreateNewPlaylistGroup(e, _callback) {
    createNewPlaylistGroup(this, e, _callback);
  }

  // Eliminar Grupo de Playlist
  onDeletePlaylistGroup(e, _callback) {
    createNewPlaylistGroup(this, e, _callback);
  }

  // Eliminar de Playlist
  onDeletePlaylist(_e, _callback) {
    deletePlaylist(this, _e, _callback);
  }

  // Crear comentario
  onCreateNewComment(_e, _callback) {
    createNewComment(this, _e, _callback);
  }

  // Actualizar comentario
  onUpdateComment(_e, _callback) {
    updateComment(this, _e, _callback);
  }

  // Elimianr comentario
  onDeleteComment(_e, _callback) {
    deleteComment(this, _e, _callback);
  }

  // Evaluar contenido
  getReviews(e) {
    getReviewsInternal(this, e);
  }

  // Reportar contenido
  getReport(e) {
    getReportInternal(this, e);
    //try{ e.type = ( e.type == "inappropiate" ) ? 'inappropriate' : e.type; }catch(_er){ console.log(_er); }
  }

  // ANNOTATIONS
  onAddAnnotation(_annotation_id, _annotation_content, _callback = null) {
    addAnnotation(this, _annotation_id, _annotation_content, _callback);
  }

  onUpdateAnnotation(_annotation_id, _annotation_content, _callback = null) {
    updateAnnotation(this, _annotation_id, _annotation_content, _callback);
  }

  onDeleteAnnotation(_obj) {
    deleteAnnotation(this, _obj);
  }
  // END ANNOTATIONS

  // DATA HOTSPOTS
  getContentHotspot(_obj, _callback) {
    getContentHotspotInternal(this, _obj, _callback);
  }

  initSesionConsumption(_data, _callback) {
    //console.log('INICIAR CONSUMO');

    if (disable_all_consumption) {
      _callback(false);
      return;
    }

    initSesionConsumptionInternal(this, _data, _callback);
  }

  finishSesionConsumption(_data_finish, _callback) {
    //console.log('FINALIZAR CONSUMO');

    if (disable_all_consumption) {
      _callback(false);
      return;
    }

    finishSesionConsumptionInternal(this, _data_finish, _callback);
  }

  reportTraceConsumption(_data_consumption, _callback) {
    if (disable_all_consumption) {
      _callback(false);
      return;
    }

    reportTraceConsumptionInternal(this, _data_consumption, _callback);
  }
  // END DATA HOTSPOTS

  // EXERCISES
  // EXERCISE, enviando traza
  hasValidatedExercise(_data, _callback) {
    hasValidatedExerciseInternal(this, _data, _callback);
  }

  // hasChangedResponseExercise(_data, _callback) {}

  // EXERCISE, recuperando traza
  getResponseExercise(_data, _callback) {
    getResponseExerciseInternal(this, _data, _callback);
  }
  // END EXERCISES

  goToBack() {
    // Excepción darkblue1 & scorm
    if (VIEWER_THEME === 'darkblue1' && this.state.data.type === 'scorm') {
      let viewerLastLocation = getViewerLastLocation();
      if (viewerLastLocation) {
        this.props.history.push({ pathname: viewerLastLocation.pathname });
      }
      return;
    }
    // FIN Excepción darkblue1 & scorm

    if (GOTOBACK_MODE && GOTOBACK_MODE === 'close' && this.state && this.state.data && this.state.data.type && this.state.data.type === 'scorm') {
      this.viewerInstance.viewerScormForceSave(function () {
        window.close();
      });
      return;
    }

    if (GOTOBACK_MODE === 'close') {
      window.close();
      return;
    }

    if (GOTOBACK_MODE === 'toback') {
      if (this.lastLocation && this.lastLocation.pathname) {
        this.props.history.push({ pathname: this.lastLocation.pathname });
      } else {
        window.history.back();
      }
      return;
    }
  }

  // Botón Volver - mensaje error
  volverError() {
    this.goToBack();
  }

  // Botón Volver - mensaje error
  showError(_msj) {
    showErrorInternal(this, _msj);
  }

  // Enviados desde Fixed Layout
  reportParentError(_report) {
    reportParentErrorInternal(this, _report);
  }
  // Enviados desde Fixed Layout

  sendReportContentError(_report) {
    sendReportContentErrorInternal(this, _report);
  }
  // REPORTE DE ERRORES
  LOCALgetAttemptScorm = (ee, _callback) => {
    getAttemptScormInternal(this, ee, _callback);
  };

  LOCALsetAttemptScorm = (_data) => {
    setAttemptScormInternal(this, _data);
  };

  render() {
    // Loading cover
    const style_loading = !this.state.is_loaded ? { display: 'flex' } : { display: 'none' };
    const style_viewer = this.state.is_loaded ? { visibility: 'visible' } : { visibility: 'hidden' };
    const _visible_loading_full = this.state.visible_loading_full ? { display: 'flex' } : { display: 'none' };
    const style_loading_error = this.state.is_loaded_error ? { display: 'flex' } : { display: 'none' };

    let includeCommonsElements = {
      header: this.state.showHeader, // Incluir cabecera
      title: true, // Incluir título
      gotoback: true, // Incluir botón de volver atrás
      progress: true, // Incluir barra de progreso
      report: true, // Incluir botón y componente REPORT - reporte de errores
      reviews: true, // Incluir botón y componente REVIEWS - calificación
      playlists: true, // Incluir botón y componente PLAYLISTS - marcadores
      questions: false, // Incluir botón y componente QUESTIONS - preguntas
      comments: false, // Incluir botón y componente COMMENTS - comentarios
    };

    var includeCommentsShareElements = [false, true, false, true];

    return (
      <div>
        <ToastViewers data={this.state.dataToast} open={this.state.openToast} closeToast={() => this.setState({ openToast: false })} />

        <div className="lds-spinner loading_full_master" style={_visible_loading_full}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="ViewerMaster_loading_cover" style={style_loading}>
          <div className="__logo"></div>
          <div className="label_loading">{this.state.loadingLabelType}</div>
          <div className="progress_loading"></div>
        </div>

        <div className="ViewerMaster_loading_cover ViewerMaster_loading_cover_error" style={style_loading_error}>
          <div className="__logo"></div>
          <div className="msje_error">{this.state.error_msj}</div>
          <div className="label_loading_sep" onClick={this.volverError.bind(this)}>
            Voltar
          </div>
          <div id="error_viewer" guid={this.state.re_guid_content}>
            {this.state.error_viewer_text}
          </div>
        </div>

        <div style={style_viewer} className={`testmaster ${this.state.styleContentApp}`}>
          <ReactOcviewersComponent
            ref={(instance) => {
              this.viewerInstance = instance;
            }}
            data={this.state.data}
            last_show_at={this.state.last_show_at}
            isLoaded={this.isLoaded}
            progressConsumed={this.state.progressConsumed}
            fullComponentStyle="true"
            commonsElements={includeCommonsElements}
            commentsShareElements={includeCommentsShareElements}
            tracingConsumptions={this.getTracingConsumptions}
            goToBack={this.goToBack}
            showError={this.showError}
            reviews={this.state.reviews}
            getReviews={this.getReviews}
            getReport={this.getReport}
            playlists={this.state.playlists}
            onCreateNewPlaylist={this.onCreateNewPlaylist}
            onDeletePlaylist={this.onDeletePlaylist}
            onUpdatePlaylist={this.onUpdatePlaylist}
            playlistGroups={this.state.playlist_groups}
            onCreateNewPlaylistGroup={this.onCreateNewPlaylistGroup}
            onDeletePlaylistGroup={this.onDeletePlaylistGroup}
            comments={this.state.comments}
            courses={this.state.courses}
            user_name={this.state.user_name}
            user_autor={this.state.user_autor}
            user_role={this.state.user_role == 'R01' ? 'student' : this.state.user_role == 'R02' ? 'teacher' : 'admin'}
            onCreateNewComment={this.onCreateNewComment}
            onUpdateComment={this.onUpdateComment}
            onDeleteComment={this.onDeleteComment}
            questions={this.state.questions}
            translate={this.state.translate}
            lang={VIEWER_LANGUAGE}
            theme={VIEWER_THEME}
            annotations={this.state.annotations}
            onAddAnnotation={this.onAddAnnotation}
            onUpdateAnnotation={this.onUpdateAnnotation}
            onDeleteAnnotation={this.onDeleteAnnotation}
            getContentHotspot={this.getContentHotspot}
            initSesionConsumption={this.initSesionConsumption}
            finishSesionConsumption={this.finishSesionConsumption}
            reportTraceConsumption={this.reportTraceConsumption}
            hasValidatedExercise={this.hasValidatedExercise}
            hasChangedResponseExercise={this.hasChangedResponseExercise}
            getResponseExercise={this.getResponseExercise}
            getAttemptScorm={this.LOCALgetAttemptScorm}
            setAttemptScorm={this.LOCALsetAttemptScorm}
            onClickShare={this.onClickShare}
          />
        </div>
      </div>
    );
  }
}

//export default ViewersContainer;

// const mapDispacthToPropsActions = (dispatch) => ({});

// const mapStateToProps = (state) => {
//   return {
//     //userWhoami: selectLogged(state),
//     currentCourse: coreEntities.courses.selectors.getCurrentCourse(state),
//   };
// };

export default withLastLocation(ViewersContainer);
