import { getLocations } from 'app/crud/locations.crud';
import { orderWithAccents } from 'app/utils/array';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_LOCATIONS, REDUCER_LOCATIONS } from '../conf';

export const actionTypes = {
  GetLocations: 'cms/GET_LOCATIONS',
  SetLocations: 'cms/SET_LOCATIONS',
  GetStates: 'cms/GET_LOCATIONS_STATES',
  SetStates: 'cms/SET_LOCATIONS_STATES',
  GetCities: 'cms/GET_LOCATIONS_CITIES',
  SetCities: 'cms/SET_LOCATIONS_CITIES',
};

const initialState = {
  loading: false,
  countries: [],
  states: [],
  cities: [],
};

export const reducer = persistReducer({ storage, key: PERSIST_LOCATIONS, whitelist: ['countries'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetLocations: {
      return { ...state, loading: true };
    }

    case actionTypes.SetLocations: {
      return { ...state, loading: false, countries: action.payload };
    }

    case actionTypes.GetStates: {
      return { ...state, loading: true };
    }

    case actionTypes.SetStates: {
      const { countries } = state;
      const find = countries.find((item) => item.guid === action.payload.value);

      return { ...state, loading: false, states: find ? orderWithAccents(find.states, 'state') : [] };
    }

    case actionTypes.GetCities: {
      return { ...state, loading: true };
    }

    case actionTypes.SetCities: {
      const { states } = state;
      const find = states.find((item) => item.guid === action.payload.value);

      return { ...state, loading: false, cities: find ? orderWithAccents(find.cities, 'city') : [] };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLoading: (state) => {
    return state.entities && state.entities[REDUCER_LOCATIONS] ? state.entities[REDUCER_LOCATIONS].loading : null;
  },
  getLocations: (state) => {
    return state.entities && state.entities[REDUCER_LOCATIONS] ? state.entities[REDUCER_LOCATIONS].countries : null;
  },
  getCountries: (state) => {
    return state.entities && state.entities[REDUCER_LOCATIONS] ? state.entities[REDUCER_LOCATIONS].countries : null;
  },
  getStates: (state) => {
    return state.entities && state.entities[REDUCER_LOCATIONS] ? state.entities[REDUCER_LOCATIONS].states : null;
  },
  getCities: (state) => {
    return state.entities && state.entities[REDUCER_LOCATIONS] ? state.entities[REDUCER_LOCATIONS].cities : null;
  },
};

export const actions = {
  getLocations: (params) => ({ type: actionTypes.GetLocations, payload: { params } }),
  getStates: (params) => ({ type: actionTypes.GetStates, payload: { ...params } }),
  getCities: (params) => ({ type: actionTypes.GetCities, payload: { ...params } }),
};

function* fetchLocations() {
  const { data } = yield getLocations();

  if (data && data.status === 'success' && data.data) {
    yield put({ type: actionTypes.SetLocations, payload: data.data });
  }
}

function* getLocationsIfNeeded() {
  const countries = yield select(selectors.getCountries);

  if (!countries || countries.length === 0) {
    yield fetchLocations();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetLocations, getLocationsIfNeeded);
  yield takeLatest(actionTypes.GetStates, function* getStatesSaga(action) {
    const { payload } = action;

    yield put({ type: actionTypes.SetStates, payload });
  });

  yield takeLatest(actionTypes.GetCities, function* getCitiesSaga(action) {
    const { payload } = action;

    yield put({ type: actionTypes.SetCities, payload });
  });
}
