import { PERSIST_I18N } from 'app/store/conf';
import storage from 'redux-persist/lib/storage';

const actionTypes = {
  SetLanguage: 'i18n/SET_LANGUAGE',
  SetScope: 'i18n/SET_SCOPE',
  GetScope: 'i18n/GET_SCOPE',
};

const initialState = {
  lang: 'es',
  scopes: {},
};

export const reducer =
  ({ storage, key: PERSIST_I18N, whitelist: ['scopes'] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SetLanguage:
        return { ...state, lang: action.payload.lang };

      case actionTypes.SetScope:
        return { ...state, scopes: { [action.payload.params.lang_id]: action.payload.params.json } };

      default:
        return state;
    }
  });

export const selectors = {
  getScopes: (state) => {
    return (state[PERSIST_I18N] && state[PERSIST_I18N].scopes) || null;
  },
};

export const actions = {
  setLanguage: (lang) => ({ type: actionTypes.SetLanguage, payload: { lang } }),
  setScope: (params) => ({ type: actionTypes.SetScope, payload: { params } }),
  getScope: (params) => ({ type: actionTypes.GetScope, payload: { params } }),
};
