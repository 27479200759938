import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ListWrappersMenu({ menu }) {
  const { pathname } = useLocation();

  function isDisabledLink(item) {
    return item.to !== pathname ? 'wrapper-list-menu__item-disabled' : '';
  }

  function isSelectedLink(item) {
    return item.to === pathname ? 'wrapper-list-menu__item-disabled-line' : '';
  }

  return (
    <div className="wrapper-list-menu">
      {menu.map((item) => (
        <div key={item.to} className="wrapper-list-menu__item">
          <Link to={item.to} className={isDisabledLink(item)}>
            {item.title}
          </Link>

          <div className={isSelectedLink(item)}></div>
        </div>
      ))}
    </div>
  );
}

ListWrappersMenu.propTypes = {
  menu: PropTypes.array,
};

ListWrappersMenu.defaultProps = {
  menu: [],
};

export default ListWrappersMenu;
