import React from 'react';

const GroupsPage = React.lazy(() => import('../pages/groups/GroupsPage/GroupsPage'));

export const PATH_GROUPS = '/groups';
export const PATH_GROUPS_CREATE = '/group/create';

const PAGE_GROUPS = {
  component: GroupsPage,
  path: PATH_GROUPS,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_GROUPS];
