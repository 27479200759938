import { CircularProgress } from '@material-ui/core';
import Icon from '__core/modules/atoms/Icons/Icon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  borderRadius,
  className,
  color,
  dataTestid,
  disabled,
  icon,
  iconColor,
  iconHtmlColor,
  iconPosition,
  iconSize,
  loading,
  onClick,
  onlyIcon,
  size,
  text,
  theme,
  to,
  type = 'button',
  variant,
}) => {
  function renderButton() {
    return (
      <button
        aria-label={text}
        data-testid={dataTestid}
        disabled={disabled}
        onClick={onClick}
        title={text}
        type={type}
        className={clsx(
          'button',
          `button--${variant}`,
          `button--size-${size}`,
          `button--color-${color}`,
          `button--border-radius-${borderRadius}`,
          `button--icon-position-${iconPosition}`,
          {
            'button--color-only-icon': onlyIcon,
            [`button--theme-${theme}`]: theme,
          },
          className
        )}
      >
        {loading ? (
          <div className="button__spinner">
            <CircularProgress color="#fff" size={23} />
          </div>
        ) : (
          <>
            {
              <div className="button__content">
                {icon && <Icon color={disabled ? 'disabled' : iconColor} fontSize={iconSize} htmlColor={iconHtmlColor} type={icon} />}
                {!onlyIcon && <div className="button__text">{text}</div>}
              </div>
            }
          </>
        )}
      </button>
    );
  }

  return to ? <Link to={to}>{renderButton()}</Link> : renderButton();
};

Button.propTypes = {
  borderRadius: PropTypes.oneOf(['default', 'big', 'small']),
  color: PropTypes.oneOf([
    'black',
    'border',
    'error',
    'grey',
    'info',
    'orange',
    'primary',
    'primary-outlined',
    'success',
    'transparent',
    'transparent-primary',
    'warning',
    'white',
  ]),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.oneOf(['inherit', 'action', 'disabled', 'primary', 'secondary', 'error']),
  iconHtmlColor: PropTypes.string,
  iconPosition: PropTypes.oneOf(['default', 'left', 'right']),
  iconSize: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
  size: PropTypes.oneOf(['default', 'small', 'xs', 'large']),
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  theme: PropTypes.oneOf(['default', 'google']),
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  variant: PropTypes.oneOf(['default', 'inline']),
};

Button.defaultProps = {
  borderRadius: 'default',
  color: 'primary',
  disabled: false,
  iconColor: 'inherit',
  iconPosition: 'default',
  iconSize: 'small',
  loading: false,
  size: 'default',
  text: 'Title',
  theme: 'default',
  type: 'button',
  variant: 'default',
};

export default Button;
