import { createProgram, updateProgram } from 'app/crud/programs.crud';
import { getMetadataArray } from 'app/services/metadata.service';
import store from 'app/store/store';
import i18next from 'app/translations/i18n';
import { getDomainTenant } from 'app/utils/url';
import axios from 'axios';

export async function createProgramService(data) {
  const response = await createProgram(getDataGeneral(data));
  const { guid } = response.data.data;

  return { guid };
}

export function updateService(data) {
  return updateProgram(getDataUpdate(data));
}

// ---------------------------------------------------------------
// Auxiliary functions
// ---------------------------------------------------------------

function getDataGeneral(data) {
  const font = store.getState()?.entities?.typographyProgram?.typography[0];

  return {
    body_typeface_guid: font?.guid,
    colour_mint: '#ed7011',
    country_guid: data?.country?.value || undefined,
    description: data?.description || '',
    education_discipline_guid: data?.discipline?.value,
    education_year_guid: data?.educationYears?.value,
    evaluation_period: data?.evaluation_period,
    heading_typeface_guid: font?.guid,
    is_accessible: data.is_accessible ? 1 : 0,
    isbn_student: data?.isbn_student || null,
    isbn_teacher: data?.isbn_teacher || null,
    json_config: data?.templateConfig || '{}',
    langs: getMetadataArray(data, 'langs'),
    name: data?.name,
    publication_guid: data?.publication_guid,
    structure_levels: data?.structure_levels,
    template_type: data?.template?.value,
  };
}

function getDataUpdate(data) {
  return {
    background: data?.background === '' ? '' : undefined,
    body_typeface_guid: data?.body_typeface_guid,
    colour_mint: data?.colour_mint,
    config: data?.config || '',
    country_guid: data?.country?.value || undefined,
    description: data?.description || '',
    education_discipline_guid: data?.discipline?.value,
    education_year_guid: data?.educationYears?.value,
    evaluation_period: data?.evaluation_period,
    guid: data?.guid,
    heading_typeface_guid: data?.heading_typeface_guid,
    is_accessible: data?.is_accessible ? 1 : 0,
    isbn_student: data?.isbn_student || null,
    isbn_teacher: data?.isbn_teacher || null,
    json_config: data?.templateConfig || '{}',
    langs: getMetadataArray(data, 'langs'),
    name: data?.name,
    picture: data?.picture === '' ? '' : undefined,
    program_cover: data?.program_cover === '' ? '' : undefined,
    publication_guid: data?.publication_guid,
    structure_levels: data?.structure_levels,
    subtitle: data?.subtitle,
    template_type: data?.template?.value,
  };
}

// ---------------------------------------------------------------
// ACCESS FRONTOFFICE
// ---------------------------------------------------------------
export function openProgramFrontOffice({ user, relativeUrl, tenant, program }) {
  const URL = getDomainTenant({ tenant, user: { ...user, country_guid: program?.country_guid || program?.country?.value } });

  return new Promise(async (resolve) => {
    const response = await axios.post(`users/${user.guid}/login`, { data: {} });
    const data = response?.data?.data;

    window.open(`${URL}auth/login/${data?.token}/course&${relativeUrl}`);
    resolve(data);
  });
}

export function editProgram({ program, user, tenant }) {
  openProgramFrontOffice({ user, relativeUrl: `${program.guid}&program`, tenant, program });
}

export function editProgramLesson({ program, lessonGuid, user, tenant }) {
  openProgramFrontOffice({ user, relativeUrl: `${program.guid}&lesson&${lessonGuid}`, tenant, program });
}

// ---------------------------------------------------------------
// GENERAL
// ---------------------------------------------------------------
export const TRIMESTERS = [
  { guid: '{"trimestre_guid":"1"}', name: i18next.t('digitalPrograms:trimester_1') },
  { guid: '{"trimestre_guid":"2"}', name: i18next.t('digitalPrograms:trimester_2') },
  { guid: '{"trimestre_guid":"3"}', name: i18next.t('digitalPrograms:trimester_3') },
];

export const RESOURCE_TREASURE_HUNT = {
  treasure_hunt: [
    { name: '', guid: '' },
    { name: '', guid: '' },
    { name: '', guid: '' },
  ],
};

export const TEMPLATES_DEFAULT = [{ template_type: 'vanilla', description: 'Standard template of Tangerine' }];

export function getTemplate() {
  const TEMPLATES = store.getState().entities.templates.templates;
  return TEMPLATES.length ? TEMPLATES : TEMPLATES_DEFAULT;
}

export const PERIODS = [
  { guid: 'anual', evalutaion_period: 'anual', name: i18next.t('dates:annual') },
  { guid: 's01', evalutaion_period: 'semestral', name: i18next.t('dates:semestral', { number: 1 }) },
  { guid: 's02', evalutaion_period: 'semestral', name: i18next.t('dates:semestral', { number: 2 }) },
  { guid: 'q01', evalutaion_period: 'quatrimestral', name: i18next.t('dates:quatrimestral', { number: 1 }) },
  { guid: 'q02', evalutaion_period: 'quatrimestral', name: i18next.t('dates:quatrimestral', { number: 2 }) },
  { guid: 'q03', evalutaion_period: 'quatrimestral', name: i18next.t('dates:quatrimestral', { number: 3 }) },
  { guid: 't01', evalutaion_period: 'trimestral', name: i18next.t('dates:trimestral', { number: 1 }) },
  { guid: 't02', evalutaion_period: 'trimestral', name: i18next.t('dates:trimestral', { number: 2 }) },
  { guid: 't03', evalutaion_period: 'trimestral', name: i18next.t('dates:trimestral', { number: 3 }) },
  { guid: 'b01', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 1 }) },
  { guid: 'b02', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 2 }) },
  { guid: 'b03', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 3 }) },
  { guid: 'b04', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 4 }) },
  { guid: 'b05', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 5 }) },
  { guid: 'b06', evalutaion_period: 'bimensual', name: i18next.t('dates:bimonthly', { number: 6 }) },
];

export const EVALUATION_PERIOD = [
  { guid: 'bimensual', name: i18next.t('dates:bi_monthly') },
  { guid: 'trimestral', name: i18next.t('dates:three_monthly') },
  { guid: 'semestral', name: i18next.t('dates:semestral') },
  // { guid: 'quatrimestral', name: i18next.t('dates:four_monthly') },
  // { guid: 'anual', name: i18next.t('dates:annual') },
];
