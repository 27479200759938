import useFeatureFlag from 'app/hooks/useFeatureFlag';
import { FEATURE_FLAGS } from 'app/services/featureFlag.service';

function useFeatureFlagRoutes() {
  const { value: showLemonadeAiFeedback } = useFeatureFlag(FEATURE_FLAGS.SHOW_LEMONADE_FEEDBACK_GENERATOR);
  const { value: showMessagesGlobal } = useFeatureFlag(FEATURE_FLAGS.SHOW_MESSAGES_GLOBAL);
  const { value: showAmplitude } = useFeatureFlag(FEATURE_FLAGS.SHOW_AMPLITUDE);
  const { value: showAnalyticsGame } = useFeatureFlag(FEATURE_FLAGS.SHOW_ANALYTICS_GAME);

  return {
    showAmplitude: showAmplitude === undefined ? true : showAmplitude,
    showAnalyticsGame: showAnalyticsGame === undefined ? true : showAnalyticsGame,
    showMessagesGlobal,
    showLemonadeAiFeedback,
  };
}

export default useFeatureFlagRoutes;
