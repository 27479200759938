import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { REDUCER_APP } from '../conf';

export const actionTypes = {
  SetActions: 'cms/SET_APP',
};

const initialState = {
  loading: false,
};

export const reducer = persistReducer({ storage, key: REDUCER_APP, whitelist: ['app'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SetActions: {
      return { ...state, loading: action.payload };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLoading: (state) => {
    return state[REDUCER_APP]?.loading;
  },
};

export const actions = {
  setLoading: (params) => ({ type: actionTypes.SetActions, payload: params }),
};
