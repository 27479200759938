import React from 'react';

const SettingsCountriesPage = React.lazy(() => import('../pages/settings/SettingsCountriesPage/SettingsCountriesPage'));
const SettingsCountryPage = React.lazy(() => import('../pages/settings/SettingsCountryPage/SettingsCountryPage'));
const SettingsLearningObjectivesPage = React.lazy(() => import('../pages/settings/SettingsLearningObjectivesPage/SettingsLearningObjectivesPage'));
const SettingsGeneralPage = React.lazy(() => import('../pages/settings/SettingsGeneralPage/SettingsGeneralPage'));
const SettingsCategoriesPage = React.lazy(() => import('../pages/settings/SettingsEvaluableCategoriesPage/SettingsEvaluableCategoriesPage'));
const SettingsPeriodsPage = React.lazy(() => import('../pages/settings/SettingsEvaluationPeriodsPage/SettingsEvaluationPeriodsPage'));
const SettingsScalesPage = React.lazy(() => import('../pages/settings/SettingsScalesPage/SettingsScalesPage'));

export const PATH_SETTINGS = '/settings';
export const PATH_SETTINGS_COUNTRIES = '/settings-countries';
export const PATH_SETTINGS_COUNTRY = '/settings-country/:guid';
export const PATH_SETTINGS_LEARNING_OBJECTIVES = '/settings-learning-objectives';
export const PATH_SETTINGS_CATEGORIES = '/settings-categories';
export const PATH_SETTINGS_PERIODS = '/settings-periods';
export const PATH_SETTINGS_SCALES = '/settings-scales';

const PAGE_SETTINGS = {
  component: SettingsGeneralPage,
  path: PATH_SETTINGS,
};

const PAGE_SETTINGS_COUNTRIES = {
  component: SettingsCountriesPage,
  path: PATH_SETTINGS_COUNTRIES,
};

const PAGE_SETTINGS_COUNTRY = {
  component: SettingsCountryPage,
  path: PATH_SETTINGS_COUNTRY,
};

const PAGE_LEARNING_OBJECTIVES = {
  component: SettingsLearningObjectivesPage,
  path: PATH_SETTINGS_LEARNING_OBJECTIVES,
};

const PAGE_SETTINGS_CATEGORIES = {
  component: SettingsCategoriesPage,
  path: PATH_SETTINGS_CATEGORIES,
};

const PAGE_SETTINGS_PERIODS = {
  component: SettingsPeriodsPage,
  path: PATH_SETTINGS_PERIODS,
};

const PAGE_SETTINGS_SCALES = {
  component: SettingsScalesPage,
  path: PATH_SETTINGS_SCALES,
};

// IMPORTANT: The order in which the pages are added is important
export default [
  PAGE_SETTINGS,
  PAGE_SETTINGS_COUNTRY,
  PAGE_SETTINGS_COUNTRIES,
  PAGE_LEARNING_OBJECTIVES,
  PAGE_SETTINGS_CATEGORIES,
  PAGE_SETTINGS_SCALES,
  PAGE_SETTINGS_PERIODS,
];
