import React from 'react';

function UIText() {
  return (
    <div className="d-flex flex-column">
      <h1>TEXT</h1>

      <span className="text-title">.text-title</span>
      <span className="text-title-strong">.text-title-strong</span>
      <span className="text-title-gray">.text-title-gray</span>
      <span className="text-title-14">.text-title-14</span>

      <span className="text-description mt-4">.text-description</span>
      <span className="text-subtitle">.text-subtitle</span>
      <span className="text-subtitle-gray">.text-subtitle-gray</span>

      <span className="text-strong mt-4">.text-strong</span>
      <span className="text-uppercase">.text-uppercase</span>
      <span className="text-span">.text-span</span>

      <span className="text-html mt-4">.text-html</span>

      <span className="mb-5"></span>
    </div>
  );
}

export default UIText;
