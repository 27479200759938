import Icon from '__core/modules/atoms/Icons/Icon';
import DialogDefault from '__core/modules/components/dialogs/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function DialogInfo({ buttonTextCancel, buttonTextSucess, className, description, descriptionSecond, onClose, onSuccess, open, setOpen, title }) {
  const { t } = useTranslation(['actions', 'dialogs']);

  function onCloseModal() {
    setOpen(false);
  }

  return (
    <DialogDefault
      buttonTextCancel={buttonTextCancel ? buttonTextCancel : t('actions:Cancel')}
      buttonTextSucess={buttonTextSucess ? buttonTextSucess : t('actions:accept')}
      className={`dialog-info ${className}`}
      hideHeader
      onCancel={setOpen ? onCloseModal : onClose}
      onClose={setOpen ? onCloseModal : onClose}
      onSuccess={onSuccess}
      open={open}
    >
      <div className="d-flex align-items-center">
        <Icon type="icon-info-circle" fontSize="large" color="primary" />
        <span className="dialog-info__title ml-3">{title}</span>
      </div>

      <p className="dialog-info__description">{description}</p>
      {descriptionSecond && <p className="dialog-info__description">{descriptionSecond}</p>}
    </DialogDefault>
  );
}

DialogInfo.propTypes = {
  buttonTextCancel: PropTypes.string,
  buttonTextSucess: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.node,
  descriptionSecond: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.node,
};

DialogInfo.defaultProps = {
  setOpen: undefined,
};

export default DialogInfo;
