//import axios from '../../api/axios';
import axios from 'axios';

function viewerApi(data, _callback) {
  switch (data.type) {
    case 'GET_CONTENTS':
      var CONTENT;
      const params = { content_guid: data.content_guid };
      try {
        // GET del CONTEÚDO
        axios
          .get(`cms/contents/${data.content_guid}`)
          .then((res) => {
            //_callback(res.data.data);

            CONTENT = res.data.data;
            CONTENT.type = CONTENT['mime-type'] == null || CONTENT['mime-type'] == '' ? CONTENT['type_guid'] : CONTENT['mime-type'];
            CONTENT.comments = [];
            CONTENT.playlists = [];
            CONTENT.playlist_groups = [];
            CONTENT.reviews = [];
            CONTENT.courses = [];
            CONTENT.questions = [];
            CONTENT.usefull = 0;
            CONTENT.difficult = 0;
            CONTENT.annotations = [];

            if (CONTENT.type_guid === 'CTTY_20') {
              if (!data.studentGuid) {
                _callback(CONTENT);
              } else {
                const params_post = {
                  content_guid: data.content_guid,
                  student_guid: data.studentGuid,
                };

                axios
                  .post(`lrs/xapi-state`, params_post)
                  .then((result_xapi_state) => {
                    if (result_xapi_state && result_xapi_state.data && result_xapi_state.data.data && result_xapi_state.data.data.ghostUser) {
                      CONTENT.ghostUser = result_xapi_state.data.data.ghostUser;
                    } else {
                      CONTENT.ghostUser = null;
                    }

                    _callback(CONTENT);
                  })
                  .catch((error) => {
                    console.log(error);
                    _callback(CONTENT);
                  });
              }
            } else {
              axios
                .get(`cms/contents/${data.content_guid}/annotations?includeWithoutParent=true`)
                .then((result_annotations) => {
                  CONTENT.annotations = result_annotations.data.data;
                  _callback(CONTENT);
                })
                .catch((error) => {
                  console.log(error);
                  //_callback(error);
                  _callback(CONTENT);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_PUBLIC_CONTENTS':
      //var CONTENT;
      //const params_bb = { content_guid: data.content_guid };
      try {
        // GET del CONTEÚDO
        axios
          .get(`front/public-viewer/${data.content_guid}`)
          .then((res) => {
            //_callback(res.data.data);

            CONTENT = res.data.data;
            CONTENT.type = CONTENT['mime-type'] == null || CONTENT['mime-type'] == '' ? CONTENT['type_guid'] : CONTENT['mime-type'];
            CONTENT.comments = [];
            CONTENT.playlists = [];
            CONTENT.playlist_groups = [];
            CONTENT.reviews = [];
            CONTENT.courses = [];
            CONTENT.questions = [];
            CONTENT.usefull = 0;
            CONTENT.difficult = 0;
            CONTENT.annotations = [];

            _callback(CONTENT);

            /*axios.get(`cms/contents/${data.content_guid}/annotations`).then(result_annotations => {
						CONTENT.annotations = result_annotations.data.data;
						_callback(CONTENT);
					}).catch(error => {
						console.log(error);
						//_callback(error);
						_callback(CONTENT);
					});*/
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_COMMON_ELEMENTS':
      var CONTENT = {};
      const params_ = { content_guid: data.content_guid };
      try {
        CONTENT.comments = [];
        CONTENT.playlists = [];
        CONTENT.playlist_groups = [];
        CONTENT.reviews = [];
        CONTENT.courses = [];
        CONTENT.questions = [];
        CONTENT.usefull = 0;
        CONTENT.difficult = 0;

        //_callback(CONTENT);

        // GET de ELEMENTOS COMUNES
        axios
          .get(`cms/contents/${params_.content_guid}/common-elements`, { params_ })
          .then((common_elements) => {
            CONTENT.comments = common_elements.data.data.comments;
            CONTENT.playlists = common_elements.data.data.playlists;
            CONTENT.reviews = common_elements.data.data.reviews;

            if (CONTENT.reviews.length > 0) {
              CONTENT.usefull = common_elements.data.data.reviews[0].usefull_rating;
              CONTENT.difficult = common_elements.data.data.reviews[0].difficult_rating;
            }

            // GET de PLAYLIST GROUPS
            axios
              .get(`cms/contents/playlist-groups`)
              .then((playlist_groups) => {
                CONTENT.playlist_groups = playlist_groups.data.data;

                // GET de COURSES
                axios
                  .get(`front/courses`)
                  .then((_courses) => {
                    try {
                      CONTENT.courses = _courses.data.data || [];
                    } catch (e) {
                      CONTENT.courses = [];
                    }

                    _callback(CONTENT);
                  })
                  .catch((error) => {
                    console.log(error);
                    _callback(error);
                  });
              })
              .catch((error) => {
                console.log(error);
                _callback(error);
              });
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_ANNOTATIONS_PAGES':
      try {
        axios
          .get(`cms/contents/${data.contentGuid}/annotations`, { params: { parent: data.parent } })
          .then((annots) => {
            _callback(annots);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_ANNOTATION':
      try {
        const params_post_annotation = {
          guid: data.params.guid,
          annotation: data.params.annotation,
          parent_guid: data.params.parent,
        };

        axios
          .post(`cms/contents/${data.params.content_guid}/annotations`, params_post_annotation)
          .then((result_annotation) => {
            _callback(result_annotation);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'PUT_ANNOTATION':
      try {
        const params_put_annotation = {
          guid: data.params.guid,
          annotation: data.params.annotation,
          parent_guid: data.params.parent,
        };

        axios
          .put(`cms/contents/${data.params.content_guid}/annotations/${data.params.guid}`, params_put_annotation)
          .then((result_annotation) => {
            _callback(result_annotation);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'DELETE_ANNOTATION':
      try {
        if (typeof data.params.guid == 'string') {
          data.params.guid = [String(data.params.guid)];
        }

        const params_delete_annotation = { data: { guid: data.params.guid } };

        axios
          .delete(`cms/contents/${data.params.content_guid}/annotations`, params_delete_annotation)
          .then((result_annotation) => {
            _callback(result_annotation);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'INIT_SESION_CONSUMPTION':
      try {
        axios
          .post('consumptions', data.params)
          .then((res) => {
            _callback(res.data.data);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'UPDATE_CONSUMPTION':
      try {
        // Consulta a servicio para CERRAR la SESIÓN DEL CONSUMO
        axios
          .post('consumptions/' + data.params.consumption_guid + '/progress', data.params)
          .then((res) => {
            _callback(res.data.data);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'CLOSE_SESION_CONSUMPTION':
      try {
        const params = {
          consumption_guid: data.consumption_guid,
          guid: data.session_guid,
          ended_at: Date.now(),
        };

        // Consulta a servicio para CERRAR la SESIÓN DEL CONSUMO
        axios
          .put('consumptions/' + data.consumption_guid + '/sessions/' + data.session_guid, params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'SEND_EVALUATE_CONTENT':
      try {
        const params = {
          usefull_rating: parseInt(data.params.usefull_rating),
          difficult_rating: parseInt(data.params.difficult_rating),
          content_guid: data.params.content_guid,
        };

        axios
          .post('/cms/contents/' + params.content_guid + '/reviews', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'SEND_REPORT_CONTENT':
      try {
        const params = {
          type: data.params.type,
          comment: data.params.comment,
          content_guid: data.params.content_guid,
        };

        axios
          .post('/cms/contents/' + params.content_guid + '/reports', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'SEND_REPORT_CONTENT_ERROR':
      const params_report = {
        type: data.params.type,
        comment: data.params.comment,
        content_guid: data.params.content_guid,
      };

      try {
        axios
          .post('cms/contents/' + data.params.content_guid + '/reports', params_report)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback('error');
      }

      break;

    case 'GET_REPORT_CONTENT_ERROR':
      try {
        axios
          .get(`/cms/contents/reports`, { params: { content: Array(String(data.params.content_guid)) } })
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }

      break;
    case 'POST_PLAYLIST_GROUP':
      try {
        const params = {
          title: data.params.title,
          thumbnail: data.params.thumbnail,
          description: data.params.description,
        };

        axios
          .post('cms/contents/playlist-groups', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'DELETE_PLAYLIST_GROUP':
      try {
        const params = {
          guid: data.params.guid,
        };

        axios
          .delete('cms/contents/playlist-groups/' + params.guid, params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_CONTENT_IN_PLAYLIST':
      try {
        const params = {
          content_guid: data.params.content_guid,
          show_at: data.params.show_at,
          group_guid: data.params.group_guid,
        };

        axios
          .post('cms/contents/' + params.content_guid + '/playlists', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'PUT_CONTENT_IN_PLAYLIST':
      try {
        const params = {
          content_guid: data.params.content_guid,
          show_at: data.params.show_at,
          group_guid: data.params.group_guid,
          guid: data.params.guid,
        };

        axios
          .put('cms/contents/' + params.content_guid + '/playlists/' + params.guid, params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'DELETE_CONTENT_OF_PLAYLIST':
      try {
        const params = {
          content_guid: data.params.content_guid,
          guid: data.params.guid,
        };

        axios
          .delete('cms/contents/' + params.content_guid + '/playlists/' + params.guid, params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_COURSE':
      try {
        const params = {
          name: data.params.name,
        };

        axios
          .post('lms/courses', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_CONTENT_COMMENT':
      try {
        var params_all = {};

        if (data.params.shared_type == 'courses') {
          params_all = {
            shared_type: data.params.shared_type,
            comment: data.params.comment,
            content_guid: data.params.content_guid,
            show_at: data.params.show_at,
            shared_courses: data.params.shared_courses,
          };
        } else {
          params_all = {
            shared_type: data.params.shared_type,
            comment: data.params.comment,
            content_guid: data.params.content_guid,
            show_at: data.params.show_at,
          };
        }
        if (data.params.parent_guid != null) {
          params_all.parent_guid = data.params.parent_guid;
        }

        axios
          .post('/cms/contents/' + params_all.content_guid + '/comments', params_all)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'PUT_CONTENT_COMMENT':
      try {
        var params_all = {};

        if (data.params.shared_type == 'courses') {
          params_all = {
            guid: data.params.guid,
            shared_type: data.params.shared_type,
            comment: data.params.comment,
            content_guid: data.params.content_guid,
            show_at: data.params.show_at,
            shared_courses: data.params.shared_courses,
          };
        } else {
          params_all = {
            guid: data.params.guid,
            shared_type: data.params.shared_type,
            comment: data.params.comment,
            content_guid: data.params.content_guid,
            show_at: data.params.show_at,
          };
        }
        if (data.params.parent_guid != null) {
          params_all.parent_guid = data.params.parent_guid;
        }

        axios
          .put('/cms/contents/' + params_all.content_guid + '/comments/' + params_all.guid, params_all)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'DELETE_CONTENT_COMMENT':
      try {
        const params = {
          guid: data.params.guid,
          content_guid: data.params.content_guid,
        };

        axios
          .delete('/cms/contents/' + params.content_guid + '/comments/' + params.guid, params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_COURSE_OF_COMMENT':
      try {
        const params = {
          //content_guid: _data.content_guid,
          //comment_guid: _data.comment_guid,
          course: data.params.course_guid,
        };

        axios
          .post('/cms/contents/' + data.params.content_guid + '/comments/' + data.params.comment_guid + '/courses', params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'DELETE_COURSE_OF_COMMENT':
      try {
        axios
          .delete('/cms/contents/' + data.params.content_guid + '/comments/' + data.params.comment_guid + '/courses/' + data.params.course_guid)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_METASCRAPER':
      try {
        axios
          .get(`cms/metascraper?url=` + data.url)
          .then((_result) => {
            if (_result.error) {
              _callback(_result);
            } else {
              _callback(_result.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_EXERCISE_TRACE':
      console.log('POST_EXERCISE_TRACE');
      console.log(data.params);
      try {
        axios
          .post('/lrs/records', data.params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }

      break;
    case 'GET_EXERCISE_TRACE':
      console.log('GET_EXERCISE_TRACE');
      console.log(data.params);

      try {
        axios
          .get(`/lrs/records`, { params: data.params })
          .then((_result) => {
            _callback(_result.data);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_USERS_ACCESS_TOKEN':
      try {
        axios
          .get(`users/access-token`)
          .then((_result) => {
            _callback(_result.data);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }

      break;
    case 'GET_LANGS':
      try {
        axios
          .get(`langs/scopes`, { params: data.params })
          .then((_result) => {
            _callback(_result.data);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'POST_LANGS':
      console.log(data.params);
      try {
        axios
          .post('langs/scopes', data.params)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'PUT_LANGS':
      try {
        const params_lang = { json: data.params.json };
        axios
          .put('langs/scopes/' + data.params.scopeId, params_lang)
          .then((res) => {
            _callback(res);
          })
          .catch((error) => {
            console.log(error);
            _callback(error);
          });
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
    case 'GET_LANG':
      //var CONTENT;
      //const params = { content_guid: data.content_guid };
      var _lang = {};
      try {
        setTimeout(function () {
          // var _lang = {
          //   VISOR_MESSAJE_WARNING: 'Atención',
          //   VISOR_MESSAJE_OK: 'Correcto',
          //   VISOR_LOADING_LABEL_TYPE_FIXED_LAYOUT: 'Livro Digital Fixed Layout',
          //   VISOR_LOADING_LABEL_TYPE_MINT_BOOK: 'Livro Digital Responsive',
          //   VISOR_LOADING_LABEL_TYPE_VIDEO: 'Video',
          //   VISOR_LOADING_LABEL_TYPE_AUDIO: 'Audio',
          //   VISOR_LOADING_LABEL_TYPE_PDF: 'PDF',
          //   VISOR_LOADING_LABEL_TYPE_LINK: 'Link',
          //   VISOR_LOADING_LABEL_TYPE_IMAGE: 'Imagem',
          //   VISOR_LOADING_LABEL_TYPE_HTML_INTERACTIVE: 'OED',
          //   VISOR_LOADING_LABEL_TYPE_TEST: 'TEST',
          //   VISOR_LOADING_LABEL_TYPE_SCORM: 'SCORM',
          //   VISOR_LOADING_LABEL_TYPE_SWF: 'SWF',
          //   VISOR_LOADING_LABEL_TYPE_OFFICE: 'OFFICE',
          //   VISOR_LOADING_LABEL_TYPE_QUESTION: 'Question',
          //   VISOR_LOADING_LABEL_TYPE_HTML_PAGE: 'Página HTML',
          //   VISOR_PLAYLIST_NEW_NOT_GROUP_ASIGNED: 'El marcador no tiene grupo asignado',
          //   VISOR_PLAYLIST_NEW_CREATED_OK: 'Marcador creado correctamente.',
          //   VISOR_PLAYLIST_NEW_CREATED_ERROR: 'No se pudo crear marcador.',
          //   VISOR_PLAYLIST_UPDATE_OK: 'Marcador actualizado correctamente.',
          //   VISOR_PLAYLIST_UPDATE_ERROR: 'Marcador no se pudo actualizado correctamente.',
          //   VISOR_PLAYLIST_DELETE_OK: 'Marcador eliminado correctamente.',
          //   VISOR_PLAYLIST_DELETE_ERROR: 'No se pudo eliminar marcador.',
          //   VISOR_PLAYLIST_GROUP_NEW_OK: 'Grupo creado correctamente.',
          //   VISOR_PLAYLIST_GROUP_NEW_ERROR: 'Nos e pudo crear grupo.',
          //   VISOR_PLAYLIST_GROUP_DELETE_OK: 'Grupo eliminado correctamente.',
          //   VISOR_PLAYLIST_GROUP_DELETE_ERROR: 'No se pudo eliminar grupo.',
          //   VISOR_COMMENT_NEW_OK: 'Comentario creado correctamente.',
          //   VISOR_COMMENT_NEW_ERROR: 'No se pudo crear comentario.',
          //   VISOR_COMMENT_UPDATE_OK: 'Comentario modificado correctamente.',
          //   VISOR_COMMENT_UPDATE_ERROR: 'No se pudo modificar comentario.',
          //   VISOR_COMMENT_DELETE_OK: 'Comentario eliminado correctamente.',
          //   VISOR_COMMENT_DELETE_ERROR: 'Error al eliminar comentario.',
          //   VISOR_EVALUATE_OK: 'Conteúdo evaluado correctamente.',
          //   VISOR_EVALUATE_ERROR: 'No se pudo evaluar o contúdo',
          //   VISOR_REPORT_OK: 'Reporte enviado correctamente.',
          //   VISOR_REPORT_ERROR: 'No se pudo enviar reporte.',
          //   VISOR_DATA_QUESTION_NOT_FOUND: 'Erro, informações da Question não localizadas.',
          //   VISOR_DATA_ACTION_NOT_POSSIBLE: 'Atualmente, não é possível executar esta ação.',
          //   VISOR_FILE_HAVE_ERROR: 'Erro de conteúdo.',
          //   VISOR_FILE_NOT_FOUND: 'Erro, conteúdo não localizado.',
          //   VISOR_FILE_TYPE_UNDEFINED: 'Erro, tipo de conteúdo não identificado.',
          //   VISOR_ERROR_404: 'Error 404. Content not found.',
          //   VISOR_ERROR_403: 'Error 403. Access forbiden.',
          //   VISOR_LIB_MENU_ADD_PLAYLIST: 'Adicionar lista',
          //   VISOR_LIB_MENU_MY_PLAYLIST: 'Minhas listas',
          //   VISOR_LIB_MENU_EVALUATE_CONTENT: 'Avaliar conteúdo',
          //   VISOR_LIB_MENU_RE_EVALUATE_CONTENT: 'Requalificar',
          //   VISOR_LIB_MENU_REPORT_CONTENT: 'Reportar erro',
          //   VISOR_LIB_COMMENTS_CANCELAR: 'CANCELAR',
          //   VISOR_LIB_COMMENTS_EDITAR: 'Editar',
          //   VISOR_LIB_COMMENTS_DELETE: 'Remover',
          //   VISOR_LIB_COMMENTS_RESPONSE: 'Responder',
          //   VISOR_LIB_COMMENTS_SAVE_NEW_GROUP: 'SALVAR NOVO GRUPO',
          //   VISOR_LIB_COMMENTS_NEW_GROUP: 'CRIAR NOVO GRUPO',
          //   VISOR_LIB_COMMENTS_NEW_GROUP_PLACEHOLDER: 'Novo grupo...',
          //   VISOR_LIB_COMMENTS_TERMINAR: 'TERMINAR',
          //   VISOR_LIB_COMMENTS_CLASS_GROUP: 'Grupos de classe',
          //   VISOR_LIB_COMMENTS_CLASS_GROUP_EMPTY: 'Não há grupos de turma.',
          //   VISOR_LIB_COMMENTS_COMMENT_WRITE: 'Escrever comentário',
          //   VISOR_LIB_COMMENTS_SHARE_TO: 'Compartilhar com',
          //   VISOR_LIB_COMMENTS_EMPTY: 'Ainda não há comentários.',
          //   VISOR_LIB_COMMENTS_SHARE_ALL: 'Todos(as)',
          //   VISOR_LIB_COMMENTS_SHARE_PRIVATE: 'Privado',
          //   VISOR_LIB_COMMENTS_SHARE_ONLY_TEACHERS: 'Apenas professores(as)',
          //   VISOR_LIB_COMMENTS_SHARE_STUDENTS: 'Estudantes das minhas aulas',
          //   VISOR_LIB_COMMENTS_SELECT_GROUP: 'Selecione o grupo de turma',
          //   VISOR_LIB_PLAYLIST_GROUP_TITLE: 'Lista de favoritos',
          //   VISOR_LIB_PLAYLIST_GROUP_PLACEHOLDER: 'Nome da lista',
          //   VISOR_LIB_PLAYLIST_GROUP_EMPTY: 'Nenhum grupo de lista',
          //   VISOR_LIB_PLAYLIST_GROUP_SAVE_NEW: 'Criar',
          //   VISOR_LIB_PLAYLIST_GROUP_CREATE_NEW: '+ Criar nova lista',
          //   VISOR_LIB_PLAYLIST_SAVE_NEW: 'Criar',
          //   VISOR_LIB_PLAYLIST_INSTANT: 'Começa em',
          //   VISOR_LIB_PLAYLIST_IN: 'Lista em',
          //   VISOR_LIB_PLAYLIST_DELETE: 'Excluir',
          //   VISOR_LIB_PLAYLIST_CREATED: 'Listas criadas',
          //   VISOR_LIB_PLAYLIST_EMPTY: 'Ainda não há listas criadas',
          //   VISOR_LIB_ERRORS_DESCRIPTION: 'Descreva o problema (opcional)',
          //   VISOR_LIB_ERRORS_ERROR_CONTENT: 'Erro no conteúdo',
          //   VISOR_LIB_ERRORS_INAPROPIATE: 'Conteúdo inadequado',
          //   VISOR_LIB_ERRORS_RAZON: 'O que deu errado?',
          //   VISOR_LIB_ERRORS_REPORT_PROBLEM: 'Relatar um problema',
          //   VISOR_LIB_EVALUATE_DIFFICULT: 'Qual o seu nível de dificuldade durante os estudos?',
          //   VISOR_LIB_EVALUATE_UTIL: 'Qual o seu nível de satisfação com o conteúdo?',
          //   VISOR_LIB_EVALUATE_TITLE: 'Avalie o conteúdo que você estudou',
          //   VISOR_LIB_EVALUATE_SAVE: 'Enviar',
          //   VISOR_LIB_PDF_PLAYERS: 'Players',
          //   VISOR_LIB_PDF_PLAYERS_MESSAGE_FILE_NOT_FOUND: 'Recurso não encontrado',
          //   VISOR_LIB_PDF_PLAYERS_MESSAGE_FILE_NOT_FOUND_CLOSE: 'Fechar',
          //   VISOR_LIB_VIEWER_LINK_BT: 'VEJA O LINK',
          //   VISOR_LIB_PDFTRON_CONTEUDO_ESPECIAL_PAGE: 'Página',
          //   VISOR_LIB_PDFTRON_APPLY: 'Aplique',
          //   VISOR_LIB_PDFTRON_APPLYALL: 'Aplicar tudo',
          //   VISOR_LIB_PDFTRON_CANCEL: 'Cancelar',
          //   VISOR_LIB_PDFTRON_CLEAR: 'Limpar',
          //   VISOR_LIB_PDFTRON_CLOSE: 'Fechar',
          //   VISOR_LIB_PDFTRON_COMMENT: 'Comente',
          //   VISOR_LIB_PDFTRON_COPY: 'Cópia',
          //   VISOR_LIB_PDFTRON_DELETE: 'Remover',
          //   VISOR_LIB_PDFTRON_DOWNLOAD: 'Baixar',
          //   VISOR_LIB_PDFTRON_EDIT: 'Editar',
          //   VISOR_LIB_PDFTRON_ENTERFULLSCREEN: 'Tela cheia',
          //   VISOR_LIB_PDFTRON_EXITFULLSCREEN: 'Sair da tela cheia',
          //   VISOR_LIB_PDFTRON_FIT: 'Em forma',
          //   VISOR_LIB_PDFTRON_FITTOPAGE: 'Ajustar à página',
          //   VISOR_LIB_PDFTRON_FITTOWIDTH: 'Ajustar à largura',
          //   VISOR_LIB_PDFTRON_OPENFILE: 'Abrir arquivo',
          //   VISOR_LIB_PDFTRON_PRINT: 'Impressão',
          //   VISOR_LIB_PDFTRON_OK: 'OK',
          //   VISOR_LIB_PDFTRON_REPLY: 'Resposta',
          //   VISOR_LIB_PDFTRON_ROTATE: 'Rodar',
          //   VISOR_LIB_PDFTRON_ROTATECLOCKWISE: 'Sentido horário',
          //   VISOR_LIB_PDFTRON_ROTATECOUNTERCLOCKWISE: 'Sentido anti-horário',
          //   VISOR_LIB_PDFTRON_SAVE: 'Salve',
          //   VISOR_LIB_PDFTRON_CREATE: 'Criar',
          //   VISOR_LIB_PDFTRON_SHOWMORERESULTS: 'Mostrar mais resultados',
          //   VISOR_LIB_PDFTRON_SAVESIGNATURE: 'Salvar assinatura',
          //   VISOR_LIB_PDFTRON_SIGN: 'Placa',
          //   VISOR_LIB_PDFTRON_STYLE: 'Estilo',
          //   VISOR_LIB_PDFTRON_SUBMIT: 'Enviar',
          //   VISOR_LIB_PDFTRON_ZOOM: 'Zoom',
          //   VISOR_LIB_PDFTRON_ZOOMIN: 'Mais Zoom',
          //   VISOR_LIB_PDFTRON_ZOOMOUT: 'Reduzir o zoom',
          //   VISOR_LIB_PDFTRON_AREAMEASUREMENT: 'Medição de área',
          //   VISOR_LIB_PDFTRON_ARROW: 'Seta',
          //   VISOR_LIB_PDFTRON_CALLOUT: 'Chamar',
          //   VISOR_LIB_PDFTRON_CARET: 'Caret',
          //   VISOR_LIB_PDFTRON_DISTANCEMEASUREMENT: 'Medição de distância',
          //   VISOR_LIB_PDFTRON_ELLIPSE: 'Elipse',
          //   VISOR_LIB_PDFTRON_FREEHAND: 'Mão livre',
          //   VISOR_LIB_PDFTRON_FREEHAND2: 'Mão livre',
          //   VISOR_LIB_PDFTRON_FREETEXT: 'Texto livre',
          //   VISOR_LIB_PDFTRON_FREETEXT2: 'Texto livre',
          //   VISOR_LIB_PDFTRON_HIGHLIGHT: 'Realçar',
          //   VISOR_LIB_PDFTRON_HIGHLIGHT2: 'Realçar',
          //   VISOR_LIB_PDFTRON_LINE: 'Linha',
          //   VISOR_LIB_PDFTRON_PERIMETERMEASUREMENT: 'Medição de perímetro',
          //   VISOR_LIB_PDFTRON_POLYGON: 'Polígono',
          //   VISOR_LIB_PDFTRON_POLYGONCLOUD: 'Nuvem',
          //   VISOR_LIB_PDFTRON_POLYLINE: 'Polilinha',
          //   VISOR_LIB_PDFTRON_RECTANGLE: 'Retângulo',
          //   VISOR_LIB_PDFTRON_REDACT: 'Redigir',
          //   VISOR_LIB_PDFTRON_SIGNATURE: 'Assinatura',
          //   VISOR_LIB_PDFTRON_SQUIGGLY: 'Ondulado',
          //   VISOR_LIB_PDFTRON_STAMP: 'Carimbo',
          //   VISOR_LIB_PDFTRON_STICKYNOTE: 'Comente',
          //   VISOR_LIB_PDFTRON_STRIKEOUT: 'Tachado',
          //   VISOR_LIB_PDFTRON_UNDERLINE: 'Sublinhado',
          //   VISOR_LIB_PDFTRON_CUSTOM: 'Personalizados',
          //   VISOR_LIB_PDFTRON_CUSTOM_CONTEUDO_ESPECIAL: 'Conteúdo especial',
          //   VISOR_LIB_PDFTRON_FREEHANDTOOLSBUTTON: 'Ferramentas à mão livre',
          //   VISOR_LIB_PDFTRON_LEFTPANEL: 'Painel',
          //   VISOR_LIB_PDFTRON_SEARCHOVERLAY: 'Procurar',
          //   VISOR_LIB_PDFTRON_MENUOVERLAY: 'Cardápio',
          //   VISOR_LIB_PDFTRON_MISCTOOLSBUTTON: 'Ferramentas diversas',
          //   VISOR_LIB_PDFTRON_NOTESPANEL: 'Notas',
          //   VISOR_LIB_PDFTRON_OUTLINESPANEL: 'Sumário',
          //   VISOR_LIB_PDFTRON_SHAPETOOLSBUTTON: 'Inserir Formas',
          //   VISOR_LIB_PDFTRON_TEXTTOOLSBUTTON: 'Ferramentas de texto',
          //   VISOR_LIB_PDFTRON_THUMBNAILSPANEL: 'Miniaturas',
          //   VISOR_LIB_PDFTRON_TOOLSBUTTON: 'Ferramentas',
          //   VISOR_LIB_PDFTRON_REDACTION: 'Redação',
          //   VISOR_LIB_PDFTRON_VIEWCONTROLSOVERLAY: 'Vistas de páginas',
          //   VISOR_LIB_PDFTRON_MEASUREMENTTOOLSBUTTON: 'Ferramentas de medição',
          //   VISOR_LIB_PDFTRON_BADDOCUMENT: 'Falha ao carregar o documento. O documento está corrompido ou não é válido.',
          //   VISOR_LIB_PDFTRON_CUSTOMPRINTPLACEHOLDER: 'e.g. 3, 4-10',
          //   VISOR_LIB_PDFTRON_ENCRYPTEDATTEMPTSEXCEEDED: 'Falha ao carregar o documento criptografado. Muitas tentativas.',
          //   VISOR_LIB_PDFTRON_ENCRYPTEDUSERCANCELLED: 'Falha ao carregar o documento criptografado. Entrada de senha cancelada.',
          //   VISOR_LIB_PDFTRON_ENTERPASSWORD: 'Este documento é protegido por senha. Por favor insira uma senha',
          //   VISOR_LIB_PDFTRON_INCORRECTPASSWORD: 'Senha incorreta, tentativas restantes: {{ remainingAttempts }}',
          //   VISOR_LIB_PDFTRON_NOANNOTATIONS: 'Este documento não possui anotações.',
          //   VISOR_LIB_PDFTRON_NOOUTLINES: 'Este documento não tem esboço.',
          //   VISOR_LIB_PDFTRON_NORESULTS: 'Nenhum resultado encontrado.',
          //   VISOR_LIB_PDFTRON_NOTSUPPORTED: 'Esse tipo de arquivo não é suportado.',
          //   VISOR_LIB_PDFTRON_PASSWORDREQUIRED: 'Senha requerida',
          //   VISOR_LIB_PDFTRON_PREPARINGTOPRINT: 'Preparando para imprimir...',
          //   VISOR_LIB_PDFTRON_PRINTTOTALPAGECOUNT: '{{count}} página',
          //   VISOR_LIB_PDFTRON_PRINTTOTALPAGECOUNT_PLURAL: '{{count}} Páginas',
          //   VISOR_LIB_PDFTRON_PROCESSING: 'Em processamento...',
          //   VISOR_LIB_PDFTRON_SEARCHING: 'Procurando...',
          //   VISOR_LIB_PDFTRON_SEARCHPLACEHOLDER: 'Pesquisar comentários...',
          //   VISOR_LIB_PDFTRON_SIGNHERE: 'Assine aqui',
          //   VISOR_LIB_PDFTRON_INSERTTEXTHERE: 'Introduzir texto aqui',
          //   VISOR_LIB_PDFTRON_ANNOTATIONCOLOR_STROKECOLOR: 'Cor da borda',
          //   VISOR_LIB_PDFTRON_ANNOTATIONCOLOR_FILLCOLOR: 'Cor de preenchimento',
          //   VISOR_LIB_PDFTRON_ANNOTATIONCOLOR_TEXTCOLOR: 'Cor do texto',
          //   VISOR_LIB_PDFTRON_DISPLAYMODE_LAYOUT: 'Exibição',
          //   VISOR_LIB_PDFTRON_DISPLAYMODE_PAGETRANSITION: 'Transição',
          //   VISOR_LIB_PDFTRON_LAYOUT_COVER: 'Tampa',
          //   VISOR_LIB_PDFTRON_LAYOUT_DOUBLE: 'Dobro',
          //   VISOR_LIB_PDFTRON_LAYOUT_SINGLE: 'Único',
          //   VISOR_LIB_PDFTRON_NOTESPANEL_ORDERPOSITION: 'Posição',
          //   VISOR_LIB_PDFTRON_NOTESPANEL_ORDERTIME: 'Tempo',
          //   VISOR_LIB_PDFTRON_NOTESPANEL_SEPARATOR_TODAY: 'Hoje',
          //   VISOR_LIB_PDFTRON_NOTESPANEL_SEPARATOR_YESTERDAY: 'Ontem',
          //   VISOR_LIB_PDFTRON_PAGETRANSITION_CONTINUOUS: 'Contínua',
          //   VISOR_LIB_PDFTRON_PAGETRANSITION_DEFAULT: 'Página por página',
          //   VISOR_LIB_PDFTRON_PRINT_ALL: 'Todas',
          //   VISOR_LIB_PDFTRON_PRINT_CURRENT: 'Atual',
          //   VISOR_LIB_PDFTRON_PRINT_PAGES: 'Páginas',
          //   VISOR_LIB_PDFTRON_PRINT_INCLUDECOMMENTS: 'Incluir comentários',
          //   VISOR_LIB_PDFTRON_REDACTION_MARKFORREDACTION: 'Marcar para redação',
          //   VISOR_LIB_PDFTRON_REDACTION_WARNINGPOPUPMESSAGE:
          //     'Esta ação removerá permanentemente todos os itens selecionados para edição. Não pode ser desfeito.',
          //   VISOR_LIB_PDFTRON_REDACTION_WARNINGPOPUPTITLE: 'Aplicar redação',
          //   VISOR_LIB_PDFTRON_SEARCHPANEL_CASESENSITIVE: 'Case sensitive',
          //   VISOR_LIB_PDFTRON_SEARCHPANEL_WHOLEWORDONLY: 'Palavra inteira',
          //   VISOR_LIB_PDFTRON_SIGNATUREOVERLAY_ADDSIGNATURE: 'Adicione uma assinatura',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_SCALE: 'Relação de escala',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_ANGLE: 'Ângulo',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_DISTANCE: 'Distância',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_PERIMETER: 'Perímetro',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_AREA: 'Área',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_DISTANCEMEASUREMENT: 'Medição de Distância',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_PERIMETERMEASUREMENT: 'Medição de perímetro',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOVERLAY_AREAMEASUREMENT: 'Medição de área',
          //   VISOR_LIB_PDFTRON_MEASUREMENTOPTION_SCALE: 'Escala',
          //   VISOR_LIB_PDFTRON_SLIDER_OPACITY: 'Opacidade',
          //   VISOR_LIB_PDFTRON_SLIDER_THICKNESS: 'Espessura',
          //   VISOR_LIB_PDFTRON_SLIDER_TEXT: 'Tamanho do texto',
          //   VISOR_LIB_PDFTRON_SHARED_PAGE: 'Página',
          //   VISOR_LIB_PDFTRON_SHARED_PRECISION: 'Precisão',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ARROW: '(A)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_CALLOUT: '(C)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_COPY: '(Ctrl C)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_DELETE: '(Del)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ELLIPSE: '(O)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_FREEHAND: '(F)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_FREETEXT: '(T)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_HIGHLIGHT: '(H)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_LINE: '(L)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_PAN: '(P)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_RECTANGLE: '(R)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ROTATECLOCKWISE: '(Ctrl Shift +)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ROTATECOUNTERCLOCKWISE: '(Ctrl Shift -)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_SELECT: '(Esc)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_SIGNATURE: '(S)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_SQUIGGLY: '(G)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_STAMP: '(I)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_STICKYNOTE: '(N)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_STRIKEOUT: '(K)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_UNDERLINE: '(U)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ZOOMIN: '(Ctrl +)',
          //   VISOR_LIB_PDFTRON_SHORTCUT_ZOOMOUT: '(Ctrl -)',
          //   VISOR_LIB_PDFTRON_TOOL_PAN: 'Mover',
          //   VISOR_LIB_PDFTRON_TOOL_SELECT: 'Selecionar',
          //   VISOR_LIB_PDFTRON_TOOL_MARQUEE: 'Marquee Zoom',
          //   VISOR_LIB_PDFTRON_TOOL_DRAWING: 'Desenhar',
          //   VISOR_LIB_PDFTRON_TOOL_UNDERLINE: 'Marcar',
          //   VISOR_LIB_READERMINT_LABELSET_NAME: 'Portuguese',
          //   VISOR_LIB_READERMINT_LABELSET_ISO639CODE: 'pt',
          //   result: 'Resultado (n)',
          //   search: 'Procura',
          //   results: 'Resultados',
          //   quizNext: 'SEGUINTE',
          //   tabGroup: 'Aba',
          //   codeGroup: 'Código',
          //   noResults: 'Nenhum resultado para ',
          //   noteGroup: 'Nota',
          //   quizScore: 'Suo resultado',
          //   quizStart: 'COMEÇAR A PROVA',
          //   courseExit: 'SAÍR DO CURSO',
          //   courseHome: 'Página inicial',
          //   lessonName: 'Lição',
          //   quizSubmit: 'Submeter',
          //   quoteGroup: 'Cita',
          //   salutation: 'Até logo!',
          //   buttonGroup: 'Botão',
          //   courseStart: 'INÍCIO',
          //   embedViewOn: 'VER EM',
          //   exitMessage: 'Pode agora abandonar esta página.',
          //   quizCorrect: 'Correto',
          //   quizPassing: 'APROVADO',
          //   quizResults: 'Resultados da prova',
          //   courseResume: 'RETOMAR O CURSO',
          //   processStart: 'INICIAR',
          //   processSwipe: 'Deslizar para continuar',
          //   quizContinue: 'Continuar',
          //   quizLandmark: 'Prova',
          //   quizQuestion: 'Pergunta',
          //   courseDetails: 'DETALHES',
          //   embedReadMore: 'Ler mais',
          //   feedbackGroup: 'Retroação',
          //   quizIncorrect: 'Incorreto',
          //   quizTakeAgain: 'Começar de novo',
          //   scenarioStart: 'COMEÇAR',
          //   sortingReplay: 'REPETIR',
          //   accordionGroup: 'Acordeão',
          //   embedLinkGroup: 'Conteúdo da web incorporado',
          //   lessonComplete: 'COMPLETAR',
          //   statementGroup: 'Declaração',
          //   storylineGroup: 'Trama',
          //   attachmentGroup: 'Ficheiro agregado',
          //   embedPhotoGroup: 'Foto incrustada',
          //   embedVideoGroup: 'Vídeo incrustado',
          //   playButtonLabel: 'Jogar',
          //   processComplete: 'Completado',
          //   processLandmark: 'Processo',
          //   processNextStep: 'SEGUINTE PASSO',
          //   processStepName: 'Passo',
          //   seekSliderLabel: 'Procurar',
          //   sortingLandmark: 'Atividade de classificação',
          //   audioPlayerGroup: 'Áudio leitor. Pode utilizar a barra de corte para alternar a reprudação e as setas para apagar. ',
          //   buttonStackGroup: 'Pilha de botões',
          //   embedPlayerGroup: 'Leitor multimídia incorporado',
          //   lessonRestricted: ' As liçoes têm de ser completadas em ordem',
          //   pauseButtonLabel: 'Pausa',
          //   scenarioComplete: 'Cenário completado!',
          //   scenarioContinue: 'CONTINUAR',
          //   scenarioTryAgain: 'TENTAR DE NOVO',
          //   textOnImageGroup: 'Texto em imagem',
          //   timelineLandmark: 'Cronologia',
          //   urlEmbedLandmark: 'URL/Incrustar',
          //   videoPlayerGroup: 'Vídeo leitor. Pode utilizar a barra de corte para alternar a reprudação e as setas para apagar. ',
          //   blocksClickToFlip: 'Fazer clique para virar',
          //   blocksPreposition: 'de',
          //   bulletedListGroup: 'Ponto lista',
          //   checkboxListGroup: 'Lista com caixa de seleção ',
          //   imageAndTextGroup: 'Imagem e texto',
          //   imageGalleryGroup: 'Galeria de imagens',
          //   lessonPreposition: 'de',
          //   numberedListGroup: 'Lista numerada',
          //   processLessonName: 'Lição',
          //   processStartAgain: 'COMEÇAR DE NOVO',
          //   scenarioStartOver: 'COMEÇAR DE NOVO',
          //   courseSkipToLesson: 'PASSAR À LIÇÃO',
          //   flashcardBackLabel: 'Cartão-relâmpago de regresso',
          //   flashcardGridGroup: 'Grelha de cartão de memória',
          //   nextFlashCardLabel: 'Cartão-relâmpago seguinte ',
          //   flashcardFrontLabel: 'Frente do cartão-relâmpago ',
          //   flashcardStackGroup: 'Pilha de cartões-relâmpago ',
          //   knowledgeCheckGroup: 'Verificação de conhecimento',
          //   sortingCardsCorrect: 'Cartão correto',
          //   hamburgerButtonLabel: 'Descrição geral do curso Barra lateral',
          //   lessonHeaderLandmark: 'Cabeçalho do lição',
          //   numberedDividerGroup: 'Divisor numerado',
          //   lessonContentLandmark: 'Conteúdo da lição',
          //   lessonSidebarLandmark: 'Barra lateral da lição',
          //   quizAnswerPlaceholder: 'Escreve a sua resposta aqui',
          //   labeledGraphicLandmark: 'Gráfico rotulado',
          //   previousFlashCardLabel: 'Cartão-relâmpago anterior',
          //   processStepPreposition: 'de',
          //   overviewPageTitleSuffix: 'Descrição ',
          //   quizAcceptableResponses: 'Respostas aceitáveis',
          //   quizRequirePassingScore: 'Tem de passar uma prova antes de continuar',
          //   timelineCardGroupPrefix: 'Cartão da linha do tempo',
          //   labeledGraphicBubbleLabel: 'Bolha gráfica rotulada',
          //   labeledGraphicMarkerLabel: 'Marcador gráfico rotulado',
          //   labeledGraphicNextMarkerLabel: 'Marcador seguinte',
          //   labeledGraphicPreviousMarkerLabel: 'Marcador anterior',
          //   highlight: 'Selecionar',
          //   removeHighlighted: 'Retirar a seleção',
          //   completed: 'Completado',
          //   textToSpeech: 'Texto a fala',
          //   saveBookmarks: 'Em mercadores',
          //   titleAdded: 'Incluir',
          //   home_book_index: 'Ver índice',
          //   home_title_aventura: 'Jogo',
          //   home_title_listas: 'Marcadores',
          //   home_title_actividades: 'Atividades ',
          //   home_title_multimedia: 'Multimídia',
          //   home_title_videolecciones: 'Vídeolições',
          //   home_title_mindmaps: 'Mapas mentais',
          //   home_title_teacher: 'Ferramenta do professor',
          //   home_title_gsuite: 'G Suite',
          //   home_create_new: '+ Criar de novo',
          //   home_add: '+ Incluir',
          //   home_game: 'Jogar',
          //   home_lists_resource: 'Recurso incluido',
          //   home_lists_resources: 'Recursos incluidos',
          //   home_teacher_module_evaluaciones: 'Gerador de testes',
          //   home_teacher_module_guia_didactica: 'Guia didactico',
          //   home_teacher_module_proyectos: 'Projetos',
          //   playlist_header_add: 'Criar de novo',
          //   playlist_header_resource_add: 'Incluir',
          //   playlist_card_copy: 'Copiar',
          //   playlist_card_share: 'Compartilhar',
          //   playlist_card_remove: 'Anular',
          //   playlist_create_empty_name: 'Escreve um nome, por favor',
          //   playlist_create_success: 'As mudanças foram salvas',
          //   playlist_create_fail: 'Não se pôde salvar',
          //   playlist_resource_to_playlist_error: 'Ainda não tem grupos de marcadores criados ',
          //   playlist_resource_to_playlist_title: 'Incluir recurso a marcadores',
          //   playlist_resource_to_playlist_button: 'Incluir',
          //   playlist_resource_to_playlist_success: 'Foi incluido corretamente',
          //   playlist_add_link_error_title: 'Escreve um título, por favor',
          //   playlist_add_link_invalid_title: 'Atenção',
          //   playlist_add_link_invalid_message: 'O URL proporcionado não é válido. Deseja continuar?',
          //   playlist_add_link_invalid_accept: 'Aceitar',
          //   playlist_add_link_invalid_cancel: 'Cancelar',
          //   playlist_resource_not_found: 'Recurso não encontrado',
          //   playlist_add_resource_title: 'Título do recurso',
          //   playlist_add_resource_url: 'Colar o URL',
          //   playlist_add_resource_button: 'Salvar',
          //   playlist_new_playlist_placeholder: 'Novo grupo de marcadores',
          //   playlist_share_widget_title: 'Compartilhar com a classe',
          //   playlist_share_widget_button: 'Compartilhar',
          //   playlist_remove_confirm_title: 'Atenção',
          //   playlist_remove_confirm_message: 'Tem a certeza que você deseja anular o grupo de marcadores?',
          //   playlist_remove_confirm_ok: 'Anular',
          //   playlist_remove_confirm_cancel: 'Cancelar',
          //   playlist_remove_resource_confirm_title: 'Atenção',
          //   playlist_remove_resource_confirm_message: 'Tem a certeza que você deseja o recurso "[TITLE]"?',
          //   playlist_remove_resource_confirm_ok: 'Anular',
          //   playlist_remove_resource_confirm_cancel: 'Cancelar',
          //   playlist_remove_success: 'Foi anulado corretamente',
          //   playlist_remove_fail: 'Não se pôde anular',
          //   playlist_copy_success: 'Cópia realizada com sucesso: "[NEW]"',
          //   playlist_copy_fail: 'Não se pôde copiar',
          //   activities_all: 'Todas as unidades',
          //   activities_found: 'Atividades encontradas',
          //   activities_no_title: 'Sem título',
          //   activities_punctuation: 'Sem pontuação',
          //   activities_act: 'Atividade',
          //   activities: 'Atividades',
          //   activities_no_result: 'Sem resultados',
          //   activities_done: 'Realizados',
          //   activities_not_done: 'Não realizados',
          //   activities_filter: 'Filtra pela nota das atividades',
          //   multimedia_section_title: 'Multimídia',
          //   multimedia_header_add: 'Incluir',
          //   multimedia_validation_missing_title: 'Escreve um título, por favor',
          //   multimedia_validation_missing_link: 'Insere um linque, por favor',
          //   multimedia_form_add_title_popup: 'Incluir multimídia',
          //   multimedia_form_add_unit: 'Unidade',
          //   multimedia_form_add_title: 'Título do recurso',
          //   multimedia_form_add_url: 'Colar o URL',
          //   multimedia_form_add_save: 'Salvar',
          //   multimedia_card_remove: 'Anular',
          //   multimedia_card_save: 'Salvar',
          //   multimedia_card_share: 'Compartilhar',
          //   multimedia_filter_interactive: 'Interativos',
          //   multimedia_filter_multimedia: 'Vídeos',
          //   multimedia_filterpopup_title: 'Filtrar',
          //   multimedia_filterpopup_button: 'Filtrar',
          //   multimedia_sharepopup_title: 'Compartilhar com a classe',
          //   multimedia_sharepopup_button: 'Compartilhar',
          //   multimedia_save_resource_invalid_title: 'Atenção',
          //   multimedia_save_resource_invalid_message: 'O URL proporcionado não é válido. Deseja continuar?',
          //   multimedia_save_resource_invalid_accept: 'Aceitar',
          //   multimedia_save_resource_invalid_decline: 'Cancelar',
          //   multimedia_save_resource_success: 'As mudanças foram salvas',
          //   multimedia_save_resource_fail: 'Não se pôde salvar',
          //   multimedia_remove_title: 'Atenção',
          //   multimedia_remove_message: 'Tem a certeza que você deseja anular a multimídia ',
          //   multimedia_remove_accept: 'Anular',
          //   multimedia_remove_decline: 'Cancelar',
          //   multimedia_remove_success: 'Foi anulado corretamente',
          //   multimedia_remove_fail: 'Não se pôde anular',
          //   multimedia_label_all_units: 'Todas as unidades',
          //   multimedia_label_filter: 'Filtrar',
          //   multimedia_x_resources: 'Há [AMOUNT] recursos',
          //   multimedia_x_resource: 'Há [AMOUNT] recurso',
          //   videolecciones_title_module: 'Vídeolíções',
          //   videolecciones_title_module_channels: 'Canais',
          //   videolecciones_all_units: 'Todas as unidades',
          //   videolecciones_create_new: 'Criar de novo',
          //   videolecciones_add_url: 'Incluir o URL',
          //   videolecciones_add_url_unit: 'Unidade',
          //   videolecciones_add_url_link: 'Linque',
          //   videolecciones_add_url_title: 'Incluir',
          //   videolecciones_add_url_text: 'https://www.youtube.com/watch?v=...',
          //   videolecciones_add_url_bt_save: 'Salvar',
          //   videolecciones_search_results_text: 'Vídeolíções encontradas',
          //   videolecciones_result_create_ok: 'Vídeolíção criada corretamente',
          //   videolecciones_result_create_error: 'Problema ao criar a vídeolíção',
          //   videolecciones_delete_title: 'Atenção',
          //   videolecciones_delete_messaje: 'Tem a certeza que você deseja anular esta vídeolíção',
          //   videolecciones_delete_bt_delete: 'Anular',
          //   videolecciones_delete_bt_cancel: 'Cancelar',
          //   videolecciones_delete_msj_ok: 'Vídeolíção eliminada corretamente',
          //   videolecciones_delete_msj_error: 'Error ao eliminar vídeolíção',
          //   videolecciones_problems_load: 'Problema ao recuperar as vídeolíções',
          //   videolecciones_bt_continue: 'Continuar',
          //   videolecciones_bt_response: 'Responder',
          //   videolecciones_must_complete_activity: 'Tem de preencher a actividade',
          //   videolecciones_title_feedback: 'Retroação',
          //   videolecciones_title_welcome: 'Bem-vind@!',
          //   videolecciones_text_welcome: 'Reproduz o vídeo para começar',
          //   videolecciones_title_multiple_choice: 'Resposta múltipla',
          //   videolecciones_title_open_question_write: 'Escreve a sua resposta',
          //   videolecciones_title_open_question: 'Pergunta aberta',
          //   videolecciones_title_open_attempt: 'Tentativa',
          //   videolecciones_open_question_you_responde: 'A sua resposta',
          //   videolecciones_list_button_copy: 'Copiar',
          //   videolecciones_list_button_share: 'Compartilhar',
          //   videolecciones_list_button_save: 'Salvar',
          //   videolecciones_list_button_edit: 'Editar',
          //   videolecciones_list_button_delete: 'Eliminar',
          //   videolecciones_new_title: 'Criarvídeolíção ',
          //   videolecciones_new_breadcrumb_a: 'Vídeolíções',
          //   videolecciones_new_breadcrumb_b: 'Nova vídeolíção ',
          //   videolecciones_new_card_right_title: 'Escolher vídeo',
          //   videolecciones_new_card_right_subtitle: 'Incluir um URL do vídeo o procura entre nossos canais',
          //   videolecciones_new_link_search: 'Procurar vídeos',
          //   videolecciones_new_link_add_video: 'Incluir o URL vídeo',
          //   videolecciones_channel: 'Canais',
          //   videolecciones_channel_breadcrumb_a: 'Vídeolíções',
          //   videolecciones_channel_breadcrumb_b: 'Procurar vídeo ',
          //   videolecciones_channel_search: 'Procurar vídeo',
          //   videolecciones_channel_preview_title: 'Pré-visualizar vídeo',
          //   videolecciones_edition_title: 'Editar vídeolíção ',
          //   videolecciones_edition_breadcrumb_b: 'Edição',
          //   videolecciones_editor_title: 'Começamos!',
          //   videolecciones_editor_subtitle: 'Escolhe uma opção para editar a vídeolíção ',
          //   videolecciones_editor_crop_video: 'Recortar vídeo',
          //   videolecciones_editor_voiceover: 'Sobreposição de voz',
          //   videolecciones_editor_audionotes: 'Notas de áudio',
          //   videolecciones_editor_quizzes: 'Provas',
          //   videolecciones_editor_save: 'SALVAR',
          //   videolecciones_editor_cancel: 'CANCELAR',
          //   videolecciones_editor_save_messaje_ok: 'Corretamente salvo',
          //   videolecciones_editor_save_messaje_error: 'Erro ao salvar',
          //   videolecciones_editor_error_localization: 'Erro, não se pôde localizar a vídeolíção ',
          //   videolecciones_new_add_video: 'Incluir o URL vídeo',
          //   videolecciones_new_right_title: 'Nova vídeolíção ',
          //   videolecciones_new_right_subtitle: 'Inclue um URL de um vídeo de YouTube para editar uma vídeolíção ',
          //   videolecciones_new_error_create: 'Erro ao criar a vídeolíção ',
          //   videolecciones_new_error_request_info_youtube: 'Erro ao recuperar informação desde YouTube',
          //   videolecciones_new_error_request_info_youtube_incorrect: 'Erro ao recuperar informação desde YouTube. Comprova que o URL é válido e correto',
          //   videolecciones_new_error_other: 'Erro, verifica o console do navegador para mais detalhes',
          //   videolecciones_new_idbook_not_defined: 'Não se pode continuar porque o ID de Livro não está definido',
          //   videolecciones_editor_cropvideo_title: 'Recortar vídeo',
          //   videolecciones_editor_cropvideo_subtitle: 'Aquí poderá escolher um intervalo de tempo que será a duração da vídeolição',
          //   videolecciones_editor_edit: 'Editar',
          //   videolecciones_editor_delete: 'Anular',
          //   videolecciones_editor_stop: 'Parar',
          //   videolecciones_editor_play: 'Jogar',
          //   videolecciones_editor_voiceover_title: 'Sobreposição de voz',
          //   videolecciones_editor_voiceover_subtitle:
          //     'Realiza uma gravação de áudio. <br /> Lembra que a gravação se realizará urante a totalidade da duração da vídeolição',
          //   videolecciones_editor_voiceover_record: 'Gravar',
          //   videolecciones_editor_voiceover_recording: 'Gravando...',
          //   videolecciones_editor_voiceover_play: 'Reproduzir',
          //   videolecciones_editor_voiceover_remove: 'Eliminar áudio',
          //   videolecciones_editor_audionote_title: 'Nota de áudio',
          //   videolecciones_editor_audionote_subtitle: ' Realiza notas de áudio breves para enriquecer à vídeolição',
          //   videolecciones_editor_audionote_insert: 'Começar nota de áudio',
          //   videolecciones_editor_audionote_end: 'Terminar nota de áudio',
          //   videolecciones_editor_audionote_edit: 'Reproduz o elimina esta nota de áudio',
          //   videolecciones_editor_quizzes_title: 'Provas',
          //   videolecciones_editor_quizzes_subtitle: 'Inclue um comentário, uma pergunta de resposta aberta o uma atividade de seleção múltipla',
          //   videolecciones_editor_quizzes_edit: 'Edita o elimina esta Prova',
          //   videolecciones_editor_quizzes_add_comment: 'Incluir comentário',
          //   videolecciones_editor_quizzes_add_multiplechoice: 'Incluir seleção múltipla',
          //   videolecciones_editor_quizzes_add_openquestion: 'Incluir pergunta aberta',
          //   videolecciones_editor_quizzes_comment_title: 'Incluir comentário',
          //   videolecciones_editor_quizzes_comment_subtitle: 'Comentário:',
          //   videolecciones_editor_quizzes_comment_placeholder: 'Comentário:',
          //   videolecciones_editor_quizzes_multiplechoice_title: 'Seleção múltipla',
          //   videolecciones_editor_quizzes_multiplechoice_title_label: 'Pergunta:',
          //   videolecciones_editor_quizzes_multiplechoice_title_placeholder: 'Pergunta...',
          //   videolecciones_editor_quizzes_multiplechoice_response_label: 'Respostas:',
          //   videolecciones_editor_quizzes_multiplechoice_add_more_checkbox: 'Incluir resposta',
          //   videolecciones_editor_quizzes_multiplechoice_add_more_response: 'Incluir uma outra resposta',
          //   videolecciones_editor_quizzes_multiplechoice_option_true: 'Verdadeiro',
          //   videolecciones_editor_quizzes_multiplechoice_option_false: 'Falso',
          //   videolecciones_editor_quizzes_openq_title: 'Pergunta de resposta aberta',
          //   videolecciones_editor_quizzes_openq_label_question: 'Pergunta:',
          //   videolecciones_editor_quizzes_openq_label_question_place: 'Pergunta...',
          //   videolecciones_editor_quizzes_openq_label_response: 'Retroação',
          //   videolecciones_editor_quizzes_openq_label_response_place: 'Retroação',
          //   mindmap_saveDraft: 'Salvar rascunho',
          //   mindmap_name: 'Escreve aqui o nome do mapa',
          //   mindmap_remove_message: 'Tem a certeza que você deseja anular?',
          //   mindmap_new_map: 'Novo mapa mental',
          //   mindmap: 'Mapas mentais',
          //   mindmap_reset: 'Tem a certeza que você deseja anular todo?',
          //   mindmap_found_plural: 'mapas mentais',
          //   mindmap_found_singular: 'mapa mental',
          //   mindmap_nomaps: 'Não há mapas mentais',
          //   mindmap_add_resource_button: 'Salvar',
          //   mindmap_sharepopup_title: 'Compartilhar com a classe',
          //   mindmap_sharepopup_button: 'Compartilhar',
          //   mindmap_add: 'Incluir',
          //   mindmap_how_this_work: 'Como funciona',
          //   mindmap_add_video: 'Incluir vídeo',
          //   mindmap_find: 'Procurar',
          //   mindmap_restart: 'Reiniciar',
          //   mindmap_save: 'Salvar mapa mental',
          //   mindmap_remove_filter: 'Eliminar filtro',
          //   mindmap_help: 'Ajuda',
          //   mindmap_help2: 'Dá um duplo clique em qualquer lugar do fundo',
          //   mindmap_name_error: 'Inclue um nome para o mapa, por favor',
          //   guide: 'Guia didactico',
          //   exam_generator_title: 'Gerador de testes',
          //   exam_evaluations: 'Gerador de testes',
          //   exam_max_questions: '20;',
          //   exam_newEvaluation: 'Novo teste',
          //   exam_savedEvaluation: 'Testes salvos',
          //   exam_unitSelection: 'Seleção de unidades',
          //   exam_questionsSelection: 'Seleção de perguntas',
          //   exam_personalise: 'Personalizar teste',
          //   exam_generate: 'Gerar teste',
          //   exam_chooseUnits: '',
          //   exam_testStep2: 'Inclue perguntas e continua. ',
          //   exam_testStep3: 'Ordena, edita, cria e elimina perguntas',
          //   exam_testStep4:
          //     "<ol class='numerate'><li> Dê um duplo clique para imprimir o teste diretamente. </li><li> Dê um clique em ‘Salva’ para aceder o teste numa data posterior. </li><li> Para salvar o teste como documento de Word no suo computador, dê um clique em ‘Salvar por palavra’",
          //   exam_save_test: 'Salve a prova, selecione a versão do professor/aluno para imprimir o volte a <strong> Personalizar teste </strong>',
          //   exam_next: 'Seguinte',
          //   exam_select: 'Selecionar',
          //   exam_unSelect: 'Desselecionar',
          //   exam_addAll: 'Todos',
          //   exam_add: 'Incluir',
          //   exam_tooltip_interaction_view: 'Ver',
          //   exam_tooltip_interaction_close: 'Fechar',
          //   exam_questions: 'Pergunta Nº',
          //   exam_questions2: 'Perguntas',
          //   exam_description: 'Descrição',
          //   exam_unitNumber: 'Unidade Nº',
          //   exam_unit: 'Unidade',
          //   exam_drawing_borrar: 'Anular',
          //   exam_name: 'Nome ',
          //   exam_solution: 'Solução',
          //   exam_information: 'Informação de ensino',
          //   exam_tables: 'Tabela',
          //   exam_write: 'Escreva a pergunta',
          //   exam_max_questions_info: 'O número máximo de perguntas por teste é:',
          //   exam_button_teacher: 'Versão do professor',
          //   exam_button_alumn: 'Versão do aluno',
          //   exam_button_add: 'Incluir pergunta',
          //   exam_button_save: 'Salvar',
          //   exam_button_print: 'Imprimir',
          //   exam_button_saveWord: 'Salvar para Word',
          //   exam_button_saveDrive: 'Salvar em Google Drive',
          //   exam_save_message: 'Teste salvo',
          //   exam_adverteisment: 'Aviso importante',
          //   exam_saveEvaluation: 'Salvar teste',
          //   exam_reset_test: 'Reiniciar b',
          //   exam_button_show_json: 'Mostrar JSON',
          //   exam_button_save_json: 'Salvar',
          //   exam_button_save_doc: 'Salvar para Word',
          //   exam_continue: 'Continuar',
          //   exam_accept: 'Aceitar',
          //   exam_wordCompatibility:
          //     "O documento gerado é compatível com Word 2007. Pode baixar um módulo de compatibilidade. Dê um clique em <a href='https://www.microsoft.com/es-es/download/details.aspx?id=12439' target='_blank'>Aquí</a>",
          //   exam_header_name: 'Nome do teste',
          //   exam_header_surname: 'Sobrenome',
          //   exam_header_date: 'Data e hora',
          //   exam_header_course: 'Curso',
          //   exam_writeHere: 'Escreve o nome aqui',
          //   exam_creator: 'Proprietário',
          //   exam_action: 'Ação',
          //   exam_noTestSaved: 'Não há testes salvos',
          //   exam_writeSolution: 'Escreve solução',
          //   exam_attempts: 'Tentativas.',
          //   exam_score: 'Pontuação:',
          //   exam_download: 'Baixar',
          //   exam_close: 'Fechar',
          //   exam_attachments: 'Arquvios anexos',
          //   exam_addAttachments: 'Incluir arquivo',
          //   exam_gDriveHelp: 'Para incluir imagens u arquivos, dê clique no botão',
          //   exam_clickDrive: 'Ctrl + clique para abrir documento',
          //   exam_confirm: 'Comprova?',
          //   bookprojects_title: 'Projetos',
          //   bookprojects_bt_add: 'Incluir',
          //   bookprojects_find_result: 'Resultado encontrado',
          //   bookprojects_find_results: 'Resultados encontrados',
          //   bookprojects_option_edit: 'Editar',
          //   bookprojects_option_share: 'Compartilhar',
          //   bookprojects_option_delete: 'Anular',
          //   bookprojects_option_playlist: 'Salvar',
          //   bookprojects_option_copy: 'Copiar',
          //   bookprojects_addnew: 'Incluir',
          //   bookprojects_addnew_units: 'Unidades',
          //   bookprojects_addnew_title: 'Título',
          //   bookprojects_addnew_title_placeholder: 'Novo projeto...',
          //   bookprojects_addnew_save: 'Salvar',
          //   bookprojects_delete_title: 'Atenção',
          //   bookprojects_delete_messaje: 'Tem a certeza que você deseja anular este projeto?',
          //   bookprojects_delete_bt_delete: 'Anular',
          //   bookprojects_delete_bt_cancel: 'Cancelar',
          //   bookprojects_delete_ok: 'Projeto eliminado corretamente',
          //   bookprojects_delete_error: 'Problemas ao eliminar projeto',
          //   bookprojects_saved_ok: 'Projeto salvo corretamente',
          //   bookprojects_saved_error: 'Erro ao salvar projeto',
          //   bookprojects_breadcrumb_a: 'Projetos',
          //   bookprojects_breadcrumb_b: 'Visualizar',
          //   bookprojects_breadcrumb_c: 'Edição',
          //   bookprojects_copyed_title: 'Cópia de',
          //   bookprojects_closeandsave_title: 'Atenção',
          //   bookprojects_closeandsave_msj: 'Deseja salvar as mudanças antes de sair?',
          //   bookprojects_closeandsave_save: 'Salvar',
          //   bookprojects_closeandsave_cancel: 'Cancelar',
          //   bookprojects_disable_block: 'Inativar bloco',
          //   bookprojects_enable_block: 'Ativar bloco',
          //   bookprojects_change_image: 'Mudar imagem',
          //   bookprojects_save_title: 'Salvar as mudanças no projeto',
          //   bookprojects_save: 'Salvar',
          //   bookprojects_cancel: 'Cancelar',
          //   bookprojects_file_not_fount: 'Atenção! Arquivo não localizado:',
          //   exerciseCorrect: 'Correto',
          //   exerciseIncorrect: 'Errado',
          //   exerciseScore: 'Pontuação',
          //   exerciseValidate: 'Validar',
          //   exerciseRestart: 'Reiniciar A',
          //   visorAddNote: 'Incluir nota',
          //   visorEditNote: 'Editar nota',
          //   visorAddNoteHint: 'Escreve a nota...',
          //   visorSave: 'Salvar',
          //   visorPublishedOn: 'Publicado em',
          //   visorShareWidgetTitle: 'Compartilhar nota',
          //   visorButtonSaveShareWidget: 'Aceitar',
          //   visorDeleteNotePopupTilte: 'Tem a certeza?',
          //   visorDeleteNotePopupBody: 'Se você apagá-lo, não poderá o recuperar.',
          //   visorDeleteNotePopupCancel: 'Cancelar',
          //   visorDeleteNotePopupDelete: 'Anular',
          //   visorDeleteNotePopupSuccess: 'Eliminada',
          //   visorAddCommentNoteHint: 'Inclue um comentário',
          //   visorButtonCommentNote: 'Comentar',
          //   visorNoteInfoNoComment: 'Sê o primeriro em comentar!',
          //   visorNoteInfoOneComment: 'Há um comentário na nota',
          //   visorNoteInfoMoreOneCommentPart1: 'Há',
          //   visorNoteInfoMoreOneCommentPart2: 'Comentários na nota',
          //   visorTitleListNotesPagePopup: 'Lista de notas',
          //   visorButtonAddedAddNote: 'Nota',
          //   visorEditColorHighlighted: 'Editar a cor',
          //   visorButtonAddedGoogleClassroom: 'google Classroom',
          //   visorButtonAddedCreateMindmap: 'Criar mapa mental',
          //   visorSelectTextMenuCreateMindmap: 'Criar mapa mental',
          //   visorFileAttachmentBlock: 'Bloco de arquivo anexo',
          //   visorNoFileAdded: 'Sem arquivo anexo',
          //   visorSearchCancel: 'Cancelar',
          //   visorSearchHint: 'Procurar',
          //   visorSearchRecentSearches: 'Procura recente',
          //   visorSearchResults: 'Resultados de pesquisa',
          //   visorContextualMenuSearch: 'Procurar',
          //   visorSearchWithoutResults: 'Sem resultados',
          //   visorShowGlossary: 'See glossary',
          //   visorGlossaryHintWord: 'Glossary word',
          //   visorGlossaryHintDefinition: 'Definition',
          //   visorGlossaryTitle: 'Glossary',
          //   visorGlossaryDeleteWordSuccess: 'Deleted',
          //   visorButtonAddedAddWordGlossary: 'word to the glossary',
          //   playlist_save_ok: 'As mudanças foram salvas',
          //   playlist_save_not_ok: 'Não se pôde salvar',
          //   playlist_without_resources: 'Nenhum recurso adicionado',
          //   playlist_single_resources: 'Um recurso',
          //   playlist_many_resources: 'Há % recursos',
          //   playlist_without_playlists: 'Sem marcadores',
          //   playlist_single_playlist: 'Um marcador',
          //   playlist_many_playlists: 'Há % marcadores',
          //   playlist_save_changes: 'Salvar alterações nos marcardores',
          //   playlist_save_changes_cancel: 'Anular',
          //   playlist_save_changes_save: 'Salvar',
          //   playlist_edit: 'Editar',
          //   multimedia_card_edit: 'Edit',
          //   multimedia_form_edit_title_popup: 'Edit multimedia',
          //   bookprojects_no_results: 'There are no saved projects. To start click on "+ add"',
          // };

          _callback(_lang);
        }, 100);

        //window.ocviewerlang.visor_report_ok

        /*// GET del CONTEÚDO
				axios.get(`cms/contents/${data.content_guid}`).then(res => {
					//_callback(res.data.data);
					
					CONTENT = res.data.data;
					
				}).catch(error => {
					console.log(error);
					_callback(error);
				});*/
      } catch (error) {
        console.log(error);
        _callback(error);
      }
      break;
  }
}

export async function getAllAnnotations(contentGuid) {
  return await axios.get(`cms/contents/${contentGuid}/annotations`);
}

export async function deleteAnnotationOne(contentGuid, annotationGuid) {
  // return new Promise(function (resolve, reject) {setTimeout(() => {resolve({data: {status: 'success',data: {guid: [annotationGuid],},},status: 200,});},100);});
  return await axios.delete(`cms/contents/${contentGuid}/annotations`, {
    data: {
      guid: [annotationGuid],
    },
  });
}

export default viewerApi;
