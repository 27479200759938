export function applyFonts(typographies) {
  typographies.forEach((typography) => {
    const { fonts, name } = typography;

    setFont(fonts);
    setFontCss({ fonts, name });
  });
}

function setFont(fonts) {
  const headElement = document.head;

  fonts.forEach((font) => {
    const fontLink = document.createElement('link');
    fontLink.rel = 'stylesheet';
    fontLink.type = 'font/woff';
    fontLink.href = font.src;

    headElement.appendChild(fontLink);
  });
}

function setFontCss({ fonts, name }) {
  const style = document.createElement('style');
  style.innerHTML = getInnerHTML(fonts, name);
  document.head.appendChild(style);
}

function getInnerHTML(fonts, name) {
  let css = '';

  fonts.forEach((element) => {
    const font = `
    @font-face {
      font-family: '${name}';
      src: url('${element.src}');
      font-weight: ${element.weight};
      font-style: ${element.style};
    }`;

    css = `${css} 
      ${font}`;
  });

  return css;
}
