export const dateRegExp = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])(?:( [0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;

export const requiredBy = function (ref, msg) {
  return this.test({
    name: 'requiredBy',
    exclusive: false,
    message: msg || '${path} es requerido por ${reference}',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      if (!this.resolve(ref)) {
        return true;
      }

      return !!this.resolve(ref) && !!value;
    },
  });
};

export const requiredIf = function (ref, name, msg) {
  return this.test({
    name: 'requiredIf',
    exclusive: false,
    message: msg || '${path} es requerido por ${reference}',
    params: {
      reference: ref,
    },
    test: function (value) {
      if (this.resolve(ref) !== name) {
        return true;
      }

      return !!value;
    },
  });
};

export const requiredIfDistinct = function (ref, name, msg) {
  return this.test({
    name: 'requiredIfDistinct',
    exclusive: false,
    message: msg || '${path} es requerido por ${reference}',
    params: {
      reference: ref,
    },

    test: function (value) {
      if (this.resolve(ref) === name) {
        return true;
      }

      return !!value;
    },
  });
};

export const minTwoValue = function (refMax, refMin, msg) {
  return this.test({
    name: 'minTwoValue',
    exclusive: false,
    message: msg || '${path} debe ser mayor que ${reference}',
    params: {
      reference: refMax.path,
    },
    test: function (value) {
      if (value < refMin) {
        return false;
      }

      return !value < this.resolve(refMax);
    },
  });
};
