const API_URL_ENV = process.env.REACT_APP_URL_API;
const BASE_DEV = process.env.REACT_APP_URL_BASE_API;
const BASE_AGNOSTIC = window.location.origin.includes('localhost') ? BASE_DEV : window.location.origin;

const firstDotIndex = BASE_AGNOSTIC.indexOf('.');
const BASE_URL = API_URL_ENV ? API_URL_ENV : `https://${BASE_AGNOSTIC.substring(firstDotIndex + 1)}/`;

export const defaultConfig = {
  API_URL: BASE_URL + 'api/',
  BASE_STORAGE_URL: BASE_URL + 'files-storage-tools/',
  BASE_URL_: BASE_URL,
  DOMAIN: BASE_AGNOSTIC,
};
