import React from 'react';

const CollectionCreatePage = React.lazy(() => import('../pages/collections/CollectionCreatePage/CollectionCreatePage'));
const CollectionsPage = React.lazy(() => import('../pages/collections/CollectionsPage/CollectionsPage'));
const CollectionDetailPage = React.lazy(() => import('../pages/collections/CollectionDetailPage/CollectionDetailPage'));

export const PATH_COLLECTION = '/collection/:guid';
export const PATH_COLLECTIONS = '/collections';
export const PATH_COLLECTION_CREATE = '/collection/create';

// -------------------------------------------
//  COLLECTIONS
// -------------------------------------------

const PAGE_COLLECTION_CREATE = {
  component: CollectionCreatePage,
  path: PATH_COLLECTION_CREATE,
};

const PAGE_COLLECTION = {
  component: CollectionDetailPage,
  path: PATH_COLLECTION,
};

const PAGE_COLLECTIONS = {
  component: CollectionsPage,
  path: PATH_COLLECTIONS,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_COLLECTION_CREATE, PAGE_COLLECTION, PAGE_COLLECTIONS];
