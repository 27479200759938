import viewerApi from '../api/viewerApi';

export function hasValidatedExerciseInternal(_this, _data, _callback) {
  // Preparndo DATA para POST
  _data.content_guid = String(_this.state.data.content_guid);
  var __data = { params: _data, type: 'POST_EXERCISE_TRACE' };
  viewerApi(__data, function (e) {
    _callback(e);
  });
}

export function getResponseExerciseInternal(_this, _data, _callback) {
  var _data_send = {};
  _data_send.contentGuid = Array(_this.state.data.content_guid);
  _data_send.referenceId = Array(_data.iditem);
  _data_send.scope = Array('viewer');

  var __data = { params: _data_send, type: 'GET_EXERCISE_TRACE' };

  viewerApi(__data, function (e) {
    if (typeof e === 'string') {
      _callback(true, '');
    } else {
      try {
        _callback(false, JSON.parse(e.data[0].data));
      } catch (e) {
        _callback(true, '');
      }
    }
  });
}
