import React, { Component } from 'react';
import Icon from '@material-ui/core/Icon';

class ToastViewers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: '',
      message: '',
      type: 'correct',
    };

    this.closeTheToast = this.closeTheToast.bind(this);
  }

  closeTheToast = e => {
    this.props.closeToast();
  };

  render() {
    var _status = false;
    try {
      _status = this.props.open;
    } catch (e) {}

    if (_status) {
      setTimeout(
        function() {
          this.props.closeToast();
        }.bind(this),
        4000
      );
    }

    var icon =
      this.props.data.type === 'error' ? (
        <Icon> close </Icon>
      ) : this.props.data.type === 'info' ? (
        <Icon> error_outline </Icon>
      ) : (
        <Icon> done </Icon>
      );
    var type = this.props.data.type === 'error' ? 'error' : this.props.data.type === 'info' ? 'info' : 'correct';

    return (
      <div className={`toastviewers ${_status ? 'toastviewers_show' : 'toastviewers_hide'} ${type}`}>
        <div className="toastviewers_icon"> {icon} </div>
        <div className="toastviewers_title"> {this.props.data.title} </div>
        <div className="toastviewers_message"> {this.props.data.messaje} </div>
        <div className="toastviewers_close" onClick={this.closeTheToast}>
          {' '}
          <Icon> close </Icon>{' '}
        </div>
      </div>
    );
  }
}

export default ToastViewers;
