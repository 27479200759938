import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

function Dropdown({ anchorEl, open, children, handleClose, className, placement, style }) {
  return (
    <div className="dropdown">
      <div>
        <Popper anchorEl={anchorEl} className={clsx(className, 'dropdown__lib')} open={open} placement={placement} role={undefined} style={style} transition>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div>{children}</div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  handleClose: PropTypes.func,
};

Dropdown.defaultProps = {
  handleClose: function () {},
  anchorEl: {},
};

export default Dropdown;
