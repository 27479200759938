import React from 'react';

const ProgramCreatePage = React.lazy(() => import('../pages/programs/ProgramCreatePage/ProgramCreatePage'));
const ProgramDetailPage = React.lazy(() => import('../pages/programs/ProgramDetailPage/ProgramDetailPage'));
const ProgramsPage = React.lazy(() => import('../pages/programs/ProgramsPage/ProgramsPage'));
const ProgramMintStandAlone = React.lazy(() => import('../pages/programs/ProgramMintStandAlone/ProgramMintStandAlone'));

export const PATH_PROGRAM = '/program/:guid';
export const PATH_PROGRAM_STEP = '/program/:guid/:step';
export const PATH_PROGRAMS = '/programs';
export const PATH_PROGRAM_CREATE = '/program/create';
export const PATH_PROGRAM_MINT_STAND_ALONE = '/program/mint-stand-alone/:guid/:role';

const PAGE_PROGRAM = {
  component: ProgramDetailPage,
  path: PATH_PROGRAM,
};

const PAGE_PROGRAM_STEP = {
  component: ProgramDetailPage,
  path: PATH_PROGRAM_STEP,
};

const PAGE_PROGRAMS = {
  component: ProgramsPage,
  path: PATH_PROGRAMS,
};

const PAGE_PROGRAM_CREATE = {
  component: ProgramCreatePage,
  path: PATH_PROGRAM_CREATE,
};

const PAGE_PROGRAM_MINT_STAND_ALONE = {
  component: ProgramMintStandAlone,
  path: PATH_PROGRAM_MINT_STAND_ALONE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_PROGRAMS, PAGE_PROGRAM_CREATE, PAGE_PROGRAM_MINT_STAND_ALONE, PAGE_PROGRAM_STEP, PAGE_PROGRAM];
