import viewerApi from '../api/viewerApi';
import { API_URL, IS_DEBUG_MODE, VIEWER_THEME } from './viewerConfig';
import { contentsTypes, getFileStorage } from './viewerContent';
import { getToken, getUserData } from './viewerUtils';

export function getContentHotspotInternal(_this, _obj, _callback) {
  var _data = { content_guid: _obj.guid, type: 'GET_CONTENTS' };

  _this.setState({
    re_endpoint: 'Endpoint: GET cms/contents/' + _obj.guid,
    re_guid_content: _obj.guid,
  });

  // let API_URL = URL_API_LOCALTEST ? URL_API_LOCALTEST : + getDomain(window.location.href, false) + '/api/';
  let _user_data = getUserData();

  // URL PARAMS
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let _courseGuid = urlParams.get('courseGuid') || 'viewer'; // GUID del Curso

  viewerApi(
    _data,
    function (e) {
      try {
        if (typeof e.encoded_url !== 'undefined' && e.encoded_url !== '' && e.encoded_url !== null) {
          e.url = e.encoded_url;
        }
      } catch (encoded_url_err) {
        console.log(encoded_url_err);
      }

      try {
        _this.setState({ re_endpoint_content_version: e.version || 1, re_endpoint_file: e.url });
      } catch (e) {
        console.log(e);
        //_this.setState({ re_endpoint_content_version: 1, re_endpoint_file: e.url });
      }

      // Data lemonade
      try {
        _this.setState({ questionData: e.question_data });
      } catch (_errr) {}

      // Tratamiento de error
      if (e.response != undefined) {
        try {
          if (e.response.data.error.error == 'NOT_FOUND') {
            // RETURN
            try {
              _callback('FILE_NOT_FOUND');
            } catch (e) {}
            return;
          }
        } catch (e) {
          // RETURN
          try {
            _callback('FILE_NOT_FOUND');
          } catch (e) {}
          return;
        }
      }
      // Tratamiento de error

      let _contentsTypes = contentsTypes.find((o) => o.guid === e.type_guid);
      let contentGuid = _this && _this.state && _this.state.data && _this.state.data.content_guid ? _this.state.data.content_guid : null;

      if (e && e.type_guid && e.type_guid === 'CTTY_04' && VIEWER_THEME === 'orangeedv') {
        e.name = '';
      }

      const headers = { Authorization: getToken() };

      if (_contentsTypes.mustGetFile) {
        // Obtener la URL completa del contenido
        var _url = IS_DEBUG_MODE
          ? window.location.host
          : e.encoded_url.indexOf('files-storage') < 0
          ? e.encoded_url
          : API_URL.replace('/api/', '') + e.encoded_url;

        // Content data
        e.guid = _obj.guid;
        e.type_guid = e.type_guid ? e.type_guid : undefined;
        e.name = e.name ? e.name : undefined;

        getFileStorage(_obj.type_guid, _url, headers, API_URL, function (responseUrl) {
          e.url = responseUrl;

          if (_contentsTypes.hasDependency) {
            // Obtener la URL completa de la dependencia
            if (e && e.dependencies && e.dependencies.length > 0) {
              let _dependency_relative = e.dependencies[0].url.startsWith('https://')
                ? e.dependencies[0].url
                : API_URL.replace('/api/', '') + e.dependencies[0].url;

              getFileStorage(e.dependencies[0].type_guid + '_' + e.type_guid, _dependency_relative, headers, API_URL, function (responseUrlDepenency) {
                if (_contentsTypes.hotspotType === 'scormLink') {
                  e.scorm_dependency = responseUrlDepenency;
                  e.content_guid = contentGuid; // GUID de contenido Fixed Layout
                  e.personGuid = _user_data.guid;
                  e.api = API_URL;
                  e.app_referer = API_URL;
                  e.token = getToken();
                  e.r = '10'; // máximo de score 10
                  e.commit_mode = 'finish'; // Modo de guardar con el evento finish
                  e.lesson_item = _courseGuid;
                  // e.menu = 'b'; // que el menú lateral sea desplegable
                  // e.viewerMode = 'read';
                }
                if (_contentsTypes.hotspotType === 'lemonadeExerciseLink') {
                  e.lemonade_dependency = responseUrlDepenency;
                  e.url_api = API_URL;
                  e.token = getToken();
                }
                _callback(e);
              });
            } else {
              // _this.showError('ERROR_404_DEPENDENCY');
              _callback('ERROR_404_DEPENDENCY');
            }
          } else {
            _callback(e);
          }
        });
      } else {
        if (_contentsTypes.hasDependency) {
          // Obtener la URL completa de la dependencia
          if (e && e.dependencies && e.dependencies.length > 0) {
            let _dependency_relative = e.dependencies[0].url.startsWith('https://')
              ? e.dependencies[0].url
              : API_URL.replace('/api/', '') + e.dependencies[0].url;

            getFileStorage(e.dependencies[0].type_guid + '_' + e.type_guid, _dependency_relative, headers, API_URL, function (responseUrlDepenency) {
              if (_contentsTypes.hotspotType === 'lemonadeExerciseLink') {
                e.lemonade_dependency = responseUrlDepenency;
                e.url_api = API_URL;
                e.token = getToken();
                e.scope = _courseGuid;
                e.guid_book = contentGuid; // GUID de contenido Fixed Layout
              }
              _callback(e);
            });
          } else {
            // _this.showError('ERROR_404_DEPENDENCY');
            _callback('ERROR_404_DEPENDENCY');
          }
        } else {
          _callback(e);
        }
      }
    }.bind(_this)
  );
}
