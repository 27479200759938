import Bubble from '__core/modules/atoms/Bubble';
import Icon from '__core/modules/atoms/Icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

const HeaderTabs = ({ align, isURLBased, onChangeTab, onDisableTab, tabActive, tabs, typeView }) => {
  const [activeTabState, setActiveTabState] = useState(tabActive);
  const [hasChangedPath, setHasChangedPath] = useState(false);
  const [tabToChangeTo, setTabToChangeTo] = useState(tabs[0]);

  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setHasChangedPath(true);
    });
  }, [history]);

  function onClickChangeTab(tab) {
    if (isURLBased) {
      setTabToChangeTo(tab);
    } else {
      if (!tab.disabled) setActiveTabState(tab.id);
      else onDisableTab(tab.id);
    }
    if (tab.onClick) {
      tab.onClick();
    }
  }

  useEffect(() => {
    if (hasChangedPath && isURLBased && tabToChangeTo) {
      setActiveTabState(tabToChangeTo.id);
      setHasChangedPath(false);
    }
  }, [hasChangedPath]);

  useEffect(() => {
    if (onChangeTab) {
      onChangeTab(activeTabState);
    }
  }, [activeTabState]);

  useEffect(() => {
    setActiveTabState(tabActive);
  }, [tabActive]);

  function renderText(text, icon, active) {
    return (
      <div className={`header-tabs__tab-text-container `}>
        {icon && (
          <div className="header-tabs__tab-icon">
            <Icon type={icon} color={active ? 'primary' : 'default'} />
          </div>
        )}
        <span className="header-tabs__tab-text">{text}</span>
      </div>
    );
  }

  return (
    <div className={clsx('header-tabs', `header-tabs__type-view-${typeView}`, `header-tabs__align-${align}`)}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => {
            onClickChangeTab(tab);
          }}
          className={clsx('header-tabs__tab', `header-tabs__tab--id-${tab.id}`, {
            'header-tabs__tab--active': tab.id === activeTabState,
            'header-tabs__tab--with-icon': tab.icon,
            'header-tabs__tab--disabled': tab.disabled,
          })}
        >
          {tab.bubble && (
            <div className="header-tabs__bubble">
              <Bubble />
            </div>
          )}
          {tab.to && <Link to={tab.to}>{renderText(tab.name, tab.icon, tab.id === activeTabState)}</Link>}
          {!tab.to && renderText(tab.name, tab.icon, tab.id === activeTabState)}
        </div>
      ))}
    </div>
  );
};

HeaderTabs.propTypes = {
  align: PropTypes.oneOf(['default', 'left', 'right']),
  isURLBased: PropTypes.bool,
  onChangeTab: PropTypes.func,
  tabActive: PropTypes.string,
  tabs: PropTypes.array, // tabs: [{id: 1, name: "primera Tab"}]
  typeView: PropTypes.oneOf(['default', 'border', 'background', 'background-round', 'vertical']),
};

HeaderTabs.defaultProps = {
  align: 'default',
  isURLBased: false,
  onDisableTab: () => {},
  tabs: [],
  typeView: 'default',
};

export default HeaderTabs;
