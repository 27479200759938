/* eslint-disable camelcase */
import en_auth from './en/auth.json';
import en_common from './en/common.json';
import es_auth from './es/auth.json';
import es_common from './es/common.json';
import pt_auth from './pt/auth.json';
import pt_common from './pt/common.json';

export default {
  es: [es_auth, es_common],
  en: [en_auth, en_common],
  pt: [pt_auth, pt_common],
};
