import resources from 'app/translations/resources';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    lng: 'es',
    resources: {
      en: {},
      es: {},
      pt: {},
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// ADD RESOURCES:
Object.keys(resources).forEach(function (lang) {
  resources[lang].map((langScope) => {
    Object.keys(langScope).forEach(function (langScopeNs) {
      i18next.addResources(lang, langScopeNs, langScope[langScopeNs]);
    });

    return langScope;
  });
});

export default i18next;
