import { Tooltip } from '@material-ui/core';
import Hr from '__core/modules/atoms/Hr';
import Icon from '__core/modules/atoms/Icons';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ListSimpleDropdown = ({ items, onClick, sizeIcon, preventEvent }) => {
  const delay = 600;

  function getColor(item) {
    let color = item.color && item.colorIcon;
    return !item.disabled ? color : 'inherit';
  }

  function getOnClick(item, event) {
    if (preventEvent) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (item.disabled) {
      return () => null;
    }

    return onClick ? onClick(item, event) : item.onClick(item, event);
  }

  function _renderItem(item) {
    return (
      <div className="list-simple-dropdown__texts">
        <span className={clsx('list-simple-dropdown__name', { disabled: item.disabled })} data-testid={item.icon || item.name}>
          {item.name}
        </span>
        <span className="list-simple-dropdown__description">{item.description} </span>
      </div>
    );
  }

  return (
    <ul className="list-simple-dropdown">
      {items.map((item) => (
        <Fragment key={item.key || item.name || uniqueId()}>
          {item.id === 'separator' && <Hr size="small" />}

          {item.id !== 'separator' && (
            <li
              className={clsx(
                'list-simple-dropdown__li',
                {
                  'list-simple-dropdown__li--active': item.active,
                  'list-simple-dropdown__li--disabled': item.disabled,
                },
                item.className
              )}
              key={item.name}
              onClick={(event) => getOnClick(item, event)}
            >
              {item.icon && (
                <div className="list-simple-dropdown__icon">
                  <Icon type={item.icon} color={getColor(item)} fontSize={sizeIcon} />
                </div>
              )}

              {item.tooltip ? (
                <Tooltip classes={{ tooltip: 'list-simple-dropdown__tooltip' }} title={item.tooltip} enterDelay={delay} enterNextDelay={delay}>
                  {_renderItem(item)}
                </Tooltip>
              ) : (
                _renderItem(item)
              )}

              {item.rightIcon && (
                <div className="list-simple-dropdown__icon list-simple-dropdown__icon--right">
                  <Icon type={item.rightIcon} color={item.active ? 'action' : 'inherit'} fontSize={sizeIcon} />
                </div>
              )}
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  );
};

ListSimpleDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      description: PropTypes.string,
      disabled: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.oneOf(['', 'separator']),
      key: PropTypes.string,
      onClick: PropTypes.func,
      rightIcon: PropTypes.string,
      tooltip: PropTypes.string,
    })
  ),
  sizeIcon: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};

ListSimpleDropdown.defaultProps = {
  items: [],
  sizeIcon: 'small',
};

export default ListSimpleDropdown;
