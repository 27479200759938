import i18next from 'app/translations/i18n';

export const DATE = i18next.t('forms:field_date');
export const EMAIL = i18next.t('forms:field_email');
export const INTEGER = i18next.t('forms:field_integer');
export const MAX = i18next.t('forms:max_length');
export const MIN = i18next.t('forms:min_length');
export const PASSWORD_MIN = i18next.t('forms:passwords_min', { min: 10 });
export const PASSWORD_REGEX = i18next.t('forms:passwords_regex');
export const PASSWORD_REPEAT_TEXT = i18next.t('forms:passwords_must_match');
export const POSITIVE = i18next.t('forms:field_positive');
export const REQUIRED = i18next.t('forms:field_required');
export const SHOULD_MATCH = i18next.t('forms:should_match');
