import { getOrganization } from 'app/crud/organization.crud';
import { getSetting } from 'app/crud/systemSettings.crud';
import { setWindowsVariable } from 'app/services/window.service';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { initializeAfterSetting } from '../../config/initializeApp';
import { REDUCER_SYSTEM_SETTINGS } from '../conf';

export const actionTypes = {
  GetSetting: 'cms/GET_SYSTEM_SETTINGS',
  SetSetting: 'cms/SET_SYSTEM_SETTINGS',

  GetOrganization: 'cms/GET_ORGANIZATION',
  SetOrganization: 'cms/SET_ORGANIZATION',
};

const initialState = {
  loading: false,
  organization: {},
  settings: {},
};

export const reducer = persistReducer({ storage, key: REDUCER_SYSTEM_SETTINGS, whitelist: ['settings', 'organization'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSetting: {
      return { ...state, loading: true };
    }

    case actionTypes.SetSetting: {
      return { ...state, loading: false, settings: { ...state.settings, ...action.payload } };
    }

    case actionTypes.GetOrganization: {
      return { ...state, loading: true };
    }

    case actionTypes.SetOrganization: {
      return { ...state, loading: false, organization: action.payload };
    }

    default:
      return state;
  }
});

export const selectors = {
  getSetting: (state) => {
    return state[REDUCER_SYSTEM_SETTINGS] ? state[REDUCER_SYSTEM_SETTINGS].settings : null;
  },

  getOrganization: (state) => {
    return state[REDUCER_SYSTEM_SETTINGS] ? state[REDUCER_SYSTEM_SETTINGS].organization : null;
  },
};

export const actions = {
  getSetting: (payload) => ({ type: actionTypes.GetSetting, payload }),
  setSetting: (response) => ({ type: actionTypes.SetSetting, payload: response }),

  getOrganization: (params) => ({ type: actionTypes.GetOrganization, payload: { params } }),
  setOrganization: (response) => ({ type: actionTypes.SetOrganization, payload: response }),
};

function* fetchSetting() {
  const { data } = yield getSetting();

  if (data && data.status === 'success' && data.data) {
    initializeAfterSetting(data.data);
    yield put(actions.setSetting(data.data));
  }
}

function* fetchOrganization() {
  const { data } = yield getOrganization();
  setWindowsVariable('tenant', data?.data?.tenant?.guid);

  if (data && data.status === 'success' && data.data) {
    yield put(actions.setOrganization(data.data));
  }
}

function* getSettingIfNeeded({ payload }) {
  const settings = yield select(selectors.getSetting);
  if (!settings || Object.keys(settings).length === 0 || payload?.isRefresh) {
    yield fetchSetting();
  }
}

function* getOrganizationIfNeeded() {
  const organization = yield select(selectors.getOrganization);
  if (!organization || Object.keys(organization).length === 0) {
    yield fetchOrganization();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetSetting, getSettingIfNeeded);
  yield takeLatest(actionTypes.GetOrganization, getOrganizationIfNeeded);
}
