import Dropdown from '__core/modules/components/dropdowns/components/Dropdown';
import ListSimpleDropdown from '__core/modules/components/dropdowns/components/ListSimpleDropdown';
import React from 'react';
import useSelectLanguage from './useSelectLanguage';

function SelectLanguage() {
  const { anchorEl, langId, open, optionsList, onClose, onOpen } = useSelectLanguage();

  return (
    <>
      <button className="select-language" onClick={onOpen}>
        {langId ? langId : 'es'}
      </button>

      <Dropdown anchorEl={anchorEl} className="select-language__dropdown" handleClose={onClose} open={open} placement="right">
        <ListSimpleDropdown items={optionsList} />
      </Dropdown>
    </>
  );
}

export default SelectLanguage;
