import React from 'react';

const LoginPage = React.lazy(() => import('../pages/auth/LoginPage/LoginPage'));
const RecoverPage = React.lazy(() => import('../pages/auth/RecoverPage/RecoverPage'));

export const PATH_LOGIN = '/auth/login';
export const PATH_RECOVER = '/recover';

// -------------------------------------------
//  AUTH
// -------------------------------------------
const PAGE_LOGIN = {
  component: LoginPage,
  path: PATH_LOGIN,
};

const PAGE_RECOVER = {
  component: RecoverPage,
  path: PATH_RECOVER,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_LOGIN, PAGE_RECOVER];
