import viewerApi from '../api/viewerApi';

export function getReviewsInternal(_this, e) {
  _this.setState({ visible_loading_full: true });

  var _data = {
    params: {
      consumption_guid: _this.state.consumption_guid,
      content_guid: _this.state.data.content_guid,
      difficult_rating: e.difficult,
      usefull_rating: e.usefull,
    },
    type: 'SEND_EVALUATE_CONTENT',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });
    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_EVALUATE_OK, type: 'correct' },
        openToast: true,
      });
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_EVALUATE_ERROR, type: 'error' },
        openToast: true,
      });
    }
  });
}

export function getReportInternal(_this, e) {
  _this.setState({ visible_loading_full: true });

  var _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      type: e.type,
      comment: e.comment,
    },
    type: 'SEND_REPORT_CONTENT',
  };

  viewerApi(_data, function (e) {
    _this.setState({ visible_loading_full: false });
    if (typeof e.data != 'undefined') {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_OK, messaje: window.ocviewerlang.VISOR_REPORT_OK, type: 'correct' },
        openToast: true,
      });
    } else {
      _this.setState({
        dataToast: { title: window.ocviewerlang.VISOR_MESSAJE_WARNING, messaje: window.ocviewerlang.VISOR_REPORT_ERROR, type: 'error' },
        openToast: true,
      });
    }
  });
}
