import React from 'react';

const AnalyticAmplitudePage = React.lazy(() => import('../pages/analytics/analyticAmplitude/AnalyticAmplitudePage'));

export const PATH_ANALYTIC_AMPLITUDE = '/analytic/external';

const PAGE_ANALYTIC_AMPLITUDE = {
  component: AnalyticAmplitudePage,
  path: PATH_ANALYTIC_AMPLITUDE,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_ANALYTIC_AMPLITUDE];
