import { LANG_ID_LOCAL_STORAGE_KEY } from 'app/services/language.service';
import i18next from 'app/translations/i18n';
import { setConfig } from 'react-lemonade-editor';

export function initializeLemonade({ showLemonadeAiFeedback, user }) {
  const lang = user?.lang_id || localStorage.getItem(LANG_ID_LOCAL_STORAGE_KEY) || i18next.language || 'en';

  setConfig({
    lang,
    debounceTime: 0,
    mathRenderEngine: 'katex',
    showSampleAnswer: true,
    showAIFeedbackGenerator: showLemonadeAiFeedback,
    configEditor: {
      answerOptions: {
        imageDefaultSize: {
          height: null,
          width: null,
        },
      },
      questions: {
        imageDefaultSize: {
          height: null,
          width: null,
        },
      },
    },
  });
}
