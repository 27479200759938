import React from 'react';

const AnalyticGamePage = React.lazy(() => import('../pages/analytics/analyticGame/AnalyticGamePage'));

export const PATH_ANALYTIC_GAME = '/analytic/game';

const PAGE_ANALYTIC_GAME = {
  component: AnalyticGamePage,
  path: PATH_ANALYTIC_GAME,
};

// IMPORTANT: The order in which the pages are added is important
export default [PAGE_ANALYTIC_GAME];
