import routesConfigAmplitude from './routesConfigAnalyticAmplitude';
import routesConfigAnalyticGame from './routesConfigAnalyticGame';
import routesConfigAnalyticLicenses from './routesConfigAnalyticLicenses';
import routesConfigAnalyticProgram from './routesConfigAnalyticProgram';
import routesConfigAnalyticUsage from './routesConfigAnalyticUsage';
import routesConfigCollections from './routesConfigCollections';
import routesConfigContents from './routesConfigContents';
import routesConfigFixedLayout from './routesConfigFixedLayout';
import routesConfigGroups from './routesConfigGroups';
import routesConfigLicenses from './routesConfigLicenses';
import routesConfigLicensesBatch from './routesConfigLicensesBatch';
import routesConfigMessages from './routesConfigMessages';
import routesConfigPersonalize from './routesConfigPersonalize';
import routesConfigDigitalPrograms from './routesConfigPrograms';
import routesConfigPublications from './routesConfigPublications';
import routesConfigSchools from './routesConfigSchools';
import routesConfigSetting from './routesConfigSetting';
import routesConfigUser from './routesConfigUser';

// IMPORTANT: The order in which the pages are added is important
export default [
  ...routesConfigContents,
  ...routesConfigCollections,
  ...routesConfigPublications,
  ...routesConfigDigitalPrograms,
  ...routesConfigUser,
  ...routesConfigLicenses,
  ...routesConfigLicensesBatch,
  ...routesConfigAmplitude,
  ...routesConfigAnalyticGame,
  ...routesConfigAnalyticLicenses,
  ...routesConfigAnalyticProgram,
  ...routesConfigAnalyticUsage,
  ...routesConfigSchools,
  ...routesConfigGroups,
  ...routesConfigPersonalize,
  ...routesConfigSetting,
  ...routesConfigFixedLayout,
  ...routesConfigMessages,
];
