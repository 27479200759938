import { getRoles } from 'app/crud/users.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_USERS } from '../conf';

export const actionTypes = {
  GetRoles: 'cms/GET_USER_ROLES',
  SetRoles: 'cms/SET_USER_ROLES',
};

const initialState = {
  loading: false,
  roles: [],
};

export const reducer = persistReducer({ storage, key: PERSIST_USERS, whitelist: ['roles'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetRoles: {
      return { ...state, loading: true };
    }

    case actionTypes.SetRoles: {
      return { ...state, loading: false, roles: action.payload };
    }

    default:
      return state;
  }
});

export const selectors = {
  getLoading: (state) => {
    return state?.entities?.users ? state.entities.users.loading : null;
  },
  getRoles: (state) => {
    return state?.entities?.users ? state.entities.users.roles : null;
  },
};

export const actions = {
  getRoles: (params) => ({ type: actionTypes.GetRoles, payload: { params } }),
  setRoles: (response) => ({ type: actionTypes.SetRoles, payload: response }),
};

function* fetchRoles() {
  const { data } = yield getRoles();
  yield put(actions.setRoles(data.data));
}

function* getRolesIfNeeded() {
  const roles = yield select(selectors.getRoles);
  if (!roles || roles.length === 0) {
    yield fetchRoles();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetRoles, getRolesIfNeeded);
}
