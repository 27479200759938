export function showErrorInternal(_this, _msj) {
  console.log('showError()========>');
  console.log(_msj);

  var _report = {
    content_guid: String(_this.state.re_guid_content),
    comment: {
      description: _this.state.re_endpoint,
      platform: 'Web Backoffice',
      os_version: navigator.userAgent,
      version: _this.state.re_endpoint_content_version,
    },
    type: 'app-error',
  };

  var _objEx;
  if (typeof _msj == 'object') {
    _objEx = _msj;
    _msj = _msj.type;
    if (_objEx.type == 'FILE_ERROR' && _objEx.message === 'PDFTRON_ERROR') {
      _msj = 'PDFTRON_ERROR';
      let viewerDataIntent = 'viewerDataIntent-' + _this.state.data.content_guid;
      let intentAccess = localStorage.getItem(viewerDataIntent);
      let intentCount = !intentAccess ? 1 : parseInt(intentAccess) + 1;
      localStorage.setItem(viewerDataIntent, intentCount);
      if (intentCount < 6) {
        window.location.reload();
        return;
      } else {
        localStorage.removeItem(viewerDataIntent);
      }
    } else if (_msj == 'FILE_ERROR') {
      _msj = 'FILE_HAVE_ERROR';
    }
  }

  try {
    // Caso de archivos malos, imagen audio y video
    if ((_this.state.data.type == 'video' || _this.state.data.type == 'image' || _this.state.data.type == 'audio') && _msj == 'FILE_NOT_FOUND') {
      _msj = 'FILE_HAVE_ERROR';
    }
  } catch (e__er) {
    console.log(e__er);
  }

  try {
    if (_msj == 'CONTENT_APP_URL_NOT_ACCESSIBLE') {
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.CONTENT_APP_URL_NOT_ACCESSIBLE || 'CONTENT_APP_URL_NOT_ACCESSIBLE' });
      return;
    } else if (_msj == 'CONTENT_DISABLED') {
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_CONTENT_DISABLED });
      return;
    } else if (_msj == 'ERROR_500') {
      _report.comment.code = 500;
      _report.comment.message = 'API Internal server error.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_ERROR_500 || 'API Internal server error.' });

      try {
        _this.setState({ error_viewer_text: 'error_500_api' });
      } catch (err_er) {}
    } else if (_msj == 'ERROR_403') {
      _report.comment.code = 403;
      _report.comment.message = 'API Access forbiden.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_ERROR_403 || 'Error 403. Access forbiden.' });

      try {
        _this.setState({ error_viewer_text: 'error_403_api' });
      } catch (err_er) {}
    } else if (_msj == 'ERROR_404') {
      _report.comment.code = 404;
      _report.comment.message = 'API Content not found.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_ERROR_404 || 'Error 404. Content not found.' });

      try {
        _this.setState({ error_viewer_text: 'error_404_api' });
      } catch (err_er) {}
    } else if (_msj == 'FILE_NOT_AUTHORIZED') {
      _report.comment.code = 403;
      _report.comment.message = 'File Access forbiden.';
      _this.setState({ is_loaded_error: true, error_msj: 'Erro, não tem autorização para acessar o conteúdo.' });

      try {
        _this.setState({ error_viewer_text: 'error_403_file' });
      } catch (err_er) {}
    } else if (_msj == 'DATA_QUESTION_NOT_FOUND') {
      _report.comment.code = 404;
      _report.comment.message = 'Data not found.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_DATA_QUESTION_NOT_FOUND });

      try {
        _this.setState({ error_viewer_text: 'error_404_file' });
      } catch (err_er) {}
    } else if (_msj == 'FILE_HAVE_ERROR') {
      _report.comment.code = 500;
      _report.comment.message = 'File error.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_FILE_HAVE_ERROR });

      try {
        _this.setState({ error_viewer_text: 'error_500_file' });
      } catch (err_er) {}
    } else if (_msj == 'FILE_NOT_FOUND') {
      _report.comment.code = 404;
      _report.comment.message = 'File not found.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_FILE_NOT_FOUND });

      try {
        _this.setState({ error_viewer_text: 'error_404_file' });
      } catch (err_er) {}
    } else if (_msj == 'URL_NOT_FOUND') {
      _report.comment.code = 404;
      _report.comment.message = 'URL not found.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_FILE_NOT_FOUND });

      try {
        _this.setState({ error_viewer_text: 'error_404_file' });
      } catch (err_er) {}
    } else if (_msj == 'FILE_TYPE_UNDEFINED') {
      _report.comment.code = 403;
      _report.comment.message = 'File Access forbiden.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_FILE_TYPE_UNDEFINED });

      try {
        _this.setState({ error_viewer_text: 'error_403_file' });
      } catch (err_er) {}
    } else if (_msj === 'PDFTRON_ERROR') {
      _report.comment.code = 500;
      _report.comment.message = 'File error.';
      _this.setState({ is_loaded_error: true, error_msj: window.ocviewerlang.VISOR_LIB_PDFTRON_ERROR_FILE });
    } else {
      _report.comment.code = 1;
      _report.comment.message = 'Other error';
      _this.setState({ is_loaded_error: true, error_msj: _msj });

      try {
        _this.setState({ error_viewer_text: 'error_1_file' });
      } catch (err_er) {}
    }

    _this.sendReportContentError(_report);
  } catch (e) {}
}

export function reportParentErrorInternal(_this, _report) {
  if (_report.origin == 'fl') {
    var _report = {
      content_guid: String(_report.guid),
      comment: {
        description: 'Endpoint: GET front/contents/' + _report.guid,
        platform: 'Web Backoffice',
        os_version: navigator.userAgent,
        version: _this.state.re_endpoint_content_version,
      },
      type: 'app-error',
    };
    if (_report.error == '404') {
      _report.comment.code = 404;
      _report.comment.message = 'File not found.';
    } else if (_report.error == '403') {
      _report.comment.code = 403;
      _report.comment.message = 'File Access forbiden.';
    } else {
      _report.comment.code = 1;
      _report.comment.message = 'Other error';
    }

    _this.sendReportContentError(_report);
  }
}

export function sendReportContentErrorInternal(_this, _report) {
  try {
    _report.comment.description = _report.comment.description + ' - Endpoint Storage: ' + _this.state.re_endpoint_file + ' - Prev section: -';
  } catch (_errr) {}

  // FTDI2C-2162
  try {
    if (_report.comment.message.indexOf('Received return status 0') >= 0 || _report.comment.message.indexOf('- STAGE C') >= 0) {
      _report.type = 'connection-error';
    }
  } catch (__er) {
    console.log(__er);
  }
  try {
    if (
      _report.comment.message.indexOf('Invalid XOD file') >= 0 ||
      _report.comment.message.indexOf('file is not a valid') >= 0 ||
      _report.comment.message.indexOf('- STAGE A') >= 0 ||
      _report.comment.message.indexOf('File error.') >= 0
    ) {
      _report.type = 'file-error';
    }
  } catch (__er) {
    console.log(__er);
  }
  try {
    if (_report.comment.message.indexOf('File Access forbiden.') >= 0) {
      _report.type = 'file-access-forbiden-error';
    }
  } catch (__er) {
    console.log(__er);
  }
  try {
    if (_report.comment.message.indexOf('File not found.') >= 0 || _report.comment.message.indexOf('URL not found.') >= 0) {
      _report.type = 'url-not-found-error';
    }
  } catch (__er) {
    console.log(__er);
  }
}
