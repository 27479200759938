import { setMetadataArraySelect, setMetadataArraySelectSimple, setMetadataSelect } from 'app/services/metadata.service';
import i18next from 'app/translations/i18n';
import { MAX, MIN, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'publication';

export const Model = {
  category_guid: {},
  collections: [],
  country: '',
  description: '',
  disciplines: '',
  educationLevels: [],
  educationYears: [],
  name: '',
  publisher_guid: '',
  thumbnail: '',
};

export const ModelYup = Yup.object().shape({
  category_guid: Yup.object(),
  collections: Yup.array().of(Yup.object({})),
  description: Yup.string(),
  disciplines: Yup.array().of(Yup.object({})).min(1, REQUIRED).required(REQUIRED),
  educationLevels: Yup.array().of(Yup.object({})),
  educationYears: Yup.array().of(Yup.object({})),
  name: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
  publisher_guid: Yup.mixed(),
  thumbnail: Yup.string(),
});

export const ModelYupMultiCountry = Yup.object().shape({
  category_guid: Yup.object(),
  collections: Yup.array().of(Yup.object({})),
  description: Yup.string(),
  disciplines: Yup.object().required(REQUIRED),
  name: Yup.string().min(3, MIN).max(250, MAX).required(REQUIRED),
  publisher_guid: Yup.mixed(),
  thumbnail: Yup.string(),
});

export const ModelSuccess = [
  { id: 'create', message: i18next.t('publications:toast_create') },
  { id: 'update', message: i18next.t('publications:toast_update') },
];

export function sanitizeData(data, storeLevels) {
  const levels = storeLevels.filter((item) => data.educationYears.find((element) => item.educationYears.find((i) => i.guid === element.education_year_guid)));

  return {
    category_guid: data.category_guid ? setMetadataSelect(data, 'category_name', 'category_guid') : Model.category_guid,
    collections: setMetadataArraySelect(data.collections, 'collection', 'guid'),
    country: data.country_guid ? setMetadataSelect(data, 'country_name', 'country_guid') : Model.country,
    description: data.description ? data.description : Model.description,
    disciplines: getDisciplines(data),
    educationLevels: getEducationLevels({ data, levels }),
    educationYears: getEducationYears(data),
    guid: data.guid,
    name: data.name ? data.name : Model.name,
    publisher_guid: data.publisher_guid ? setMetadataSelect(data, 'publisher_name', 'publisher_guid') : Model.publisher_guid,
    thumbnail: data.thumbnail ? data.thumbnail : '',
  };
}

// ---------------------------------------------------------------
// Auxiliary functions
// ---------------------------------------------------------------
function getEducationLevels({ data, levels }) {
  if (data.country_guid) {
    return levels.length ? { label: levels[0].level, value: levels[0].guid } : '';
  }
  return setMetadataArraySelectSimple(levels, 'level', 'guid');
}

function getEducationYears(data) {
  if (data.country_guid) {
    return data.educationYears.length ? { label: data.educationYears[0].education_year_name, value: data.educationYears[0].education_year_guid } : '';
  }
  return setMetadataArraySelectSimple(data.educationYears, 'education_year_name', 'education_year_guid');
}

function getDisciplines(data) {
  if (data.country_guid) {
    return data.disciplines.length ? { label: data.disciplines[0].discipline_name, value: data.disciplines[0].discipline_guid } : '';
  }
  return setMetadataArraySelect(data.disciplines, 'discipline_name', 'discipline_guid');
}
