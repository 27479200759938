import axios from 'axios';

export const ADMIN = 'front/school-admin';
export const USERS = 'users';

export function createGroup(schoolGuid, groupGuid, params = { guid: [] }) {
  return axios.post(`${ADMIN}/${schoolGuid}/groups/${groupGuid}/${USERS}`, params);
}

export function deleteGroup(schoolGuid, groupGuid, params = { guid: [] }) {
  return axios.delete(`${ADMIN}/${schoolGuid}/groups/${groupGuid}/${USERS}`, { data: params });
}
