import { CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import IndicatorsContainer from './components/IndicatorsContainer';
import OptionLabel from './components/OptionLabel';

export const utilsSelectCustomizable = {
  /**
   * Devuelve las opciones que son necesarias para el componente SelectCustomizable,
   * dichas opciones las compone recorriendo el array elements
   * y accediendo a la propiedad propertyToLabel para el valor label,
   * y a la propieddad propertyToValue para el valor value de las opciones
   * @param {array} elements
   * @param {string} propertyToLabel
   * @param {string} propertyToValue
   */
  getOptionsProperties(elements, propertyToLabel, propertyToValue) {
    return elements && propertyToLabel && propertyToValue
      ? elements.map((element) => {
          return {
            label: element[propertyToLabel],
            value: element[propertyToValue],
            componentRight: element.componentRight,
          };
        })
      : [];
  },
};

const SelectCustomizable = ({
  t,
  canCreate,
  defaultValue,
  error,
  icon,
  isLoading,
  isMulti,
  isSkeleton,
  label,
  menuPosition,
  name,
  noOptionsMessage,
  onChange,
  onCreateOption,
  options,
  placeholder = t('Select...'),
  placeholderCreateLabel = t('placeholder:Create new section'),
  size,
  value,
  variant,
  ...props
}) => {
  function formatGroupLabel(data) {
    return (
      <div className="select-customizable__group-label">
        <span>{data.label}</span>
        <span className="select-customizable__group-badge">{data.options.length}</span>
      </div>
    );
  }

  function loadingMessage() {
    return (
      <div>
        <CircularProgress color="inherit" thickness={5} />
      </div>
    );
  }

  function getStyles() {
    return {
      // Fixes the overlapping problem of the component
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        fontFamily: data?.style?.fontFamily ? data.style.fontFamily : 'Inter',
        fontSize: 15,
      }),
    };
  }

  function getOptionLabel(option) {
    return option.label;
  }

  const customComponents = {
    SingleValue: (props) => {
      const { children, data } = props;
      return (
        <components.SingleValue {...props}>
          <div className="d-flex align-items-center justify-content-between w-100" style={{ gap: '8px' }}>
            <div style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</div>
            {data.componentRight && <>{data.componentRight}</>}
          </div>
        </components.SingleValue>
      );
    },
    Option: (props) => {
      const { children, data } = props;
      return (
        <components.Option {...props}>
          <div className="d-flex align-items-center justify-content-between w-100" style={{ gap: '8px' }}>
            <div style={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</div>
            {data.componentRight && <>{data.componentRight}</>}
          </div>
        </components.Option>
      );
    },
  };

  return !isSkeleton ? (
    <div className="select-customizable-wrapper">
      {label && (
        <label className="select-customizable__label" title={label}>
          {label} {props.required && <sup>*</sup>}
        </label>
      )}
      {canCreate ? (
        <CreatableSelect
          className={`select-customizable select-customizable--${variant} select-customizable--size-${size}`}
          classNamePrefix="select-customizable"
          createOptionPosition="last"
          defaultValue={defaultValue}
          formatCreateLabel={(text) => `${placeholderCreateLabel}: ${text}`}
          isClearable={true}
          isLoading={isLoading}
          isMulti={isMulti}
          loadingMessage={loadingMessage}
          menuPlacement={'auto'}
          menuPosition={'fixed'}
          name={name}
          noOptionsMessage={() => noOptionsMessage}
          onChange={onChange}
          onCreateOption={onCreateOption}
          options={options}
          placeholder={placeholder}
          styles={getStyles()}
          value={value}
          components={{ ...props.components, ...customComponents }}
          {...props}
        />
      ) : (
        <Select
          {...props}
          className={`select-customizable select-customizable--${variant} select-customizable--size-${size} data-testid-${name}`}
          classNamePrefix="select-customizable"
          components={{ IndicatorsContainer, ...customComponents }}
          defaultValue={defaultValue}
          formatGroupLabel={formatGroupLabel}
          formatOptionLabel={OptionLabel}
          getOptionLabel={getOptionLabel}
          isLoading={isLoading}
          isMulti={isMulti}
          loadingMessage={loadingMessage}
          menuPlacement={'auto'}
          menuPosition={menuPosition}
          name={name}
          noOptionsMessage={() => noOptionsMessage}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          styles={getStyles()}
          value={value}
        />
      )}
      {error && <span className="select-customizable__error">{error}</span>}
    </div>
  ) : (
    <>
      {label && (
        <label className="select-customizable__label" title={label}>
          {label} {props.required && <sup>*</sup>}
        </label>
      )}

      <Skeleton animation="wave" height={48} variant="text" />
    </>
  );
};

SelectCustomizable.propTypes = {
  canAccess: PropTypes.bool,
  defaultValue: PropTypes.any,
  error: PropTypes.string,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSkeleton: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  menuPosition: PropTypes.oneOf(['initial', 'fixed']),
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.oneOf(['default', 'small']),
  value: PropTypes.any,
  variant: PropTypes.oneOf(['default']),
  statusLabel: PropTypes.string,
  statusColor: PropTypes.string,
};

SelectCustomizable.defaultProps = {
  isMulti: false,
  isSkeleton: false,
  menuPosition: 'fixed',
  noOptionsMessage: 'No hay opciones...',
  onChange: () => null,
  options: [],
  size: 'default',
  variant: 'default',
};

export default withTranslation(['placeholder'])(SelectCustomizable);
