import i18next from 'app/translations/i18n';
import { EMAIL, MAX, MIN, PASSWORD_REPEAT_TEXT, REQUIRED } from 'app/utils/yupMessage';
import * as Yup from 'yup';

export const MODEL_TEXT = 'auth';

export const ModelRecover = { email: '' };
export const ModelResetPassword = { new_password: '', new_password_repeat: '' };

export const ModelYupRecover = Yup.object().shape({ email: Yup.string().max(200, MAX).email(EMAIL).required(REQUIRED) });

export const ModelYupResetPassword = Yup.object().shape({
  new_password: Yup.string().required(REQUIRED).min(3, MIN),
  new_password_repeat: Yup.string().oneOf([Yup.ref('new_password'), null], PASSWORD_REPEAT_TEXT),
});

export const ModelErrors = [
  { error: 'BAD_REQUEST', message: i18next.t('auth:error_email_not_exist') },
  { error: 'INVALID', message: i18next.t('auth:invalid_token') },
  { errorKey: 'PASSWORD_INCORRECT', message: i18next.t('auth:error_password_incorrect') },
];

export const ModelSuccess = [
  { id: 'create', message: i18next.t('toast:toast_create') },
  { id: 'update', message: i18next.t('toast:toast_update') },
];
