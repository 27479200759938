// IMPORTS
import { defaultConfig } from '../../../config/environment';

// EXPORTS
export const API_URL = defaultConfig.API_URL;
export const VIEWER_ACTOR_VERSION = 'BACKOFFICE - ViewersContainer 07_08_2023';
export const IS_BACKOFFICE = true;
export const IS_DEBUG_MODE = false;
export const PORT_DEBUG_MODE = '3003';
export const VIEWER_LANGUAGE = window.location.href.indexOf('macmillan') >= 0 ? 'es' : window.location.href.indexOf('edelvivesdigital') >= 0 ? 'es' : 'en';
export const URL_API_LOCALTEST = null;
export const GOTOBACK_MODE = 'close'; // close | toback
export const VIEWER_THEME =
  window.location.href.indexOf('macmillan') >= 0 ? 'darkblue1' : window.location.href.indexOf('edelvivesdigital') >= 0 ? 'orangeedv' : 'gray';
