import i18next from 'app/translations/i18n';
import { enIN, es } from 'date-fns/locale';
import 'moment/locale/es';
import moment from 'moment/moment';

export function getFormat() {
  return i18next.language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
}

export function getDateFormat(date) {
  const dateDate = new Date(date);
  return moment(dateDate).format(i18next.language === 'es' ? 'DD/MM/YYYY' : 'MM/DD/YYYY');
}

export function getDateFormatText(date) {
  const dateDate = new Date(date);
  const locale = i18next.language.toLowerCase();
  return moment(dateDate).locale(locale).format('LL');
}

export function getDateFormatAPI(date) {
  const dateDate = new Date(date);
  return moment(dateDate).format('YYYY-MM-DD');
}

export function getDateFormatServer(date) {
  const dateDate = new Date(date);
  return moment(dateDate).format('YYYY-MM-DD HH:mm:ss');
}

export function getDateJS(date) {
  return date ? new Date(date) : new Date();
}

export function getLocale() {
  return i18next.language === 'es' ? es : enIN;
}

export function timestampPrventCache() {
  return `?t=${new Date().getTime()}`;
}

export function getDateRanges(t) {
  return [
    { value: 'HOUR', label: t('common:hours'), labelSingular: t('common:hour') },
    { value: 'DAY', label: t('common:days'), labelSingular: t('common:day') },
    { value: 'WEEK', label: t('common:weeks'), labelSingular: t('common:week') },
    { value: 'MONTH', label: t('common:months'), labelSingular: t('common:month') },
    { value: 'YEAR', label: t('common:years'), labelSingular: t('common:year') },
  ];
}
