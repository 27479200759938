import ErrorText from '__core/modules/atoms/ErrorText';
import InputText from '__core/modules/atoms/InputText';
import SelectContentType from 'app/components/selectors/SelectContentType/SelectContentType';
import SelectDisciplines from 'app/components/selectors/SelectDisciplines/SelectDisciplines';
import React from 'react';

function UIInput() {
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setError(value.length > 5);
  }, [value]);

  function onChange(event) {
    setValue(event.target.value);
  }

  return (
    <div>
      <div className="d-flex">
        <InputText error={error && <ErrorText text={'Numero de caracteres erroneos'} />} label={'Nombre Usuario'} onChange={onChange} value={value} />
        <InputText label={'Nombre Usuario'} placeholder={'Escriba el nombre de usuario...'} />
        <div className="w-25">
          <SelectDisciplines />
        </div>

        <div>
          <SelectContentType className="w-25" />
        </div>
      </div>

      <div className="d-flex mt-4">
        <InputText error={<ErrorText text={'Numero de caracteres erroneos'} />} label={'Nombre Usuario'} placeholder={'Escriba el nombre de usuario...'} />
        <InputText label={'Nombre Usuario'} placeholder={'Escriba el nombre de usuario...'} />
      </div>
    </div>
  );
}

export default UIInput;
