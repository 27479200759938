import axios from 'axios';

export const SCHOOLS = 'schools';

export function getSchool(guid) {
  return axios.get(`${SCHOOLS}/${guid}`);
}

export function getSchools(params) {
  return axios.get(SCHOOLS, { params });
}

export function createSchool(params) {
  return axios.post(SCHOOLS, params);
}

export function updateSchool(params) {
  return axios.put(`${SCHOOLS}/${params.guid}`, params);
}

export function deleteSchool(params = { guid: [] }) {
  return axios.delete(SCHOOLS, { data: params });
}

export function syncSchool(guid) {
  return axios.post(`${SCHOOLS}/${guid}/sif-sync`, {});
}

// --------------------------------------------------------------------------------
//  TYPES:
// --------------------------------------------------------------------------------

export function getSchoolsTypes(params) {
  return axios.get(`${SCHOOLS}/types`, { params });
}

// --------------------------------------------------------------------------------
//  USERS:
// --------------------------------------------------------------------------------
export function deleteSchoolUsers(schoolGuid, params = { guid: [] }) {
  return axios.delete(`${SCHOOLS}/${schoolGuid}/users`, { data: params });
}
