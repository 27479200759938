import Icon from '__core/modules/atoms/Icons';
import DialogDefault from '__core/modules/components/dialogs/Dialog';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';

const DialogConfirm = ({
  t,
  open,
  onClose,
  name,
  text,
  detailedList,
  onSuccess,
  title,
  buttonTextSucess = t('actions:Delete'),
  buttonTextCancel = t('actions:Cancel'),
}) => {
  return (
    <DialogDefault
      buttonTextCancel={buttonTextCancel}
      buttonTextSucess={buttonTextSucess}
      className={'dialog-confirm'}
      onCancel={onClose}
      onClose={onClose}
      onSuccess={onSuccess}
      open={open}
      hideHeader
    >
      <div className="d-flex align-items-center">
        <Icon type="icon-warning-circle" fontSize="large" color="error" />
        <span className="dialog-confirm__title ml-3">{title ? title : t('actions:Delete')}</span>
      </div>

      <div className="mt-4">
        <p className="dialog-confirm__text">{text ? text : <Trans i18nKey="common:Delete x?" values={{ name }} components={{ strong: <strong /> }} />}</p>
        <p className="dialog-confirm__text">{detailedList && detailedList.map((item, index) => <p key={index}>{item}</p>)}</p>
      </div>
    </DialogDefault>
  );
};

DialogConfirm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  name: PropTypes.string,
  buttonTextCancel: PropTypes.string,
};

DialogConfirm.defaultProps = {
  onClose: function () {},
  open: false,
};

export default withTranslation(['course', 'actions', 'common'])(DialogConfirm);
