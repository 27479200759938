import { permissionsRol as analyticsRol } from 'app/policies/roles/analyticsRol.access';
import { permissionsRol as collectionRol } from 'app/policies/roles/collectionsRol.access';
import { permissionsRol as contentRol } from 'app/policies/roles/contentsRol.access';
import { permissionsRol as licensesBatchRol } from 'app/policies/roles/licensesBatchRol.access';
import { permissionsRol as licensesRol } from 'app/policies/roles/licensesRol.access';
import { permissionsRol as personalizeRol } from 'app/policies/roles/personalizeRol.access';
import { permissionsRol as programRol } from 'app/policies/roles/programsRol.access';
import { permissionsRol as publicationRol } from 'app/policies/roles/publicationsRol.access';
import { permissionsRol as schoolsRol } from 'app/policies/roles/schoolsRol.access';
import { permissionsRol as settingsRol } from 'app/policies/roles/settingsRol.access';
import { permissionsRol as usersRol } from 'app/policies/roles/usersRol.access';
import { PATH_ANALYTIC_AMPLITUDE } from 'app/router/routesConfigAnalyticAmplitude';
import { PATH_ANALYTIC_GAME } from 'app/router/routesConfigAnalyticGame';
import { PATH_COLLECTION, PATH_COLLECTIONS, PATH_COLLECTION_CREATE } from 'app/router/routesConfigCollections';
import { PATH_CONTENT, PATH_CONTENTS, PATH_CONTENT_CREATE } from 'app/router/routesConfigContents';
import { PATH_FIXED_LAYOUT_EDITOR } from 'app/router/routesConfigFixedLayout';
import { PATH_LICENSE, PATH_LICENSES, PATH_LICENSE_CREATE } from 'app/router/routesConfigLicenses';
import { PATH_LICENSE_BATCH, PATH_LICENSE_BATCHS, PATH_LICENSE_BATCH_CREATE } from 'app/router/routesConfigLicensesBatch';
import { PATH_MESSAGES } from 'app/router/routesConfigMessages';
import { PATH_PERSONALIZE } from 'app/router/routesConfigPersonalize';
import { PATH_PROGRAM, PATH_PROGRAMS, PATH_PROGRAM_CREATE, PATH_PROGRAM_MINT_STAND_ALONE, PATH_PROGRAM_STEP } from 'app/router/routesConfigPrograms';
import { PATH_PUBLICATION, PATH_PUBLICATIONS, PATH_PUBLICATION_CREATE } from 'app/router/routesConfigPublications';
import { PATH_SCHOOL, PATH_SCHOOLS, PATH_SCHOOL_CREATE } from 'app/router/routesConfigSchools';
import { PATH_SETTINGS, PATH_SETTINGS_COUNTRIES, PATH_SETTINGS_COUNTRY, PATH_SETTINGS_LEARNING_OBJECTIVES } from 'app/router/routesConfigSetting';
import { PATH_USER, PATH_USERS, PATH_USER_CREATE } from 'app/router/routesConfigUser';
import { USER_ROLES } from 'app/services/users.service';

export function canAccess({ flags, path, settings, user }) {
  const USER_FTD = 'a44067a1-52d5-11ee-940a-4d25fb80326f';
  if (user?.guid === USER_FTD && path !== PATH_PROGRAM_MINT_STAND_ALONE) return false;

  return (
    analytics({ flags, path, user }) &&
    collections(path, user) &&
    contents(path, user) &&
    fixedLayout(path, user, settings) &&
    licenses(path, user) &&
    licensesBatch(path, user) &&
    messages({ flags, path }) &&
    personalize(path, user) &&
    programs(path, user) &&
    publications(path, user) &&
    schools(path, user) &&
    settingsApp(path, user) &&
    users(path, user)
  );
}

function analytics({ flags, path, user }) {
  if (PATH_ANALYTIC_AMPLITUDE === path) {
    return analyticsRol(user).readList && flags?.showAmplitude;
  }

  if (PATH_ANALYTIC_GAME === path) {
    return analyticsRol(user).readList && flags?.showAnalyticsGame;
  }

  return true;
}

function collections(path, user) {
  if (PATH_COLLECTION === path) {
    return collectionRol(user).readDetail;
  }

  if (PATH_COLLECTIONS === path) {
    return collectionRol(user).readList;
  }

  if (PATH_COLLECTION_CREATE === path) {
    return collectionRol(user).create;
  }

  return true;
}

function contents(path, user) {
  if (PATH_CONTENT === path) {
    return contentRol(user).readDetail;
  }

  if (PATH_CONTENTS === path) {
    return contentRol(user).readList;
  }

  if (PATH_CONTENT_CREATE === path) {
    return contentRol(user).create;
  }

  return true;
}

function fixedLayout(path, user, settings) {
  if (PATH_FIXED_LAYOUT_EDITOR === path) {
    const { cms_have_fixed_layout_editor_role } = settings;
    const isRolFixedLayout = USER_ROLES.adminFixedLayout === user?.role_guid;
    const hasPermissions = cms_have_fixed_layout_editor_role ? isRolFixedLayout : true;

    return hasPermissions;
  }

  return true;
}

function licenses(path, user) {
  if (PATH_LICENSE === path) {
    return licensesRol(user).readDetail;
  }

  if (PATH_LICENSES === path) {
    return licensesRol(user).readList;
  }

  if (PATH_LICENSE_CREATE === path) {
    return licensesRol(user).create;
  }

  return true;
}

function licensesBatch(path, user) {
  if (PATH_LICENSE_BATCH === path) {
    return licensesBatchRol(user).readDetail;
  }

  if (PATH_LICENSE_BATCHS === path) {
    return licensesBatchRol(user).readList;
  }

  if (PATH_LICENSE_BATCH_CREATE === path) {
    return licensesBatchRol(user).create;
  }

  return true;
}

function messages({ flags, path }) {
  if (PATH_MESSAGES === path) {
    return flags?.showMessagesGlobal;
  }

  return true;
}

function personalize(path, user) {
  if (PATH_PERSONALIZE === path) {
    return personalizeRol(user).sectionPersonalize;
  }

  return true;
}

function programs(path, user) {
  if (PATH_PROGRAM === path) {
    return programRol(user).readDetail;
  }

  if (PATH_PROGRAM_STEP === path) {
    return programRol(user).readDetail;
  }

  if (PATH_PROGRAMS === path) {
    return programRol(user).readList;
  }

  if (PATH_PROGRAM_CREATE === path) {
    return programRol(user).create;
  }

  return true;
}

function publications(path, user) {
  if (PATH_PUBLICATION === path) {
    return publicationRol(user).readDetail;
  }

  if (PATH_PUBLICATIONS === path) {
    return publicationRol(user).readList;
  }

  if (PATH_PUBLICATION_CREATE === path) {
    return publicationRol(user).create;
  }

  return true;
}

function schools(path, user) {
  if (PATH_SCHOOL === path) {
    return schoolsRol(user).readDetail;
  }

  if (PATH_SCHOOLS === path) {
    return schoolsRol(user).readList;
  }

  if (PATH_SCHOOL_CREATE === path) {
    return schoolsRol(user).create;
  }

  return true;
}

function settingsApp(path, user) {
  if (PATH_SETTINGS === path) {
    return settingsRol(user).sectionSettings;
  }

  if (PATH_SETTINGS_COUNTRIES === path) {
    return settingsRol(user).sectionSettings;
  }

  if (PATH_SETTINGS_COUNTRY === path) {
    return settingsRol(user).sectionSettings;
  }

  if (PATH_SETTINGS_LEARNING_OBJECTIVES === path) {
    return settingsRol(user).sectionSettings;
  }

  return true;
}

function users(path, user) {
  if (PATH_USER === path) {
    return usersRol(user).readDetail;
  }

  if (PATH_USERS === path) {
    return usersRol(user).readList;
  }

  if (PATH_USER_CREATE === path) {
    return usersRol(user).create;
  }

  return true;
}
