import { getQuestionsTypes } from 'app/crud/tests.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { PERSIST_QUESTION_TYPES, REDUCER_QUESTION_TYPES } from '../conf';

export const actionTypes = {
  GetQuestionTypes: 'cms/GET_QUESTION_TYPES',
  SetQuestionTypes: 'cms/SET_QUESTION_TYPES',
};

const initialState = {
  types: [],
  loading: false,
};

export const reducer = persistReducer({ storage, key: PERSIST_QUESTION_TYPES, whitelist: ['types'] }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetQuestionTypes:
      return { ...state, loading: true };

    case actionTypes.SetQuestionTypes: {
      const { response } = action.payload;
      if (response && response.status === 'success' && response.data) {
        return { types: response.data };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getQuestionTypes: (state) => {
    return state.entities && state.entities[REDUCER_QUESTION_TYPES] ? state.entities[REDUCER_QUESTION_TYPES].types : null;
  },
};

export const actions = {
  getQuestionTypes: () => ({ type: actionTypes.GetQuestionTypes }),
  fulfillQuestionTypes: (response) => ({ type: actionTypes.SetQuestionTypes, payload: { response } }),
};

function* fetchQuestionTypes() {
  const { data } = yield getQuestionsTypes();
  yield put(actions.fulfillQuestionTypes(data));
}

function* getQuestionTypesIfNeeded() {
  const types = yield select(selectors.getQuestionTypes);
  if (!types || types.length === 0) {
    yield fetchQuestionTypes();
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GetQuestionTypes, getQuestionTypesIfNeeded);
}
