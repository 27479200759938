import Skeleton from '@material-ui/lab/Skeleton';
import Icon from '__core/modules/atoms/Icons/Icon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const InputText = React.forwardRef(
  (
    {
      background,
      disabled,
      error,
      errorBorder,
      icon,
      iconColor,
      iconLeft,
      iconSize,
      isSkeleton,
      label,
      min,
      name,
      onChange,
      onClickIcon,
      onKeyPress,
      placeholder,
      referer,
      type,
      variant,
      ...props
    },
    ref
  ) => {
    const refInput = referer ? referer : ref;

    function handleKeyDown(event) {
      if (min <= 0 && type === 'number' && event.key === '-') {
        event.preventDefault();
      }

      if ((event.key === 'Backspace' || event.key === 'Delete') && props.value === undefined) {
        onChange({ ...event, target: { ...event.target, value: '' } });
      }
    }

    return !isSkeleton ? (
      <div
        className={clsx(
          'input-text',
          `input-text--${variant}`,
          `input-text--background-${background}`,
          { 'input-text--icon': !!icon },
          { 'input-text--icon-click': onClickIcon !== InputText.defaultProps.onClickIcon },
          { 'input-text--icon-left': iconLeft },
          { 'input-text--error': !!error },
          { 'input-text--error-border': errorBorder }
        )}
      >
        {label && (
          <label className="input-text__label" title={label}>
            {label} {props.required && <sup>*</sup>}
          </label>
        )}

        <div className="input-text__input-container">
          <input
            className={clsx('input-text__input', { 'input-text__input--disabled': disabled })}
            data-testid={name}
            disabled={disabled}
            min={min}
            name={name}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            ref={refInput}
            type={type}
            {...props}
          />
          {icon && (
            <div className="input-text__icon" onClick={onClickIcon}>
              <Icon type={icon} size={iconSize} color={iconColor} />{' '}
            </div>
          )}
        </div>
        {error && <span className="input-text__error">{error}</span>}
      </div>
    ) : (
      <div>
        {label && (
          <label className="input-text__label">
            {label} {props.required && <sup>*</sup>}
          </label>
        )}

        <Skeleton animation="wave" height={48} variant="text" />
      </div>
    );
  }
);

InputText.propTypes = {
  background: PropTypes.oneOf(['default', 'grey']),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconLeft: PropTypes.bool,
  isSkeleton: PropTypes.bool,
  min: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['default']),
};

InputText.defaultProps = {
  background: 'default',
  disabled: false,
  iconColor: 'disabled',
  iconLeft: false,
  iconSize: 'small',
  isSkeleton: false,
  onChange: () => null,
  onClickIcon: () => null,
  onKeyPress: () => {},
  type: 'text',
  variant: 'default',
};

export default InputText;
