import RoutesContext from 'app/context/routes.context';
import useFeatureFlagAnalytics from 'app/hooks/useFeatureFlagAnalytics';
import { permissionsRol as settingsRol } from 'app/policies/roles/settingsRol.access';
import { permissionsRol as usersRol } from 'app/policies/roles/usersRol.access';
import { PATH_PERSONALIZE } from 'app/router/routesConfigPersonalize';
import { PATH_SETTINGS } from 'app/router/routesConfigSetting';
import { getMenuApp } from 'app/services/menus.service';
import * as auth from 'app/store/ducks/auth.duck';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

function useListWrappers() {
  const flags = useFeatureFlagAnalytics();
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation(['actions']);
  const { transitionPage } = React.useContext(RoutesContext);
  const authUser = useSelector((state) => auth.selectors.getUser(state));
  const OPTIONS_MENU = getMenuApp({ authUser, flags });
  const [activeItem, setActiveItem] = React.useState(null);
  let optionsList = [{ name: t('logout'), icon: 'icon-exit', onClick: onLogout }];
  const optionsListSetting = settingsRol(authUser).sectionSettings
    ? [
        { name: t('setting'), icon: 'icon-setting', color: ' ', onClick: onSettings },
        { name: t('personalization'), icon: 'icon-pencil', onClick: onPersonalize },
      ]
    : [];

  React.useEffect(() => {
    let active = null;
    for (const option of OPTIONS_MENU) {
      active = option.toSubMenu.find((element) => element === pathname);
      if (active) {
        setActiveItem(option.key);
        break;
      }
    }
  }, [pathname]);

  function onProfile() {
    history.push(authUser.guid ? `/user/${authUser.guid}` : '/profile');
  }

  function onLogout() {
    history.push('/logout');
  }

  function onPersonalize() {
    history.push(PATH_PERSONALIZE);
  }

  function onSettings() {
    history.push(PATH_SETTINGS);
  }

  if (usersRol(authUser).readDetail) {
    optionsList.splice(0, 0, { name: t('profile'), icon: 'icon-user', onClick: onProfile });
  }

  return {
    OPTIONS_MENU,
    activeItem,
    authUser,
    optionsList,
    optionsListSetting,
    transitionPage,
  };
}

export default useListWrappers;
