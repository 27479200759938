import { defaultConfig } from 'app/config/environment';

export function getNameUrl(url) {
  if (url) {
    const replace = url.match(`http.+/`);
    return url.replace(replace, '');
  }

  return url;
}

export function openInNewTab(url) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export function openInNewTabRouter(PATH, guid) {
  const url = PATH.replace(':guid', guid);

  openInNewTab(url);
}

export function getQueryString(string) {
  let queryString = string;
  let query = {};

  // Remove the question mark "?" from the beginning of the chain
  queryString = queryString.slice(1);

  // Separate key-value pairs using the "&" character
  let pares = queryString.split('&');

  // Separate each key and value using the "=" character
  for (let i = 0; i < pares.length; i++) {
    let par = pares[i].split('=');
    let clave = par[0];
    let valor = par[1];

    // Assign keys and values to JavaScript object
    query[clave] = valor;
  }

  return query;
}

export function getDomainTenant({ tenant, user }) {
  const { BASE_URL_ } = defaultConfig;

  // TODO: Redireccionar al dominio de FrontOffice segun el pais, Ahora solo se haca para mx y EDELVIVES_DIGITAL_PLUS hay que buscar una solución generica.
  if (tenant?.tenant?.guid === 'EDELVIVES_DIGITAL_PLUS' && user?.country_guid === 'mx') {
    return BASE_URL_.includes('.mx') ? BASE_URL_ : `${BASE_URL_.slice(0, BASE_URL_.length - 1)}.mx/`;
  }
  // TODO: Redireccionar al dominio de FrontOffice segun el pais, Ahora solo se haca para mx y EDELVIVES_DIGITAL_PLUS hay que buscar una solución generica.
  if (tenant?.tenant?.guid === 'EDELVIVES_DIGITAL_PLUS' && BASE_URL_.includes('.mx')) {
    return BASE_URL_.replace('.mx', '');
  }

  return BASE_URL_;
}

export function getGUID(url) {
  if (url) {
    const regex = /\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})\/[^\/]+/;
    const match = url.match(regex);

    if (match) {
      return match[1];
    }
  }

  return '';
}
