import Icon from '__core/modules/atoms/Icons/Icon';
import DialogDefault from '__core/modules/components/dialogs/Dialog';
import { usePrompt } from 'app/hooks/usePrompt';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function DialogExitPage({ title, description, when }) {
  const { t } = useTranslation(['actions', 'dialogs']);
  const [currentPath, setCurrentPath] = React.useState('');
  const [showPrompt, setShowPrompt] = React.useState(false);
  const history = useHistory();
  const titleDialog = title ? title : t('dialogs:exit_page_title');
  const descriptionDialog = description ? description : t('dialogs:exit_page_description');

  usePrompt(when);

  React.useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => null);
    }

    return () => history.block(() => null);
  }, [history, when]);

  const onSuccess = React.useCallback(async () => {
    const canRoute = await Promise.resolve(true);
    if (canRoute) {
      history.block(() => null);
      history.push(currentPath);
    }
  }, [currentPath, history]);

  const onClose = React.useCallback(async () => {
    const canRoute = await Promise.resolve(false);
    if (canRoute) {
      history.block(() => null);
      history.push(currentPath);
    }

    setShowPrompt(false);
  }, [currentPath, history]);

  return showPrompt ? (
    <DialogDefault
      buttonTextCancel={t('Cancel')}
      buttonTextSucess={t('Exit')}
      className="dialog-exit-page"
      hideHeader
      onCancel={onClose}
      onClose={onClose}
      onSuccess={onSuccess}
      open={showPrompt}
    >
      <div className="d-flex align-items-center">
        <Icon type="icon-warning-circle" fontSize="large" color="error" />
        <span className="dialog-exit-page__title ml-3">{titleDialog}</span>
      </div>

      <p className="dialog-exit-page__description">{descriptionDialog}</p>
    </DialogDefault>
  ) : null;
}

export default DialogExitPage;
