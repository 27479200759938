import { permissionsRol as analyticsRol } from 'app/policies/roles/analyticsRol.access';
import { permissionsRol as collectionRol } from 'app/policies/roles/collectionsRol.access';
import { permissionsRol as contentRol } from 'app/policies/roles/contentsRol.access';
import { permissionsRol as licensesRol } from 'app/policies/roles/licensesRol.access';
import { permissionsRol as programRol } from 'app/policies/roles/programsRol.access';
import { permissionsRol as publicationRol } from 'app/policies/roles/publicationsRol.access';
import { permissionsRol as usersRol } from 'app/policies/roles/usersRol.access';
import { PATH_ANALYTIC_AMPLITUDE } from 'app/router/routesConfigAnalyticAmplitude';
import { PATH_ANALYTIC_GAME } from 'app/router/routesConfigAnalyticGame';
import { PATH_ANALYTIC_LICENSES } from 'app/router/routesConfigAnalyticLicenses';
import { PATH_ANALYTIC_PROGRAMS } from 'app/router/routesConfigAnalyticProgram';
import { PATH_ANALYTIC_USAGE } from 'app/router/routesConfigAnalyticUsage';
import { PATH_COLLECTIONS } from 'app/router/routesConfigCollections';
import { PATH_CONTENTS } from 'app/router/routesConfigContents';
import { PATH_LICENSES } from 'app/router/routesConfigLicenses';
import { PATH_LICENSE_BATCHS } from 'app/router/routesConfigLicensesBatch';
import { PATH_MESSAGES } from 'app/router/routesConfigMessages';
import { PATH_PROGRAMS } from 'app/router/routesConfigPrograms';
import { PATH_PUBLICATIONS } from 'app/router/routesConfigPublications';
import { PATH_SCHOOLS } from 'app/router/routesConfigSchools';
import {
  PATH_SETTINGS,
  PATH_SETTINGS_CATEGORIES,
  PATH_SETTINGS_COUNTRIES,
  PATH_SETTINGS_LEARNING_OBJECTIVES,
  PATH_SETTINGS_PERIODS,
  PATH_SETTINGS_SCALES,
} from 'app/router/routesConfigSetting';
import { PATH_USERS } from 'app/router/routesConfigUser';
import store from 'app/store/store';
import i18next from 'app/translations/i18n';

export function getMenuApp({ authUser, flags }) {
  let menu = [
    {
      key: 1,
      to: getToPathContent(authUser),
      toSubMenu: [PATH_CONTENTS, PATH_PUBLICATIONS, PATH_COLLECTIONS, PATH_PROGRAMS],
      title: i18next.t('menuContents:contents'),
      icon: 'icon-content',
    },
    // {
    //   key: 4,
    //   to: PATH_ANALYTIC_USAGE,
    //   toSubMenu: [PATH_ANALYTIC_LICENSES, PATH_ANALYTIC_PROGRAMS, PATH_ANALYTIC_USAGE],
    //   title: i18next.t('menuAnalytic:Analytic'),
    //   icon: 'icon-analytic',
    // },
  ];

  if (usersRol(authUser).readList) {
    menu.push({
      key: 2,
      to: PATH_USERS,
      toSubMenu: [PATH_USERS, PATH_SCHOOLS, PATH_MESSAGES],
      title: i18next.t('menuUsers:users'),
      icon: 'icon-user',
    });
  }

  if (licensesRol(authUser).readList) {
    menu.push({
      key: 3,
      to: PATH_LICENSES,
      toSubMenu: [PATH_LICENSES, PATH_LICENSE_BATCHS],
      title: i18next.t('menuLicenses:licenses'),
      icon: 'icon-license',
    });
  }

  if (analyticsRol(authUser).readList && (flags.showAnalyticsGame || flags.showAmplitude)) {
    const to = flags.showAmplitude ? PATH_ANALYTIC_AMPLITUDE : PATH_ANALYTIC_GAME;

    menu.push({
      key: 5,
      to,
      toSubMenu: [PATH_ANALYTIC_AMPLITUDE, PATH_ANALYTIC_GAME],
      title: i18next.t('menuAnalytic:Analytic'),
      icon: 'icon-analytic',
    });
  }

  return menu;
}

export function getMenuSectionContent(authUser) {
  let menu = [];

  if (contentRol(authUser).readList) {
    menu.push({ key: 1, title: i18next.t('menuContents:contents'), to: PATH_CONTENTS });
  }

  if (publicationRol(authUser).readList) {
    menu.push({ key: 2, title: i18next.t('menuContents:publications'), to: PATH_PUBLICATIONS });
  }

  if (collectionRol(authUser).readList) {
    menu.push({ key: 3, title: i18next.t('menuContents:collections'), to: PATH_COLLECTIONS });
  }

  if (programRol(authUser).readList) {
    menu.push({ key: 4, title: i18next.t('menuContents:digitalPrograms'), to: PATH_PROGRAMS });
  }

  return menu;
}

export function getMenuSectionLicense() {
  return [
    { key: 1, title: i18next.t('menuLicenses:licenses'), to: PATH_LICENSES },
    { key: 2, title: i18next.t('menuLicenses:licensesBatch'), to: PATH_LICENSE_BATCHS },
  ];
}

export function getMenuSectionUser(config) {
  let menu = [
    { key: 1, title: i18next.t('menuUsers:users'), to: PATH_USERS },
    { key: 3, title: i18next.t('menuUsers:schools'), to: PATH_SCHOOLS },
  ];

  if (config.flagShowMessagesGlobal) {
    menu.push({ key: 4, title: i18next.t('menuUsers:messages'), to: PATH_MESSAGES });
  }

  return menu;
}

export function getMenuSectionAnalytic() {
  return [
    { key: 1, title: i18next.t('menuAnalytic:Usage'), to: PATH_ANALYTIC_USAGE },
    { key: 2, title: i18next.t('menuAnalytic:Licenses'), to: PATH_ANALYTIC_LICENSES },
    { key: 3, title: i18next.t('menuAnalytic:Programs'), to: PATH_ANALYTIC_PROGRAMS },
  ];
}

export function getMenuAnalytic({ flags }) {
  let menu = [];

  if (flags.showAmplitude) {
    menu.push({ key: 1, title: i18next.t('menuAnalytic:Analytics'), to: PATH_ANALYTIC_AMPLITUDE });
  }

  if (flags.showAnalyticsGame) {
    menu.push({ key: 2, title: i18next.t('menuAnalytic:Game'), to: PATH_ANALYTIC_GAME });
  }

  return menu;
}

export function getMenuSectionSchools() {
  return [{ key: 1, title: i18next.t('menuSchool:schools'), to: PATH_SCHOOLS }];
}

export function getMenuSectionSettings(showEvaluationOptions) {
  const storeRedux = store.getState();
  const { is_multicountry, is_learning_objectives } = storeRedux['system-setting'].settings;
  const menu = [{ key: 1, title: i18next.t('settings:menu_general'), to: PATH_SETTINGS }];

  if (is_learning_objectives) {
    menu.push({ key: 3, title: i18next.t('settings:menu_learning_objectives'), to: PATH_SETTINGS_LEARNING_OBJECTIVES });
  }

  if (is_multicountry) {
    menu.splice(1, 0, { key: 2, title: i18next.t('settings:menu_countries'), to: PATH_SETTINGS_COUNTRIES });
  }

  if (showEvaluationOptions) {
    menu.push(
      { key: menu.length + 3, title: i18next.t('settings:menu_scales'), to: PATH_SETTINGS_SCALES },
      { key: menu.length + 1, title: i18next.t('settings:menu_categories'), to: PATH_SETTINGS_CATEGORIES },
      { key: menu.length + 2, title: i18next.t('settings:menu_periods'), to: PATH_SETTINGS_PERIODS }
    );
  }

  return menu;
}

function getToPathContent(authUser) {
  if (contentRol(authUser).readList) {
    return PATH_CONTENTS;
  }

  if (publicationRol(authUser).readList) {
    return PATH_PUBLICATIONS;
  }

  if (collectionRol(authUser).readList) {
    return PATH_COLLECTIONS;
  }

  if (programRol(authUser).readList) {
    return PATH_PROGRAMS;
  }

  return PATH_CONTENTS;
}
