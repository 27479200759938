import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Hr from '__core/modules/atoms/Hr';
import Icon from '__core/modules/atoms/Icons';
import HeaderTabs from '__core/modules/components/tabs/HeaderTabs';
import FooterButtons from '__core/modules/compositions/footer/FooterButtons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

const DialogDefault = ({
  t,
  buttonTextCancel = t('actions:Cancel'),
  buttonTextSucess = t('actions:Confirm'),
  children,
  className,
  customFooterLeft,
  customFooterRight,
  customHeader,
  disabledCancel,
  disabledClose,
  disabledOnBack,
  disabledSuccess,
  footerStuffed,
  footerWithBackground,
  hasCustomFooter,
  hideHeader,
  htmlColor,
  iconTitle,
  isKeyEnterSubmmit,
  loadingCancel,
  onBack,
  onCancel,
  onChangeTab,
  onClose,
  onGoBack,
  onSuccess,
  open,
  separator,
  showHeader,
  size,
  subtitle,
  tabActive,
  tabs,
  text,
  title,
  titleRigth,
  ...propsDialog
}) => {
  const { tReady, ...props } = propsDialog;
  const handleKeyDown = (event) => {
    if (isKeyEnterSubmmit && event.key === 'Enter') {
      onSuccess();
    }
  };

  return (
    <div className={clsx('dialog')}>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        disableEnforceFocus={true}
        fullWidth={true}
        maxWidth={false}
        onClose={onClose}
        onKeyDown={handleKeyDown}
        open={open}
        className={clsx('dialog', className, `dialog--size-${size}`, {
          'dialog--with-footer-with-background': footerWithBackground,
          'dialog--with-icon': iconTitle,
          'dialog--footer-stuffed': footerStuffed,
        })}
        {...props}
      >
        {!hideHeader && (title || onClose || customHeader) && showHeader && (
          <div className="dialog__header">
            {onBack && (
              <button className="hover-basic" onClick={onBack} disabled={disabledOnBack}>
                <img src="/images/svg/back.svg"></img>
              </button>
            )}

            {onGoBack && (
              <div className="hover-basic" onClick={onGoBack}>
                <Icon type="icon-arrow-left" htmlColor={htmlColor} />
              </div>
            )}

            <div className="dialog__title-container ">
              {title && (
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="w-100">
                    <div className="dialog__title-wrapper">
                      {iconTitle && <Icon type={iconTitle} color="primary" />}
                      <span className="dialog__title">{title}</span>
                    </div>

                    {subtitle && <div className="dialog__subtitle">{subtitle}</div>}
                  </div>

                  {titleRigth && <div className="mr-3 w-100">{titleRigth}</div>}
                </div>
              )}

              {tabs && (
                <div className="dialog__title-tabs">
                  <HeaderTabs tabs={tabs} tabActive={tabActive} onChangeTab={onChangeTab} />
                </div>
              )}

              <div>
                {onClose && (
                  <div className={disabledClose ? 'dialog__close-disabled' : 'dialog__close'} onClick={onClose}>
                    <Icon size="big" type="icon-close" />
                  </div>
                )}
              </div>
            </div>
            {text && <span className="dialog__text">{text}</span>}
            {customHeader && <div className="dialog__custom-header">{customHeader}</div>}
            {separator && (
              <div className="dialog__hr">
                <Hr size="small" />
              </div>
            )}
          </div>
        )}

        {children && <DialogContent>{children}</DialogContent>}

        {(onSuccess || onCancel) && (
          <DialogActions>
            <div className="dialog__actions">
              <div className="dialog__actions-left">{!!customFooterLeft && customFooterLeft}</div>
              <FooterButtons
                buttonTextCancel={buttonTextCancel}
                buttonTextSucess={buttonTextSucess}
                disabledCancel={disabledCancel}
                disabledSuccess={disabledSuccess}
                loadingCancel={loadingCancel}
                onCancel={onCancel}
                onSuccess={onSuccess}
              />
            </div>
          </DialogActions>
        )}

        {hasCustomFooter && (
          <DialogActions>
            <div className="dialog__actions">
              <div className="dialog__actions-left">{!!customFooterLeft && customFooterLeft}</div>
              <div className="dialog__actions-left">{!!customFooterRight && customFooterRight}</div>
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

DialogDefault.propTypes = {
  buttonTextCancel: PropTypes.string,
  buttonTextSucess: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.any,
  customFooterLeft: PropTypes.any,
  customFooterRight: PropTypes.any,
  customHeader: PropTypes.any,
  disabledCancel: PropTypes.bool,
  disabledClose: PropTypes.bool,
  disabledOnBack: PropTypes.bool,
  disabledSuccess: PropTypes.bool,
  footerStuffed: PropTypes.bool,
  footerWithBackground: PropTypes.any,
  hasCustomFooter: PropTypes.bool,
  hideHeader: PropTypes.bool,
  htmlColor: PropTypes.string,
  iconTitle: PropTypes.any,
  isKeyEnterSubmmit: PropTypes.bool,
  loadingCancel: PropTypes.bool,
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  onChangeTab: PropTypes.func,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  separator: PropTypes.any,
  showHeader: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'xxs', 'xs', 's', 'small', 'xl', 'xxl', 'all-screen']),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  t: PropTypes.any,
  tabActive: PropTypes.any,
  tabs: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleRigth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

DialogDefault.defaultProps = {
  disabledCancel: false,
  disabledOnBack: false,
  disabledSuccess: false,
  footerStuffed: false,
  hideHeader: false,
  htmlColor: '#fff',
  open: false,
  showHeader: true,
  size: 'default',
};

export default withTranslation(['common', 'actions', 'placeholder', 'types-resources'])(DialogDefault);
