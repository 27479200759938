import { USER_ROLES } from 'app/services/users.service';

function canPermissions(user) {
  return user?.role_guid !== USER_ROLES.helpDesk;
}

function canDelete(user) {
  return user?.role_guid !== USER_ROLES.helpDesk && user?.role_guid !== USER_ROLES.publisher;
}

function permissions(user) {
  return {
    readList: canPermissions(user),
    readDetail: canPermissions(user),
    create: canPermissions(user),
    update: canPermissions(user),
    delete: canDelete(user),
    deleteOwner: true,
  };
}

export function permissionsRol(user) {
  return permissions(user);
}
