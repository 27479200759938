import { editProfile } from 'app/crud/auth.crud';
import { LANG_ID_LOCAL_STORAGE_KEY } from 'app/services/language.service';
import { auth, i18n } from 'app/store/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useSelectLanguage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => auth.selectors.getUser(state));

  const [anchorEl, setAnchorEl] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const optionsList = [
    { name: 'Español', onClick: () => onChangeLanguage('es'), active: user.lang_id === 'es' },
    { name: 'Português', onClick: () => onChangeLanguage('pt'), active: user.lang_id === 'pt' },
  ];

  function onOpen(event) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  async function onChangeLanguage(lang) {
    dispatch(auth.actions.editProfile({ ...user, lang_id: lang }));
    dispatch(i18n.actions.setLanguage(lang));

    await editProfile({ lang_id: lang });
    handleLocalStorage(lang);
    handleRefresh();
  }

  function handleRefresh() {
    window.location.reload();
  }

  function handleLocalStorage(lang) {
    localStorage.setItem(LANG_ID_LOCAL_STORAGE_KEY, lang);
  }

  function sortOptionsList() {
    return optionsList.sort((a, b) => b.active - a.active);
  }

  return {
    anchorEl,
    langId: user.lang_id,
    open,
    optionsList: sortOptionsList(),
    onClose,
    onOpen,
  };
}

export default useSelectLanguage;
