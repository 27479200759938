import viewerApi from '../api/viewerApi';

export function reportTraceConsumptionInternal(_this, _data_consumption, _callback) {
  var _data = {
    params: _data_consumption,
    type: 'UPDATE_CONSUMPTION',
  };

  viewerApi(_data, function (e) {});
}

export function initSesionConsumptionInternal(_this, _data, _callback) {
  var _data_ = {
    params: { content_guid: _data.content_guid, type: _data.type, progress_ends: _data.progress_ends },
    type: 'INIT_SESION_CONSUMPTION',
  };

  // Llamada al servicio para Inicializar SESIÓN DE CONSUMO
  viewerApi(
    _data_,
    function (e) {
      _callback(e);
    }.bind(_this)
  );
}

export function getTracingConsumptionsInternal(e, _this) {
  if (_this.state.typeContent === 'CTTY_21' && _this.state.suspend_data !== e.suspend_data) {
    _this.timer(e);
  } else if (_this.state.progress_start != e.progress_start || _this.state.progress_end != e.progress_end) {
    _this.setState({ progress_start: e.progress_start, progress_end: e.progress_end });

    switch (_this.state.typeContent) {
      case 'CTTY_01': // Fixed Layout
      case 'CTTY_05':
      case 'application/pdf':
      case 'pdf':
      case 'CTTY-16': // PDF
      case 'CTTY_12': // PDF, Office
        _this.timer();
        break;
      case 'CTTY_02': // Libros Mint
        _this.timer();
        break;
      default:
        break;
    }
  }
}

export function closeSesionConsumption(_this) {
  if (_this.state.session_guid != null) {
    var _data = { session_guid: _this.state.session_guid, consumption_guid: _this.state.consumption_guid, type: 'CLOSE_SESION_CONSUMPTION' };
    try {
      viewerApi(_data, function (e) {});
    } catch (erre) {
      console.log(erre);
    }
  }
}

export function finishSesionConsumptionInternal(_this, _data_finish, _callback) {
  var _data = {
    session_guid: _data_finish.session_guid,
    consumption_guid: _data_finish.consumption_guid,
    type: 'CLOSE_SESION_CONSUMPTION',
  };

  try {
    viewerApi(_data, function (e) {
      _callback(e);
    });
  } catch (erre) {
    console.log(erre);
  }
}

export function afterLoadingViewer(_this, e) {
  e.type = e.type == 'mint_book' ? 'document' : e.type;
  var _data = {
    params: {
      content_guid: _this.state.data.content_guid,
      type: e.type,
      progress_ends: e.total,
    },
    type: 'INIT_SESION_CONSUMPTION',
  };

  // Llamada al servicio para Inicializar SESIÓN DE CONSUMO

  viewerApi(_data, function (e) {
    var _last_show_at = e.last || 0;
    if (_this.state.typeContent === 'CTTY_21') {
      _last_show_at = e.suspend_data;
    }

    _this.setState({
      session_guid: e.session_guid,
      consumption_guid: e.guid,
      progressConsumed: e.progress * 100 > 100 ? 100 : e.progress * 100,
      last_show_at: _last_show_at,
    });

    // Timer control de ID SESIÓN DE CONSUMO

    if (e.session_guid != null) {
      if (_this.intervalId === undefined) {
        //_this.intervalId = setInterval(_this.timer, 1000);

        switch (_this.state.typeContent) {
          case 'CTTY_03':
          case 'video':
          case 'video/mp4': // video
          case 'CTTY_04':
          case 'audio':
          case 'audio/*': // audio
            _this.intervalId = setInterval(_this.timer, 3000);
            break;
          case 'CTTY_06':
          case 'link':
          case 'link/*': // Link
          case 'CTTY_07':
          case 'image':
          case 'image/*': // Imagen
          case 'CTTY_08':
          case 'text/html': // Html Interactivo, OED
          case 'CTTY_09': // Test
          case 'CTTY_10': // Scorm
          case 'CTTY_11': // SWF
          case 'CTTY_13': // Zip
          case 'CTTY_14': // Question
          case 'CTTY_15': // Html Page
            _this.setState({ progress_start: 0, progress_end: 1 });
            _this.timer();
            break;
          default:
            break;
        }
      }
    }
  });
}

export function timerInternal(_this, e) {
  if (
    _this.state.progress_start != _this.state.progress_start_test ||
    _this.state.progress_end != _this.state.progress_end_test ||
    (e && _this.state.suspend_data !== e.suspend_data)
  ) {
    _this.setState({
      progress_start_test: _this.state.progress_start,
      progress_end_test: _this.state.progress_end,
      suspend_data: (e && e.suspend_data) || '',
    });

    var _data = {
      params: {
        content_guid: _this.state.data.content_guid,
        consumption_guid: _this.state.consumption_guid,
        started_at: _this._started_at,
        ended_at: Date.now(),
        progress_start: _this.state.progress_start,
        progress_end: _this.state.progress_end,
        session_guid: _this.state.session_guid,
      },
      type: 'UPDATE_CONSUMPTION',
    };

    // Epub, para recuperar luego para repintar la última posión
    if (_this.state.suspend_data !== '') {
      _data.params.suspend_data = _this.state.suspend_data;
    }

    if (_data.params.session_guid != null) {
      viewerApi(_data, function (e) {
        _this.setState({ progressConsumed: e.progress * 100 > 100 ? 100 : e.progress * 100 });
      });
    }
  }
}
